
import $ from "jquery";
import detailDefault from "../../detailDefault";
export default class detailacm_compliant extends detailDefault{
    load(sAction,data){
        const currentUser = sAction.dataGet('conf/user');
        if (currentUser.get("is_admin") !== '1') {
            
            let isCompliantManager = sAction.dataGet(data.prefix + "/customData/isCompliantManager");
            if(!isCompliantManager){
                let isCompliantUser = sAction.dataGet(data.prefix + "/customData/isCompliantUser");
                if(!isCompliantUser){
                    sAction.dataSet(data.prefix + "/fields/pojistovna/def/readonly", true);
                }
                sAction.dataSet(data.prefix + "/fields/pojistovna_suma/def/readonly", true);
                sAction.dataSet(data.prefix + "/fields/assigned_user_name/def/readonly", true);
            }

        }
        else{
            sAction.dataSet(data.prefix+"/fields/comp_state/def/readonly", false);
        }
        //const record = sAction.dataGet(data.prefix + "/id");

        //if(record){
        //     sAction.dataSet(data.prefix + "/fields/comp_source/def/readonly", true);
        //}

        const comp_type = sAction.dataGet(data.prefix + "/fields/comp_type/value");
        this.setTypePanels(sAction, comp_type);
        this.checkCreditTransit(sAction, data);
        this.handelZasilkaCisField(sAction, data.prefix, sAction.dataGet(data.prefix + "/fields/poskozena_cast/value"), sAction.dataGet(data.prefix + "/fields/neposkozena_cast/value"));
    }
    update(sAction,data){
        switch(data.field){
            case "comp_type":
                this.setTypePanels(sAction, data.value);
                break;
            case "credit_transit":
                this.checkCreditTransit(sAction, data);
                break;
            case "poskozena_cast":
                this.handelZasilkaCisField(sAction, data.prefix, data.value, sAction.dataGet(data.prefix + "/fields/neposkozena_cast/value"));
                break;
            case "neposkozena_cast":
                this.handelZasilkaCisField(sAction, data.prefix, sAction.dataGet(data.prefix + "/fields/poskozena_cast/value"), data.value);
                break;
            case "balik_cislo":
                if(data.value !== data.prevValue){
                    sAction.cisloBalikuChanged(data.prefix, data.value);
                }
                break;
        }
    }

    handelZasilkaCisField(sAction, prefix, poskozena, neposkozena){
        if(poskozena == "vracena_sps" || poskozena == "vracena_odesilateli"){
            sAction.dataSet(prefix + "/fields/poskozena_zasilka_cislo/def/required", true);
        }
        else{
            sAction.dataSet(prefix + "/fields/poskozena_zasilka_cislo/def/required", false);
        }

        if(neposkozena == "vracena_sps" || neposkozena == "vracena_odesilateli"){
            sAction.dataSet(prefix + "/fields/neposkozena_zasilka_cislo/def/required", true);
        }
        else{
            sAction.dataSet(prefix + "/fields/neposkozena_zasilka_cislo/def/required", false);
        }

    }

    beforeSave(sAction,data){
    
        const credit_transit = sAction.dataGet(data.prefix+"/fields/credit_transit/value");
        if(credit_transit == "ano"){
            const comp_ref_suma = sAction.dataGet(data.prefix+"/fields/comp_ref_suma/value");
            if(comp_ref_suma <= 0){
                sAction.dataSet(data.prefix+"/fields/comp_ref_suma/customClass", "error");
                sAction.error(sAction.translate("LBL_ERR_COMP_REINV_INVALID_VAL"));
                return false;
            }
        }
        return true;
        
    }

    checkCreditTransit(sAction, data){
        const credit_transit = sAction.dataGet(data.prefix+"/fields/credit_transit/value");
        if(credit_transit == "ano"){
            sAction.dataSet(data.prefix+"/fields/comp_ref_suma/def/required", true);
        }
        else{
            sAction.dataSet(data.prefix+"/fields/comp_ref_suma/def/required", false);
        }
    }

    setTypePanels(sAction, value){
        const panelConf = {
            "st" : "lbl_detailview_panel2",
            "se" : "lbl_detailview_panel3",
            "ps" : "lbl_detailview_panel4",
            "zz" : "lbl_detailview_panel5",
        };

        panelConf.forEachObject((panel, key) => {
            if(value === key){
                this.showPanel(sAction, panel);
            }
            else{
                this.hidePanel(panel);
            }
        });


    }

    hidePanel(pomClass){
        const header = $("."+pomClass);
        let pomElem = header.next();
        while(pomElem.hasClass("detailViewTabContentRow")){
            pomElem.hide();
            pomElem = pomElem.next();
        }
        header.hide();
    }
    showPanel(sAction, pomClass){
        const header = $("."+pomClass);
        let pomElem = header.next();
        while(pomElem.hasClass("detailViewTabContentRow")){
            pomElem.show();
            pomElem = pomElem.next();
        }
        header.show();
        header.children(".detailViewPanelText").text(sAction.translate("LBL_TASKS", "acm_compliant"));
    }


}
