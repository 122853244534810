import React from "react";
import  sAction  from "sAction";

export default class DropZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false

    };
    this.fileInput = React.createRef();
    this.filesForUpload = [];
    this.maxFileSize = 104857600;
  }

  update = () => {
    this.props.update(this.filesForUpload);
    this.filesForUpload = [];
  };

  processInput = (files, index) => {
    var file = files[index];
    if (!file) {
      return;
    }

    if (file.size > this.maxFileSize) {
      sAction.error(
        sAction
          .translate("ERR_EMAIL_UPLOAD_MAX_FILE_SIZE", "Emails")
          .replace("%name%", file.name)
          .replace("%size%", sAction.humanFileSize(file.size))
          .replace("%maxsize%", sAction.humanFileSize(this.maxFileSize))
      );
      if (index + 1 < files.length) {
        this.processInput(files, index + 1);
      } else {
        this.update();
      }
    } else {
      this.filesForUpload.push(file);

      if (index + 1 < files.length) {
        this.processInput(files, index + 1);
      } else {
        this.update();
      }
    }
  };

  drop(ev) {
    ev.preventDefault();

    var files = [];
    var i, file;

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === "file") {
          file = ev.dataTransfer.items[i].getAsFile();
          files.push(file);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (i = 0; i < ev.dataTransfer.files.length; i++) {
        file = ev.dataTransfer.files[i];
        files.push(file);
      }
    }
    // sAction.processInput(files, 0);
    this.processInput(files, 0);
    // Pass event to removeDragData for cleanup
    //  this.removeDragData(ev)
    //  sAction.messageSet([{way:["files"],value:files,like:"list"}])
    this.setState({
      dragging: false
    });
  }

  select = e => {
    e.preventDefault();
    this.processInput(e.target.files, 0);
  };

  dragActive = () => {
    if (this.props.readonly) {
      return;
    }
    this.setState({ dragging: true });
  }

  dragDeActive = () => {
    this.setState({ dragging: false });
  }

  renderTable = () => {
    let { attachments, readonly } = this.props;

    if (!attachments || !attachments.size) return "";

    return (
      <table>
        <thead>
        <tr>
          <th>{sAction.translate("LBL_EMAIL_UPLOAD_FILE_NAME", "Emails")}</th>
          <th>{sAction.translate("LBL_EMAIL_UPLOAD_FILE_SIZE", "Emails")}</th>
          {/*<th>{sAction.translate("LBL_EMAIL_UPLOAD_FILE_TYPE", "Emails")}</th>*/}
          {!readonly && (<th>
            <a onClick={this.props.deleteAll}>
              {sAction.translate("LBL_EMAIL_UPLOAD_DELETE_ALL", "Emails")}
            </a>
          </th>)}
        </tr>
        </thead>
        <tbody>
        {!!attachments &&
        attachments.valueSeq().map((a, i) => (
          <tr key={i + "_" + a.get("id")}>
            <td>
                  <span title={a.get("name")}>
                      <a
                        data-i={a.get("id")}
                        onClick={this.props.handleDownload}
                      >
                        {a.get("name")}
                      </a>
                  </span>
            </td>
            <td>{a.get("size") && sAction.humanFileSize(a.get("size"))}</td>
            {/*<td>
                  <span title={a.get("type")}>{a.get("type")}</span>
                </td>*/}
            {(!readonly || this.props.allowReadonlyDelete)&& (<td>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.delete(a.get("id"));
                }}
              >
                <div className="actionPanelButtonIcon deleteIcon"/>
              </a>
            </td>)}
          </tr>
        ))}
        </tbody>
      </table>
    );
  };

  loading = () => {
    const {
      uploading: { percent, loaded, total }
    } = this.props;

    if (percent === 100) return "";

    return (
      <React.Fragment>
        <p>
          {sAction.translate("LBL_LOADING")} {sAction.humanFileSize(loaded)} /{" "}
          {sAction.humanFileSize(total)}
        </p>
        <div className="progressBarContainer w100">
          <div className="progressBarActual" style={{ width: percent + "%" }}/>
        </div>
        <br/>
      </React.Fragment>
    );
  };

  changeHidden = () => {
    if (this.props.changeHidden) {
      this.props.changeHidden();
    }
  };

  handleClick = () => {
    if (!this.props.readonly) {
      this.fileInput.click();
    }
  };

  render() {
    let dragOverlap = null;
    const { dragging } = this.state;

    let className = "dropzone nonactive";
    if (dragging) {
      className = "dropzone active";
      dragOverlap = (
        <div
          className="drop"
          onDragLeave={() => this.dragDeActive()}
          onDrop={e => this.drop(e)}
        />
      );
    }

    const display =
      this.props.hidden === true &&
      (!this.props.attachments || !this.props.attachments.size)
        ? "none"
        : "block";

    let dragContent = "";
    if (!this.props.readonly) {
      dragContent = (
        <React.Fragment>
          <p>
            <a href="#" onClick={e => e.preventDefault()}>
              {sAction.translate("LBL_EMAIL_UPLOAD_CHOOSE_FILES", "Emails")}
            </a>{" "}
            {sAction.translate("LBL_EMAIL_UPLOAD_CHOOSE_FILES_DRAG", "Emails")}
          </p>
          {this.loading()}
          {dragOverlap}
        </React.Fragment>
      );
    }

    return (
      <div
        className={className}
        onDragOver={e => e.preventDefault()}
        onDragEnter={this.dragActive}
        ref="content"
        onClick={this.handleClick}
        style={{ display }}
      >
        {dragContent}
        {this.renderTable()}
        <input
          type="file"
          className="hidden"
          ref={fileInput => (this.fileInput = fileInput)}
          onChange={this.select}
          multiple={this.props.multipleFiles ?? true}
          onClick={this.changeHidden}
        />
        <input
          type="hidden"
          className="addSugarDocument"
          onClick={this.props.addSugarDocument}
        />
      </div>
    );
  }
}
