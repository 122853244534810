import detailDefault from "../../detailDefault";

export default class detailacm_customers_requests_history extends detailDefault {
    load(sAction, data) {
        const actionType = sAction.dataGet('view/fields/action_type/value');
        if (actionType === 'assigned-change') {
            sAction.dsClear();
            sAction.dsAdd('set', `${data.prefix}/fields/from_status/def/visible`, false);
            sAction.dsAdd('set', `${data.prefix}/fields/to_status/def/visible`, false);
            sAction.dsProcess();
        } else if (actionType === 'status-change') {
            sAction.dsClear();
            sAction.dsAdd('set', `${data.prefix}/fields/from_fullname/def/visible`, false);
            sAction.dsAdd('set', `${data.prefix}/fields/to_fullname/def/visible`, false);
            sAction.dsProcess();
        }
    }
}
