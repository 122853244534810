export default function sendOfficialCommunication(params) {
    const id = this.dataGet(`${params.prefix}/id`);

    this.rest.post('sendOfficialCommunication', {id}, res => {
        if (!res.status) {
            this.error(this.translate(res.message, params.module));
        } else {
            this.toast({
                name: this.translate(res.message, params.module),
                type: 'success',
            });
        }
    });
}
