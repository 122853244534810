import React, { Component } from "react";
import PureComponent from "../../pure";
import TextField from "@material-ui/core/TextField";
import Select from '../../formElements/Select';

import  sAction  from "sAction";

class OppProduct extends PureComponent {

  constructor() {
    super();
    this.state = {
      descripFocused: false
    };
  }

  valChange(way, field, val, oppWay, product_template_id) {
    sAction.updateDetailCustom("changeOppProd", {
      way: way,
      field: field,
      value: val,
      lines: sAction.dataGet(oppWay).get("lines"),
      oppWay:oppWay,
      product_template_id: product_template_id,
      totalFieldName: "amount"
    });
  }


  blurDescription(way, val) {
    this.setState({ descripFocused: false });
    sAction.updateDetailCustom("field", { way: way, value: val });
  }

  render() {
    const data = this.props.data;
    const way = this.props.way;
    const oppWay = this.props.oppWay;
    const readonly = this.props.readonly;

    const total = sAction.formatNumber(data.discount_price * data.quantity);
    const keyPre = data.product_template_id + "_" + Date.now();

    let descClass = "productLineDescription";
    if (this.state.descripFocused) {
      descClass += " expanded";
    }

    var descRows = 1;
    if(data.description || this.state.descripFocused){
      descRows = 3;
    }

    var pomClass = "";
    if (data.quantity == 0) {
      pomClass = " emptyProduct";
    }

    var currenciesOptions =[];
    sAction.currencies.forEachObject((val, key) => {
      currenciesOptions.push({value: key, label: val.name + " ("+val.symbol+")"});
    });

    return (
      <tr>
        <td className="oppProdField prodName">
          {data.name}
        </td>
        <td className="oppProdField priceInput">
          <TextField
            margin="normal"
            variant="outlined"
            type="text"
            autoComplete="off"
            defaultValue={sAction.formatNumber(data.cena_za_kus)}
            disabled={readonly}
            key={keyPre + "_cena_za_kus"}
            onBlur={(ev) => this.valChange(way, "cena_za_kus", sAction.parseNumber(ev.target.value), oppWay, data.product_template_id)}
          />
        </td>
        <td className="oppProdField numberInput">
          <TextField
            className="priceInput"
            margin="normal"
            variant="outlined"
            type="number"
            autoComplete="off"
            defaultValue={data.ks}
            disabled={readonly}
            key={keyPre + "_ks"}
            onBlur={(ev) => this.valChange(way, "ks", ev.target.value, oppWay, data.product_template_id)}
          />
        </td>
        <td className="oppProdField priceInput">
          <TextField
            margin="normal"
            variant="outlined"
            type="text"
            autoComplete="off"
            defaultValue={sAction.formatNumber(data.celkem)}
            disabled={true}
            key={keyPre + "_celkem"}
            onBlur={(ev) => this.valChange(way, "celkem", sAction.parseNumber(ev.target.value), oppWay, data.product_template_id)}
          />
        </td>
        <td className="oppProdField priceInput">
        <Select 
            open={false} 
            name="currency_id" 
            options={currenciesOptions} 
            defaultValue={data.currency_id} 
            disabled={readonly}
            key={keyPre+"_currency_id"}
            onChange={(ev) => this.valChange(way, "currency_id", ev.target.value, oppWay, data.product_template_id)} 
        />
        </td>
        <td className={"oppProdField " + descClass + pomClass}>
          <TextField
            multiline={true}
            type="text"
            margin="normal"
            variant="outlined"
            rows={descRows}
            key={keyPre + "_description"}
            disabled={readonly}
            onBlur={(ev) => this.blurDescription(way + "/description", ev.target.value)}
            defaultValue={data.description}
          />
        </td>

      </tr>
    );
  }
}

export default OppProduct;