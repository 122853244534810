import React, { useState, useEffect, useRef } from 'react';
import Select from '../../formElements/Select';
import sAction from 'sAction';

export default function CsStatusSelect({ csWorkerStatus }) {
    const [value, setValue] = useState(csWorkerStatus);
    const [open, setOpen] = useState(false);
    const options = sAction.getEnumForSelect('work_type');

    const colors = {
        online: '#18da59',
        offline: '#E92222',
        lunch: '#f5a020',
    };

    const containerRef = useRef(null);

    /**
     * @param {Event} e
     */
    const onChange = (e) => {
        setValue(e.target.value);
        const data = {
            status: e.target.value,
        };
        sAction.rest.post('changeCsWorkerStatus', data, (res) => {
            setOpen(false);
            if (res.status) {
                sAction.dataSet('conf/user/cs_worker_status', e.target.value);
            } else {
                sAction.error(this.translate(res.data, 'acm_customers_requests'));
            }
        });
    };

    const handleClickOutside = (event) => {
        if (containerRef.current &&
            !containerRef.current.contains(event.target) &&
            !event?.target?.className?.includes('MuiListItem-button') &&
            !event?.target?.className?.includes('MuiMenu-list')) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            ref={containerRef}
            className={`csContainer csContainer-${open}`}
            style={{ backgroundColor: open ? '#FFF' : colors[value] }}
        >
            <div className={`csContainerIconBox csContainer-${open}`} onClick={() => setOpen(!open)}>
                <div className={`csContainer-icon icon-${value ? value : csWorkerStatus}`} />
            </div>
            {open ? (
                <div className='csContainerContent'>
                    <Select value={value ? value : csWorkerStatus} options={options} onChange={(e) => onChange(e)} />
                </div>
            ) : null}
        </div>
    );
}