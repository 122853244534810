export default function accGenerateContract(params) {

    const fileData = {
        returnFile: true,
        action: "printPdf",
        module: "Accounts",
        id: params.id
    };

    this.load();
    this.filesXhr({
        action: "generateFileRequest",
        data: fileData,
        success: ret => {
        this.unLoad();
        if (!ret) {
            // Nepodařilo se spojit se serverem nebo získat data, opakujte prosím akci.
            this.error(this.translate("ERR_SEND_MASS_EMAIL_NO_DATA", "Emails"));
        } else if (ret.id == undefined) {
            // Data nebyla vrácena ve správném formátu. Výstup:
            this.error(
            this.translate("ERR_SEND_MASS_EMAIL_FORMAT", "Emails") + ret
            );
        } else {
            const email = this.dataGet(params.prefix+"/fields/email1/value");
            const data = {
                parent_type: params.module,
                parent_id: params.id,
                parent_name: params.name,
                
                to_addrs: email,
                emailTemplate : "1f526317-3219-58d4-54dc-5e99b8ca26ae",
                attachments: {
                    [ret.id]: {
                      id: ret.id,
                      name: (params.name + ".pdf"),
                      size: ret.size,
                      source: "cache",
                      type: "pdf"
                    }
                  },
                  callback: "accGenerateContractCallback",
            };
                
            this.openEmailPopup(data);
            }
        }
    });

  }