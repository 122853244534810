import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import TimeAgo from 'react-timeago';
import ChatAvatar from './ChatAvatar';
import czechStrings from './utils/timeagoCzechStrings';
import AvatarGroup from '@mui/material/AvatarGroup';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import sAction from 'sAction';

export default function ChatGroupListItem({
    users,
    chat,
    lastMessage,
    newMessages,
    selected,
    className,
    onClick,
    highlightProtected = false,
}) {
    const formatter = buildFormatter(czechStrings);

    const messagePreview = useMemo(() => {
        switch (lastMessage.messageType) {
            case 'system':
                return sAction.translate(lastMessage.content);
            case 'image':
                return `media: ${lastMessage.content}`;
            case 'coripoLink':
                return `${lastMessage.content?.url}`;
            default:
                return lastMessage.content;
        }
    }, [lastMessage]);

    return (
        <div className={`chatListItem chatGroupListItem ${className} ${selected ? 'selected' : ''}`} onClick={onClick}>
            <div className='chatListItemAvatarWrapper'>
                <div className='chatListItemInfoAvatar'>
                    <p className={'chatListItemName' + (highlightProtected && chat?.protected ? ' protected' : '' )}>{chat.name}</p>
                    <AvatarGroup max={3} className='chatListItemAvatarGroup'>
                        {users.map((user) => (
                            <ChatAvatar key={user.id} user={user} />
                        ))}
                    </AvatarGroup>
                </div>
                <div className='chatListItemInfo'>
                    <p className='chatListItemLastMessage'>{messagePreview}</p>
                </div>
            </div>
            <div className='chatListItemNewMessages'>
                <p className='chatListItemLastMessageTimestamp'>
                    <TimeAgo
                        date={lastMessage.timestamp}
                        formatter={formatter}
                        minPeriod={60}
                    />
                </p>
                <p className={`chatListItemNewMessagesCount ${newMessages <= 0 ? 'countZero' : ''}`}>{newMessages}</p>
            </div>
        </div>
    );
}

ChatGroupListItem.propTypes = {
    className: PropTypes.string,
    chat: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        participants: PropTypes.array,
        type: PropTypes.string,
        unreadMessages: PropTypes.number,
        messages: PropTypes.array,
        protected: PropTypes.bool,
    }),
    lastMessage: PropTypes.shape({
        chatID: PropTypes.string,
        content: PropTypes.any,
        messageID: PropTypes.string,
        messageType: PropTypes.string,
        sendByID: PropTypes.string,
        timestamp: PropTypes.string,
    }),
    newMessages: PropTypes.number,
    selected: PropTypes.bool,
    users: PropTypes.array,
    onClick: PropTypes.func,
    highlightProtected: PropTypes.bool,
};
