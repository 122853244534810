import React, {useState} from 'react';
import  sAction  from 'sAction';

import InputText from '../formElements/InputText';

export default function CargoNetSearch() {
    const [value, setValue] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        sAction.getCargoNetPackageInfo(value.replaceAll('-', ''));
    };

    return (
        <form className='cargonetSearch' onSubmit={handleSubmit}>
            <span className='cargonetSearch-label'>{sAction.translate('LBL_PACKAGES_SEARCH', 'acm_customers_requests')}:</span>
            <InputText
                containerClassName='cargonetSearch-input'
                className="withBorder"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={sAction.translate('LBL_SEARCH_PACKAGE', 'acm_customers_requests')}
            />
            <button type='submit' className='cargonetSearch-icon icon-search'></button>
        </form>
    )
}
