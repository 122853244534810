export default function acmCompliantEfa(data){
    const poskozena_cast = this.dataGet(data.prefix + "/fields/poskozena_cast/value");
    const neposkozena_cast = this.dataGet(data.prefix + "/fields/neposkozena_cast/value");
    const comp_reinv = this.dataGet(data.prefix+"/fields/comp_reinv/value");
    const comp_culprit = this.dataGet(data.prefix + "/fields/comp_culprit/value");
    const comp_culprit_info = this.dataGet(data.prefix + "/fields/comp_culprit_info/value");
    const comp_inv_damage = this.dataGet(data.prefix+"/fields/comp_inv_damage/value");

    if(!poskozena_cast ||
        !neposkozena_cast ||
        !comp_reinv ||
        !comp_culprit  ||
        !comp_culprit_info ||
        comp_inv_damage <= 0
    ){

            if(!poskozena_cast){
                this.dataSet(data.prefix+"/fields/poskozena_cast/customClass", "error");
            }

            if(!neposkozena_cast){
                this.dataSet(data.prefix+"/fields/neposkozena_cast/customClass", "error");
            }

            if(!comp_reinv){
                this.dataSet(data.prefix+"/fields/comp_reinv/customClass", "error");
            }

            if(!comp_culprit){
                this.dataSet(data.prefix+"/fields/comp_culprit/customClass", "error");
            }

            if(!comp_culprit_info){
                this.dataSet(data.prefix+"/fields/comp_culprit_info/customClass", "error");
            }

            if(comp_inv_damage <= 0){
                this.dataSet(data.prefix+"/fields/comp_inv_damage/customClass", "error");
            }

            this.error(this.translate("LBL_ERR_INVALID_UZAVRENA_REQUEST", "acm_compliant"));
            return;
        }

        const dataEm = {
            parent_type: data.module,
            parent_id: data.id,
            parent_name: data.name,
            to_addrs: "efa@sps-sro.sk",
            emailTemplate : "22396916-518a-d113-30ad-5ece1c79d80f",
            callback: "acmCompliantEfaCallback",
        };
            
        this.openEmailPopup(dataEm);
   
}