import PropTypes from 'prop-types';
import React, {useContext, useCallback} from 'react';
import Snackbar from '@mui/joy/Snackbar';
import {ChatContext} from 'ROOT/src/contexts/ChatContext';
import ChatAvatar from './ChatAvatar';
import sAction from 'sAction';

export default function Notifications({chatOpen}) {
    const {notifications, closeNotification, users, setSelectedChat} = useContext(ChatContext);

    const handleNotificationClick = useCallback((notification) => {
        sAction.dataSet('conf/chat/open', true);
        setSelectedChat(notification.chatId);
        closeNotification(notification.id);
    }, []);

    return (
        <div className={'chatNotificationsWrapper' + (chatOpen ? ' chatOpen' : '')} key={'chatNotificationsWrapper'}>
            <div className='chatNotificationsStack'>
                {notifications.map((notification) => {
                    return (
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={notification.open}
                            onClose={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                closeNotification(notification.id);
                            }}
                            className='notification'
                            autoHideDuration={8000}
                            key={notification.id}
                            onClick={() => handleNotificationClick(notification)}
                        >
                            <ChatAvatar className='notificationImg' user={users[notification.userId]} />

                            <div className='notificationContent'>
                                <b className='notificationUserName'>{notification.userName} {notification.chatType === 'group' ? (
                                    <div className='notificationGroup'>
                                        <span className='iconfas-group' />
                                        <p className='notificationGroupName'>{notification.chatName}</p>
                                    </div>
                                ) : null}
                                </b>
                                <p className='notificationText'>{notification.messagePreview.substring(0, 80)}</p>
                            </div>
                            <div className='notificationClose' onClick={() => closeNotification(notification.id)} key={notification.id}>
                                <i className='icon-closeIconLight' />
                            </div>
                        </Snackbar>
                    );
                })}
            </div>
        </div>
    );
}

Notifications.propTypes = {
    chatOpen: PropTypes.bool,
};
