import PropTypes from 'prop-types';
import React, {useMemo, memo} from 'react';
import Avatar from '@mui/material/Avatar';
import sAction from 'sAction';

const ChatAvatar = memo(function ChatAvatar({user, className}) {
    const profilePhoto = useMemo(() => {
        if (!user?.profile_photo) {
            return null;
        }
        return sAction.getImageUrl(user.profile_photo);
    });

    if (!profilePhoto) {
        const userColor = sAction.chatCore.generateUserColorFromId(user.id);
        const userAvatarText = `${user.firstName?.substring(0, 1) || ''}${user.lastName?.substring(0, 1) || ''}`;
        return (
            <Avatar
                alt={user.name}
                className={`chatAvatar ${className}`}
                style={{
                    backgroundColor: userColor,
                    color: '#fff',
                }}
            >
                {userAvatarText}
            </Avatar>
        );
    }

    return (
        <Avatar
            alt={user.name}
            src={profilePhoto}
            className={`chatAvatar ${className}`}
        />
    );
});

export default ChatAvatar;

ChatAvatar.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profile_photo: PropTypes.string,
        online: PropTypes.any,
    }).isRequired,
    className: PropTypes.string,
};
