
export default function actCloseCreateNew(paramData){

  if (paramData.canSave === true) {
      var closeStatus = "Held";
      if(paramData.module == "Tasks"){
          closeStatus = "Completed";
      }

      this.dataSet(paramData.prefix+"/fields/status/value", closeStatus);
      this.dataSet(paramData.prefix+"/changes/fields/status", closeStatus);
  }

  ///
  var self = this;
  this.saveRecordBase(paramData, data => {
    
    if (data.state == true) {
      
      this.dataSet(paramData.prefix+"/changes", {fields: [], files: []});

      this.detailPredefinedFields = [];

      if(paramData.newModule == "Opportunities"){
        this.detailPredefinedFields.push({name: "sales_stage", value: "Prospecting", type: "field"});
      };
  
      const ignoreFields = [
        "id",
        "created_by_name",
        "date_entered",
        "date_modified",
        "date_start",
        "duration_hours",
        "status",
      ];

      const fields = this.dataGet(paramData.prefix+"/fields").toJS();
  
      fields.forEachObject( (field, fname) => {
        if(ignoreFields.indexOf(fname) >= 0){
          return;
        }
        if(!field.def){
          return;
        }
        
        if(field.def.type == "parent"){
          if(paramData.newModule == "Opportunities"){
            if(field.def.parent_type_value === "Accounts"){
                self.detailPredefinedFields.push({
                  type: "relate_simple",
                  name: "account_id",
                  fieldName: "account_name",
                  value: { name: field.value, id: field.def.id_value }
                });
            }
          }
          else{
            this.detailPredefinedFields.push({
              name: "parent_name", 
              id_name: "parent_id", 
              parentName: field.value, 
              parentId: field.def.id_value, 
              parentModule: field.def.parent_type_value, 
              type: "parent"
            });
          }
        }
        else{
          this.detailPredefinedFields.push({name: fname, value: field.value, type: "field"});
        }
      });
      
      if(paramData.type == "rightPanel"){
          this.closeRightPanel();
      }

      if(paramData.type == "popup"){
        this.unLoad();
        this.popupDetail({
          module: paramData.newModule,
          record: "",
          saveCallback: data => {
            if(paramData.newModule == "Opportunities"){
              this.reloadSubpanelByWay("view", paramData.newModule);
            }
            else{
              this.reloadSubpanelByWay("view", "Activities");
              this.reloadSubpanelByWay("view", "History");
            }
            
          },
          exitCallback: data => {
      
          }
        });
      }
      else{
        
        if(window.location.hash == "#detail/" + paramData.newModule){
          window.location.hash = "#detail/" + paramData.newModule;
          this.route();
        }
        else{
          window.location.hash = "#detail/" + paramData.newModule;
        }
        
      }

      
      

      /*
      if(this.getModuleName() != paramData.module){
        this.closeRightPanel();
        this.unLoad();
      }else{
        window.location.hash = "#list/" + msg.module;
      }
      */
    } else {
      self.error(data.msg);
    }
  });
}