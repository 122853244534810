import PropTypes from 'prop-types';
import React, {useMemo, useContext, useEffect, useState} from 'react';
import {Resizable} from 're-resizable';
import Dropdown from '@mui/joy/Dropdown';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import {ChatContext} from 'ROOT/src/contexts/ChatContext';
import ChatAvatarOnline from './ChatAvatarOnline';
import CreateEditGroupChat from './CreateEditGroupChat';
import AdminGroupChatList from './AdminGroupChatList';
import GlobalChatSettings from './GlobalChatSettings';
import Loader from '../loader';
import sAction from 'sAction';

import Input from '@mui/joy/Input';
import ChatWindow from './ChatWindow';
import UsersList from './UsersList';
import ChatsList from './ChatsList';
import Notifications from './Notifications';

export default function Chat(props) {
    const {
        users,
        selectedChat,
        userIsActive,
        imagePreview,
        setImagePreview,
        activeScreen,
        setActiveScreen,
        getUserChats,
    } = useContext(ChatContext);
    const [search, setSearch] = useState('');
    const [state, setState] = useState({
        width: window.innerWidth * 0.35 < 680 ? 680 : window.innerWidth * 0.35,
        height: window.innerHeight - 44,
        modified: false,
    });
    // const [openCoolDown, setOpenCoolDown] = useState(true);

    // useEffect(() => {
    //     if (!props.data.open) {
    //         setSelectedChat(null);
    //     }
    //     setOpenCoolDown(true);
    //     setTimeout(() => {
    //         console.log('setOpenCoolDown');
    //         setOpenCoolDown(false);
    //     }, 500);
    // }, [props.data.open]);

    let handleClickAway = (e) => {
        console.log('handleClickAway', e);
        if(props.data.open && e.target.id !== 'chatIcon') {
        // if(props.data.open && !openCoolDown) {
            console.log('action closeChat');
            sAction.closeChat();
        }
    };

    useEffect(() => {
        // if (!props.data.open) {
        //     setSelectedChat(null);
        // }
        if (props.data.open) {
            getUserChats();
        }
    }, [props.data.open]);

    useEffect(() => {
        // reset search when chat is opened
        setSearch('');
    }, [selectedChat]);

    // on window resize listener
    useEffect(() => {
        /**
         * handleResize
         */
        const handleResize = () => {
            if (!state.modified) {
                setState({
                    width: window.innerWidth * 0.35 < 680 ? 680 : window.innerWidth * 0.35,
                    height: window.innerHeight - 44,
                    modified: false,
                });
            } else if (state.width > window.innerWidth) {
                setState({
                    width: window.innerWidth - 10,
                    height: window.innerHeight - 44,
                    modified: false,
                });
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const chatWindowScreen = useMemo(() => {
        switch (activeScreen?.view) {
            case 'createGroupChat':
                return <CreateEditGroupChat user={props.user} data={activeScreen?.data}/>;
            case 'editGroupChat':
                return <CreateEditGroupChat user={props.user} edit={true} chatId={selectedChat} data={activeScreen?.data} />;
            case 'adminGroupChatList':
                return <AdminGroupChatList user={props.user} data={activeScreen?.data} />;
            case 'globalChatSettings':
                return <GlobalChatSettings user={props.user} data={activeScreen?.data} />;
            default:
                return <ChatWindow user={props.user} chatId={selectedChat} data={activeScreen?.data} chatOpen={props.data.open} />;
        }
    }, [activeScreen, selectedChat, props.data.open]);

    const isAdmin = useMemo(() => {
        return !!sAction.dataGet('conf/user/is_admin') === true || !!sAction.dataGet('conf/user/is_superUser') === true;
    }, []);

    if (!!sAction.dataGet('conf/enableChat') !== true) {
        return null;
    }

    if (!props.user.id || !users) {
        return <Loader />;
    }

    return (
        <div id="chat">
            <ClickAwayListener onClickAway={handleClickAway}>
                <div className={'chatWrapperSlide' + (props.data.open ? ' open' : '')}>
                    <Resizable
                        className='chatWrapperResizable'
                        size={{width: state.width, height: state.height}}
                        onResizeStop={(e, direction, ref, d) => {
                            // max width is window width
                            setState({
                                width: state.width + d.width > window.innerWidth ? window.innerWidth : state.width + d.width,
                                height: window.innerHeight - 44,
                                modified: true,
                            });
                        }}
                        // allow only vertical resizing
                        enable={{
                            top: false,
                            right: false,
                            bottom: false,
                            left: true,
                            topRight: false,
                            bottomRight: false,
                            bottomLeft: false,
                            topLeft: false,
                        }}
                    >
                        <div className='chatWrapper open'>
                            <div className='chatContent'>
                                {imagePreview ?
                                    <div className='chatImagePreview'>
                                        <div className='imagePreviewControls'>
                                            <a href={sAction.getImageUrl(imagePreview)} download>
                                                <button className='downloadChatImage'>
                                                    <span className='iconfa-download' />
                                                </button>
                                            </a>
                                            <button onClick={() => setImagePreview(null)} className='closeChatImagePreview'>
                                                <span className='iconfa-close' />
                                            </button>
                                        </div>
                                        <img src={sAction.getImageUrl(imagePreview)} alt='image preview' />
                                    </div> :
                                    <>
                                        <div className='chatHeader'>
                                            <div className='chatUserProfileWrapper'>
                                                <ChatAvatarOnline user={users[props.user.id]} online={userIsActive} />
                                                <p className='chatUserName'>{users[props.user.id]?.name}</p>
                                            </div>

                                            <Dropdown>
                                                <MenuButton
                                                    slots={{root: IconButton}}
                                                    slotProps={{root: {color: 'neutral'}}}
                                                    className='chatHeaderMenuButton'
                                                >
                                                    <span className='iconfas-menuDotsVert' />
                                                </MenuButton>
                                                <Menu variant="plain" placement="bottom-end" size="lg">
                                                    <MenuItem onClick={() => setActiveScreen({view: 'globalChatSettings'})}>
                                                        <span className='iconfas-settings' />
                                                        {sAction.translate('LBL_CHAT_GLOBAL_SETTINGS')}
                                                    </MenuItem>
                                                    <MenuItem onClick={() => setActiveScreen({view: 'createGroupChat'})}>
                                                        <span className='iconfas-group' />
                                                        {sAction.translate('LBL_CHAT_CREATE_GROUP_CHAT')}
                                                    </MenuItem>
                                                    {isAdmin && (
                                                        <MenuItem
                                                            color="primary"
                                                            onClick={() => setActiveScreen({view: 'adminGroupChatList'})}
                                                        >
                                                            <span className='iconfas-groupSettings' />
                                                            {sAction.translate('LBL_CHAT_ADMIN_GROUP_CHAT_LIST')}
                                                        </MenuItem>
                                                    )}
                                                </Menu>
                                            </Dropdown>
                                        </div>
                                        <div className='chatBody'>
                                            <div className='chatBodyChats chatBox'>
                                                <Input
                                                    placeholder={sAction.translate('LBL_CHAT_SEARCH')}
                                                    variant="soft"
                                                    className='chatSearchFiled'
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                />
                                                {search.length <= 0 && (
                                                    <ChatsList user={props.user} markSelected={true} />
                                                )}
                                                <UsersList user={props.user} search={search} />
                                            </div>
                                            <div className='chatBodyMessages chatBox'>
                                                {chatWindowScreen}
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </Resizable>
                </div>
            </ClickAwayListener>

            <Notifications chatOpen={props.data.open} />
        </div>
    );
}

Chat.propTypes = {
    data: PropTypes.shape({
        open: PropTypes.bool,
    }),
    user: PropTypes.shape({
        id: PropTypes.string,
    }),
};
