import PropTypes from 'prop-types';
import React, {useContext, useCallback, useMemo, useEffect, useState} from 'react';
import {ChatContext} from 'ROOT/src/contexts/ChatContext';
import UserListItem from './UserListItem';
import sAction from 'sAction';
import Loader from '../loader';

export default function UsersList(props) {
    const {users, setSelectedChat, chats, getUserChats} = useContext(ChatContext);
    const [showMoreUsers, setShowMoreUsers] = useState(false);

    if (!users) {
        return <Loader />;
    }

    useEffect(() => {
        if (props.search?.length > 0) {
            setShowMoreUsers(true);
        } else {
            setShowMoreUsers(false);
        }
    }, [props.search]);

    /**
     * selectUser
     * @param {object} user
     */
    const selectUser = (user) => {
        console.log('selectUser --> ', user);
        // call selectCallback if provided instead of selecting or creating new chat
        if (props?.selectCallback) {
            console.log('selectCallback --> ', user);
            props.selectCallback(user);
            return;
        }

        // try to find chat with this user where user and props.user.id is participant and type is direct
        const chat = Object.values(chats).find((chat) => {
            return (
                chat.type === 'direct' &&
                chat.participants.includes(user.id) &&
                chat.participants.includes(props.user.id)
            );
        });

        if (chat) {
            console.log('chat found --> ', chat);
            setSelectedChat(chat.id);
            return;
        }

        // if chat not found create new chat with this user
        const participants = [user.id];
        sAction.chatCore.createNewChat(participants, 'direct', (data) => {
            console.log('new chat created --> ', data.data);
            if (data?.data?.id) {
                getUserChats();
                setSelectedChat(data?.data?.id);
            } else {
                console.warn('error creating new chat --> ', data);
            }
        });
    };

    const handleShowMoreUsers = useCallback(() => {
        setShowMoreUsers(!showMoreUsers);
    }, [showMoreUsers]);

    const usersList = useMemo(() => {
        /**
         * filterUsers - filter users by filter prop
         * @param {object} user
         * @returns {boolean}
         */
        const filterUsers = (user) => {
            return !props.filter || !props.filter?.includes(user.id);
        };

        /**
         * excludeCurrentUser - exclude current user from list
         * @param {object} user
         * @returns {boolean}
         */
        const excludeCurrentUser = (user) => {
            return props.includeCurrentUser || user.id !== props.user.id;
        };

        /**
         * searchByName - search users by name if search is provided
         * @param {object} user
         * @returns {boolean}
         */
        const searchByName = (user) => {
            return !(props.search?.length > 0) ||
                user.name.toLowerCase().includes(props.search.toLowerCase());
        };

        let usersListOut = Object.values(users)
            .filter(excludeCurrentUser)
            .filter(filterUsers)
            .filter(searchByName);

        if (!showMoreUsers && !props.showAllUsers) {
            usersListOut = usersListOut.slice(0, 6);
        }

        return usersListOut;
    }, [users, showMoreUsers, props.search, props?.filter, props?.showAllUsers]);

    return (
        <div className={'usersList ' + props?.className}>
            <p className='usersListLabel'>Uživatelé</p>
            <div className='usersListStack'>
                { usersList.map((user) => {
                    return (
                        <UserListItem
                            key={user.id}
                            user={user}
                            onClick={() => selectUser(user)}
                        />
                    );
                })}
                {!props?.showAllUsers && Object.keys(users).length > 6 && props.search?.length <= 0 && (
                    <p className={'usersListShowMore ' + (showMoreUsers ? 'active' : '')} onClick={handleShowMoreUsers}>
                        {!showMoreUsers ? sAction.translate('LBL_CHAT_SHOW_MORE_USERS') : sAction.translate('LBL_CHAT_SHOW_LESS_USERS')}
                    </p>
                )}
            </div>
        </div>
    );
}

UsersList.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string,
    }),
    search: PropTypes.string,
    selectCallback: PropTypes.func,
    filter: PropTypes.array,
    showAllUsers: PropTypes.bool,
    className: PropTypes.string,
    includeCurrentUser: PropTypes.bool,
};
