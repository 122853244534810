import React from 'react';
import CustomerRequestReplyCommunicationHistory from "../../../components/popup/acm_customers_requests/CustomerRequestReplyCommunicationHistory";

/**
 * Call from buttons from details and from activity buttons from detail
 *
 * @param data
 */
export default function openCommunication(data){
    this.openRightPanel(
        <CustomerRequestReplyCommunicationHistory
            acm_customers_requestsID={data.id}
        />
    );
}