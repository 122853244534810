export default function mergeCompliants(data){

    let errArr = [];
    if(!data.mainCompl){
        errArr.push("Je potřeba vybrat primární reklamaci pro sloučení.");
    }
    let complArr = [];
    

    if(data.complArr.length < 1 || (data.complArr.length == 1 && data.complArr[0] === data.mainCompl)){
        errArr.push("Je potřeba vybrat alespoň jednu reklamaci pro sloučení.");
    }

    if(errArr.length > 0){
        this.error(errArr.join('<br>'));
        return;
    }
    
    this.confrim("Opravdu si přejete tyto reklamace sloučit do jedné ("+data.mainComplName+")?" , () => {
        this.popupHide();
        this.load();
        const params = {
            action: "merge_compliants",
            mainCompl : data.mainCompl,
            complArr : data.complArr,
        };

        this.rest.post("customAction", params, (resp) => {
            this.unLoad();
            if (resp.errCode == "OK") {
                this.alert(this.translate("LBL_MERGE_SUCESSFULL", "acm_compliant"));
                if(data.callback){
                    data.callback({status : true});
                }
            }
            else{
                this.error(resp.errMsg);
                if(data.callback){
                    data.callback({status : false});
                }
            }
        });

    })
    
}