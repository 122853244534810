export default function acmCompliantClose(data){
    const params = {
        action: "compliantClose",
        record: data.id,
    };

    
    this.load();
    this.rest.post("customAction", params, (resp) => {
        this.unLoad();
        if (resp.errCode == "OK") {
            location.reload();
        }
        else{
            this.error(resp.errMsg);
        }
    });
}

