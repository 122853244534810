import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';
import sAction from 'sAction';

export default function GlobalChatSettings() {
    const [muteNotifications, setMuteNotifications] = useState(false);

    useEffect(() => {
        // load user preferences from local storage
        const chatConfig = sAction.getStorage('chatConfig');
        if (chatConfig && chatConfig?.muteNotifications !== undefined) {
            setMuteNotifications(chatConfig.muteNotifications);
        }
    }, []);

    useEffect(() => {
        // save user preferences to local storage
        let chatConfig = sAction.getStorage('chatConfig');
        if (!chatConfig) {
            chatConfig = {};
        }
        if (chatConfig?.muteNotifications !== muteNotifications) {
            sAction.toast({
                type: 'success',
                name: sAction.translate('LBL_CHAT_SETTINGS_SAVED'),
                description: ' ',
            });
        }
        chatConfig.muteNotifications = muteNotifications;
        sAction.setStorage('chatConfig', chatConfig);
    }, [muteNotifications]);

    return (
        <div className='chatSettings'>
            <h3 className='chatSettingsHeadline'>
                {sAction.translate('LBL_CHAT_GLOBAL_SETTINGS')}
            </h3>

            <div className='chatSettingsForm'>
                <div className='chatSettingsInputs'>
                    <div className='inputLine'>
                        <label className='createGroupLabel'>
                            {sAction.translate('LBL_CHAT_SETTINGS_MUTE_NOTIFICATIONS')}
                        </label>
                        <Switch
                            checked={muteNotifications}
                            onChange={() => setMuteNotifications(!muteNotifications)}
                            inputProps={{'aria-label': 'controlled'}}
                        />
                    </div>
                </div>
                <p className='chatSettingsNote'>
                    {sAction.translate('LBL_CHAT_SETTINGS_NOTE')}
                </p>
            </div>
        </div>
    );
}

GlobalChatSettings.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profile_photo: PropTypes.string,
        online: PropTypes.any,
    }),
    edit: PropTypes.bool,
    chatId: PropTypes.string,
    data: PropTypes.object,
};
