export default function acmCompliantChangeState(data){

    const prefix = data.prefix;

    if(data.newState == "likvidace"){
        const poskozena_cast = this.dataGet(data.prefix + "/fields/poskozena_cast/value");
        const neposkozena_cast = this.dataGet(data.prefix + "/fields/neposkozena_cast/value");

        if(!poskozena_cast
        || !neposkozena_cast){

            if(!poskozena_cast){
                this.dataSet(data.prefix+"/fields/poskozena_cast/customClass", "error");
            }

            if(!neposkozena_cast){
                this.dataSet(data.prefix+"/fields/neposkozena_cast/customClass", "error");
            }

            this.error(this.translate("LBL_ERR_INVALID_LIKVIDACE_REQUEST", "acm_compliant"));
            return;
        }
    }
    else if(data.newState == "uzavrena"){
        const comp_reinv = this.dataGet(data.prefix+"/fields/comp_reinv/value");
        const comp_culprit = this.dataGet(data.prefix + "/fields/comp_culprit/value");
        const comp_culprit_info = this.dataGet(data.prefix + "/fields/comp_culprit_info/value");

        if(!comp_reinv
        || !comp_culprit 
        || !comp_culprit_info){

            if(!comp_reinv){
                this.dataSet(data.prefix+"/fields/comp_reinv/customClass", "error");
            }

            if(!comp_culprit){
                this.dataSet(data.prefix+"/fields/comp_culprit/customClass", "error");
            }

            if(!comp_culprit_info){
                this.dataSet(data.prefix+"/fields/comp_culprit_info/customClass", "error");
            }


            this.error(this.translate("LBL_ERR_INVALID_UZAVRENA_REQUEST", "acm_compliant"));
            return;
        }
    }

    /*switch(data.newState){
        case "reseni":
        case "obchod":
        case "bez_naroku":
        case "likvidace":
        case "uzavrena":
            
            break;
    }*/

    const updateData = {
        way: data.prefix + "/fields/comp_state",
        name: "comp_state",
        value: data.newState,
        type: "enum"
      };
      this.saveField(updateData);
      const saveData = {
        prefix: data.prefix,
        id: data.id,
        module: "acm_compliant",
        canSave: true
      };
      this.saveRecord(saveData);
}