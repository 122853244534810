import React, {useState} from 'react';
import  sAction  from 'sAction';

export default function CsGetRequest({getCount, notAssignedRequests}) {
    const [load, setLoad] = useState(false);

    const getCustomersRequestsCount = () => {
        setLoad(true)
        sAction.rest.get('assignCustomerRequest', (res) => {
            if (res.status && res.description) {
                sAction.toast({
                    name: sAction.translate(res.message, 'acm_customers_requests'),
                    description: sAction.translate(res.description, 'acm_customers_requests') + res.data.name,
                    type: 'success',
                    redirect: `#detail/acm_customers_requests/${res.data.id}`,
                });
                getCount();
            } else if (res.status) {
                sAction.toast({
                    name: sAction.translate(res.message, 'acm_customers_requests'),
                    type: 'warning',
                });
                getCount();
            } else {
                sAction.error(sAction.translate(res.message, 'acm_customers_requests'))
            }
            setLoad(false)
        }, false);
    }

    const color = notAssignedRequests <= 10 ? 'green' : notAssignedRequests > 10 && notAssignedRequests <= 50 ? 'orange' : 'red';

    const colors = {
        green: '#18da59',
        red: '#E92222',
        orange: '#f5a020',
    }

    return (
        <div className={`csContainer`} style={{backgroundColor: colors[color]}}>
            <div className={`csContainerIconBox`} onClick={() => getCustomersRequestsCount()}>
                <div className='csEmptyDivForFlex'></div>
                {load ? <div className='csLoader'></div> : <div className={`csContainer-icon icon-download`}/>}
                <div className='csRequestNumBox'>
                    <div className={`csRequestNum`}>{notAssignedRequests > 99 ? '99+' : notAssignedRequests}</div>
                </div>
            </div>
        </div>
    )
}