import rest from "./rest";
import CoripoWebsocket from './websocket/CoripoWebsocket';
import ChatCore from 'ROOT/src/components/chat/ChatCore';

export default function init(paramData, callback) {

  if(window.location.search != ""){
    var urlParams = new URLSearchParams(window.location.search);
    var urlData = {};
    urlParams.forEach((val,key) => {
      urlData[key] = val;
    });
    urlData = this.toBase64(urlData);
    var r = urlParams.get("r");
    if(r != null){
      
      window.location.href = window.location.origin+"/#"+r+"/"+urlData;
    }
  }


  this.dataReducer = "app";
  this.param = paramData;

  this.notificationInterval = null;
  this.currencies = null;
  this.customClass = null;
  this.colorTemplate = new Array(
    "#898CFF",
    "#71E096",
    "#5AD0E5",
    "#BB96FF",
    "#FF89B5",
    "#F5A26F",
    "#DA97E0",
    "#EFCE7E",
    "#668DE5",
    "#90D4F7",
    "#ED6D79",
    "#FF96E3",
    "#93BFDC",
    "#E09399",
    "#B6D96E",
    "#FF9393",
    "#79CB9F",
    "#BDCCAA",
    "#5AD0E5",
    "#65D8CC"
  );
  this.detailTempData = {};
  this.rest = new rest(this);
  this.websocket = new CoripoWebsocket(this);
  this.chatCore = new ChatCore(this);

  this.objectLoopInit();
  this.overwriteStyle();

  
  if (window.location.hash.indexOf('#login') === -1) {
    this.rest.get("checksid", data => {
      this.initData(data);
      this.route(true,data);
      this.renderReact();
      this.checkNewNotifications(); 
    });
  } else {
    this.rest.get("trns?language="+this.getLanguage(), data => {
      this.app_strings = data.lang;
      this.dsClear();
      this.dsAdd("set","conf/front_version",data.softwareInfo.front_version);
      this.dsAdd("set","conf/back_version",data.softwareInfo.back_version);
      this.dsAdd("set","conf/softwareName",data.softwareInfo.softwareName);
      this.dsAdd("set", "conf/view", "login");
      this.dsProcess();
      this.renderReact();
    });
  }
}
