import PropTypes from 'prop-types';
import React, {useState, useEffect, useContext} from 'react';
import sAction from 'sAction';
import {ChatContext} from 'ROOT/src/contexts/ChatContext';
import ChatsList from './ChatsList';

export default function AdminGroupChatList({user}) {
    const {setActiveScreen, chats} = useContext(ChatContext);
    const [groupChats, setGroupChats] = useState([]);

    useEffect(() => {
        fetchData();
        /**
         * fetchData - fetch group chats
         */
        async function fetchData() {
            const resp = await sAction.chatCore.getAllGroupChats();
            setGroupChats(resp);
        }
    }, [chats]);

    /**
     * handleSelectGroup
     * @param {string} chatId
     */
    const handleSelectGroup = (chatId) => {
        setActiveScreen({view: 'editGroupChat', data: {
            chatId: chatId,
            admin: true,
            chat: groupChats.find((chat) => chat.id === chatId),
        }});
    };

    return (
        <div className='adminGroupChatList'>
            <div className='adminGroupChatListHeader'>
                <p className='chatsListAdminLabel'>Group chaty</p>
                <div className='chatsListAdminAddBtn'
                    onClick={() => setActiveScreen({view: 'createGroupChat', data: {admin: true}})}
                >
                    <span className='iconfa-add' />
                </div>
            </div>
            {groupChats && groupChats?.length > 0 && (
                <ChatsList
                    user={user}
                    groupsOnly={true}
                    showAllChats={true}
                    selectCallback={handleSelectGroup}
                    chats={groupChats}
                />
            )}
        </div>
    );
}

AdminGroupChatList.propTypes = {
    user: PropTypes.any,
};
