import React, {useState, createContext, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import sAction from 'sAction';

export const ChatContext = createContext({});

export default function ChatContextProvider({children}) {
    // chat context states...
    const [users, setUsers] = useState(null);
    const [chats, setChats] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [userIsActive, setUserIsActive] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [activeScreen, setActiveScreen] = useState(null);
    const currentUser = useMemo(() => {
        const userId = sAction.dataGet('conf/user/id');
        return users ? users[userId] : null;
    }, [users]);

    // chat context functions...
    // init chat
    useEffect(() => {
        getAllUsers();
        getUserChats();

        // register listener for user activity
        window.addEventListener('focus', () => {
            setUserIsActive(true);
        });
        window.addEventListener('blur', () => {
            setUserIsActive(false);
        });

        return () => {
            window.removeEventListener('focus', () => {
                setUserIsActive(true);
            });
            window.removeEventListener('blur', () => {
                setUserIsActive(false);
            });
        };
    }, []);

    /**
     * getAllUsers
     */
    const getAllUsers = () => {
        sAction.chatCore.getAllUsers((data) => {
            setUsers(data.data);
        });
    };

    /**
     * getUserChats
     */
    const getUserChats = () => {
        sAction.chatCore.getAllUserChats((data) => {
            data.data.chats.forEach((chat) => {
                sAction.dataSet('conf/chat/newMessages/' + chat.id, parseInt(chat.unreadMessages));
            });

            setChats(data.data.chats);
        });
    };

    /**
     * @param {object} notification
     */
    const addNotification = (notification) => {
        setNotifications([...notifications, notification]);
    };

    /**
     * closeNotification
     * @param {string} id
     */
    const closeNotification = (id) => {
        // NOTE: buggy close is called onClose on every snackbar in stack
        setNotifications(notifications.map((n) => {
            if (n.id === id) {
                n.open = false;
            }
            return n;
        }));
    };

    /**
     * handleIncomingMessage
     * @param {object} messageData
     */
    const handleIncomingMessage = (messageData) => {
        console.log('handleIncomingMessage', messageData);
        const chatId = (messageData.chatID).toString();
        const chat = chats.find((c) => c.id === chatId);
        if (!chat) {
            console.warn('Chat not found', chatId, chats);
            return;
        }

        const lastMessageIndex = chat.messages[chat.messages.length - 1]?.index;
        messageData.index = lastMessageIndex ? lastMessageIndex + 1 : 1;

        chat.messages.push(messageData);

        if (messageData.sendByID !== currentUser.id) {
            // add to newMessages
            const newMessages = sAction.dataGet('conf/chat/newMessages/' + chatId);
            sAction.dataSet('conf/chat/newMessages/' + chatId, newMessages ? newMessages + 1 : 1);
            // add to newMessages on chat
            chat.unreadMessages = newMessages ? newMessages + 1 : 1;
        }

        setChats([...chats]);
    };

    useEffect(() => {
        // inject callback to sAction chatCore
        sAction.chatCore.handleMessageCallback = (messageData) => {
            const user = users ? users[messageData.sendByID] : null;
            if (!user) {
                console.warn('[Chat] Message received User not found id: ' + messageData.sendByID, messageData, users);
                return;
            }

            handleIncomingMessage(messageData);
            castNotification(messageData, user);
        };

        // inject callback to sAction chatCore
        sAction.chatCore.markChatAsReadCallback = (chatId, lastMessageSeenId, userId = currentUser.id) => {
            const chat = chats.find((c) => c.id === chatId);
            if (!chat) {
                console.warn('[Chat] >> Chat not found markChatAsRead', chatId, chats);
                return;
            }
            if (userId === currentUser.id) {
                chat.unreadMessages = 0;
            }
            chat.lastSeenMessages[userId] = lastMessageSeenId;
            setChats([...chats]);
        };

        // inject callback to sAction chatCore
        sAction.chatCore.handleSentConfirmationCallback = (data) => {
            const chat = chats.find((c) => c.id === data.chatID);
            if (!chat) {
                console.warn('[Chat] >> Chat not found handleSentConfirmation', data.chatID, chats);
                return;
            }
            const message = chat.messages.find((m) => m.messageID === data.messageID);
            if (!message) {
                console.warn('[Chat] >> Message not found handleSentConfirmation', data.messageID, chat.messages);
                return;
            }
            message.sent = true;
            setChats([...chats]);
        };
    }, [notifications, users, chats]);

    useEffect(() => {
        if (!users) {
            return;
        }
        // inject callback to sAction chatCore
        sAction.chatCore.handleUserOnlineCallback = (data, online) => {
            const usersCopy = {...users};
            usersCopy[data.userID].online = online;
            setUsers(usersCopy);
        };
    }, [users]);

    /**
     * castNotification
     * @param {object} messageData
     * @param {object} user
     * @returns {boolean}
     */
    const castNotification = (messageData, user) => {
        // add notification if message is not from selected chat and not from myself
        if ((selectedChat !== messageData.chatID || sAction.dataGet('conf/chat/open') !== true || !userIsActive) &&
        messageData.sendByID !== currentUser.id
        ) {
            // add notification to state notifications if chat is not open
            if (sAction.dataGet('conf/chat/open') !== true) {
                const chatData = chats.find((c) => c.id === messageData.chatID);
                addNotification({
                    id: messageData.messageID,
                    userName: user.name,
                    userId: messageData.sendByID,
                    messagePreview: messageData.content,
                    timestamp: messageData.timestamp,
                    profilePicture: sAction.getImageUrl(user.profile_photo),
                    chatId: messageData.chatID,
                    chatType: chatData?.type,
                    chatName: chatData?.name,
                    open: true,
                });
            }
            const chatConfig = sAction.getStorage('chatConfig');
            if (!chatConfig || chatConfig?.muteNotifications === false) {
                // play notification sound from static folder
                const audio = new Audio('audio/mixkit-correct-answer-tone-2870.wav');
                audio.play();
            }
        }
        return true;
    };

    /**
     * previewImage
     * @param {string} image
     */
    const previewImage = (image) => {
        setImagePreview(image);
    };

    // DEBUG: print chat context states
    useEffect(() => {
        console.log('%c [debug] >> ChatContextProvider users|chats|notifications|selectedChat|userIsActive', 'color: #bada55',
            users, chats, notifications, selectedChat, userIsActive);
    }, [users, chats, notifications, selectedChat, userIsActive]);

    const ChatContextData = {
        users,
        chats,
        notifications,
        closeNotification,
        selectedChat,
        setSelectedChat,
        setChats,
        getUserChats,
        userIsActive,
        previewImage,
        setImagePreview,
        imagePreview,
        currentUser,
        activeScreen,
        setActiveScreen,
    };

    return (
        <ChatContext.Provider value={ChatContextData}>
            {children}
        </ChatContext.Provider>
    );
}

ChatContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
