import React from "react";
import PureComponent from "../pure";

import TabContainer from "../formElements/TabContainer";
import DetailTabContent from "./DetailTabContent";
import ViewPanelMenu from "./../viewPanelMenu/viewPanelMenu";
import Subpanels from "../subpanel/Subpanels";

import DetailCustomComponent from "./DetailCustomComponent";
import DetailInvTabComponent from "./DetailInvTabComponent";
import DetailTimeline from "./DetailTimeline";

import sAction from "sAction";
import CustomDetailLines from "./CustomLines/CustomDetailLines";
import DetailDashboard from "./DetailDashboard";
import DetailViewActivityButtons from "./DetailViewActivityButtons";
import Tags from "../Tags/Tags";
import DetailAclAccessTabComponent from "./Users/DetailAclAccessTabComponent";
import PermissionAccess from "./Permissions/PermissionAccess";
import CustomDataTop from "./CustomDataTop/CustomDataTop";

class Detail extends PureComponent {
  constructor(props) {
    super(props);
    this.rightPanelCustom = ['Meetings', 'Calls'];
    this.detail = React.createRef();
    this.uId = sAction.dataGet('conf/user/id');
  }
  state = {
    contextMenu: null
  };

  componentWillMount() {
    sAction.fieldsForValidation = new Array(); // vyprázdnění pole pro validaci
    // kontrola, zda nemame neulozene zmeny ve storage
    var way = this.props.way;

    sAction.setStorage('stopChanges', "0")
    var foundChanges = sAction.getStorage('changes-'+this.props.data.module+'-'+this.uId);
    if (foundChanges
      &&
      (Object.keys(foundChanges.changes.fields).length !== 0 || Object.keys(foundChanges.changes.customData).length !== 0)
      &&
      (foundChanges.expiration - Date.now() > 0)
      &&
      this.props.view !== "calendar"
      ){
      // pokud mame zaznam o neulozene zmene, tak ho vyuzijeme
      sAction.dsClear();
      var changesFields = foundChanges.changes.fields;
      changesFields.forEachObject(function (value, index) {
        if (index === "relate") {
          value.forEachObject(function (value, index) {
            sAction.dsAdd("set", way + "/fields/" + index + "/value", value);
          });
        } else if (index === "parent_type") {
          sAction.dsAdd("set", way + "/fields/parent_name/def/parent_type_value", value);
        } else if (index === "ss") {
          var res = value.split("|")
          sAction.dsAdd("set", way + "/fields/ss/def/selected", res);
        }

        if (sAction.dataGet(way + "/fields/" + index)) {
          sAction.dsAdd("set", way + "/fields/" + index + "/value", value);
        }
        if (sAction.dataGet(way+"/fields/email1")){
          sAction.dsAdd("set",way+"/fields/email1/def/emails",foundChanges.emails);
        }
      });
      sAction.dsAdd("set",way+"/changes",foundChanges.changes);
      sAction.dsAdd("set",way+"/changes/forceChange", true);
      if (foundChanges.module === "Quotes" || foundChanges.module === "Opportunities"){
        var akce = {
          type: "DETAILLOCALSTORAGERESTORE",
          content: foundChanges,
          prefix: way
        };
        sAction.dsAddCustom(akce);

      }
      else {
        // pokud bychom uz meli ve storu customData a zaroven mame null v LS
        // tak je nesmysl ho tam davat misto dat co uz tam jsou
        if (foundChanges.customData !== null) {
          sAction.dsAdd("set", way + "/customData", foundChanges.customData);
        }
      }
      sAction.dsProcess();
    }
  }
  componentDidMount() {

    const data = this.props.data;
    sAction.fillDetailPredefinedFields(this.props.way, data.id);

    sAction.custom("load", {
      prefix: this.props.way,
      module: data.module
    });

    sAction.checkDetailUrl(this.props.way);
    sAction.detailTempData[this.props.way] = {
      tabChange: true
    };
    document.onkeydown = e => {
      sAction.detailOnKeyDown(e, this.props);
    };
  }
  componentWillUpdate() {

    var storeChanges = this.props.data.changes;
    var forceChange = storeChanges.get("forceChange");

    if (
      (
        sAction.getStorage('stopChanges') === "0"
        ||
        !sAction.getStorage('stopChanges')
      )
    &&
      (
        forceChange
        ||
        storeChanges.get("fields").size > 0
        ||
        storeChanges.get("files").length > 0
      )
    )
    {
      console.log('---------- ukladam zmeny do localStorage');

      const disabledModules = ["Emails"]

      var date = new Date();
      date.setDate(date.getDate() + 1); //+1 den

      if (!disabledModules.includes(this.props.data.module)) {

        var changes = {
          expiration: date.getTime(date),
          id: this.props.data.id,
          name: this.props.data.name,
          module: this.props.data.module,
          changes: this.props.data.changes,
          customData: this.props.data.customData,
        }
        if (this.props.data.fields.get("email1")) {
          changes.emails = this.props.data.fields.get("email1").get("def").get("emails");
        }
        sAction.setStorage('changes-' + this.props.data.module + '-' + this.uId, sAction.toJSON(changes))
      }
    }
  }

  componentDidUpdate(prevProps) {
    const data = this.props.data;
    let set_assigned_user = false;
    if (prevProps.data.module != this.props.data.module) {
      sAction.checkDetailUrl(this.props.way);
      var container = document.querySelector(
        ".pageContainer .detailViewContainer"
      );
      container.scrollTop = 0;
    }
    if (prevProps.data.id != this.props.data.id) {
      sAction.custom("load", {
        prefix: this.props.way,
        module: this.props.data.module
      });
      set_assigned_user = true;
    }
    sAction.fillDetailPredefinedFields(this.props.way, data.id, set_assigned_user);

    if (data.doCustom == true) {
      sAction.custom("load", {
        prefix: this.props.way,
        module: this.props.data.module
      });
    }
    //____________________________________________________Zkontroluje špatné fieldy a pdoel toho vyznačí červeně celý tab
    const errors = this.detail.current.querySelectorAll(
      ".valueContainer.error"
    );
    this.detail.current
      .querySelectorAll(".acmTabContainerButton")
      .forEach(tab => {
        tab.classList.remove("error");
      });
    errors.forEach(item => {
      var tabId = item.closest(".detailViewTabContentBlok").dataset.index;
      const tab = this.detail.current.querySelector(
        ".acmTabContainerButton[data-index='" + tabId + "']"
      );
      tab.classList.add("error");
    });

    //____________________________________________________END
  }
  componentWillUnmount() {
    let way = this.props.way;
    sAction.detailTempData[way] = null;

    const data = sAction.dataGet(way);
    if ( ! data) {
      return
    }

    if (way.includes("calendar")){
      sAction.removeChangesFromLS(data.module, sAction.dataGet('conf/user/id'))
    }
    else{
      sAction.removeChangesFromLS(data.modul, sAction.dataGet('conf/user/id'))
    }
  }

  tabChange(value) {
    sAction.changeDetailTab(value, this.props.way, this.props.data.activeTab);
  }

  render() {
    const data = this.props.data;
    const module = data.module;
    const way = this.props.way;
    const view = this.props.view;
    const hideHeader = this.props.hideHeader;
    var renderDataTabContent = [];
    var activeTab = null;

    var tabs = [];
    let tabsExtraClass = {};
    let customerRequestID = null;
    switch(module){
      case 'acm_internal_communication':
        customerRequestID = data?.get('fields')?.get('acm_customers_requests_acm_internal_communication_1_name')?.get('def')?.get('id_value') || null;
      break;
      case 'acm_official_communication':
        customerRequestID = data?.get('fields')?.get('acm_customers_requests_acm_official_communication_2_name')?.get('def')?.get('id_value') || null;
      break;
      case 'acm_customers_requests':
        customerRequestID = data.id;
      break;
    }
    data.tabs.forEach((tab, index) => {
      if (tab.hidden) { // pro dynamicke skryvani/odkryvani tabu za pochodu dle aktualne vybranych dat na zaznamu
        tabs.push("");
        return;
      }
      tabs.push(sAction.translate(tab.name.toUpperCase(), module));
      if (tab.active == true) {
        activeTab = index;
      }

      if(module === 'acm_compliant' && tab.name === 'lbl_detailview_panel15'){
        tabsExtraClass[index] = 'acmCompliantExceptionsTab'
      }

      if (tab.type == "standard") {
        renderDataTabContent.push(
          <DetailTabContent
            prefix={way}
            module={module}
            index={index}
            way={way + "/tabs/" + index}
            key={tab.id}
            data={tab}
            rowCount={data.rowCount}
            id={data.id}
            fields={data.fields}
            readonly={data.readonly}
            acl={data.acl}
          />
        );
      } else if (tab.type == "invitees") {
        renderDataTabContent.push(
          <DetailInvTabComponent
            active={tab.active}
            module={module}
            id={data.id}
            way={way + "/customData"}
            data={data.customData}
            key={tab.id}
          />
        );
      } else if (tab.type == "acl_access" && sAction.dataGet("conf/user/is_admin") == 1) {
        renderDataTabContent.push(
          <DetailAclAccessTabComponent
            active={tab.active}
            module={module}
            id={data.id}
            way={way + "/customData"}
            data={data.customData}
            key={tab.id}
          />
        );
      }
    });
    const isNewRecord = !data.id;
    const header = (
      <div className="header">
        <div className="title">
          <span className={"detailViewHeaderIcon icon-" + data.module} />
          <span className="detailViewName">
            {isNewRecord ? sAction.translate("LBL_NEW_RECORD") : sAction.decodeHTMLEntities(data.name)}
          </span>
        </div>
        <Tags
          way={way}
          record={data.id}
          module={module}
          tags={data.tags}
        />
        {!isNewRecord && (
          <DetailViewActivityButtons
            activities={data.activities}
            prefix={way}
            parentId={data.id}
            parentModule={data.module}
            parentName={data.name}
            customerRequestID={customerRequestID}
          />
        )}
      </div>
    );
    return (
      <div className="pageContainer DetailViewMainContainer">
        {data.type != "rightPanel" && data.type != "calendar" && (
          <ViewPanelMenu data={data} prefix={way} />
        )}
        <div className="detailViewContainer">

          <div className="detailCard">
            {hideHeader !== true && header}
            <div className="detailView" ref={this.detail}>
              {data.customDataTop != null &&
                <CustomDataTop data={data.customDataTop} module={module}/>
              }
              {data.timeline != null && data.type != "popup" && data.type != "calendar" && (
                <DetailTimeline
                  id={data.id}
                  data={data.timeline}
                  module={data.module}
                  way={way}
                  field={data.fields.get(data.timeline.field)}
                  type={data.type}
                />
              )}
              {data.dashboard != null && data.type != "rightPanel" && (
                <DetailDashboard
                  data={data.dashboard}
                  id={data.id}
                  module={data.module}
                  way={way + "/dashboard"}
                />
              )}
              <TabContainer
                onChange={(e, value) => this.tabChange(value)}
                value={activeTab}
                tabs={tabs}
                tabs_extra_class={tabsExtraClass}
              />
              <div className="detailViewTabContent">{renderDataTabContent}</div>
            </div>
          </div>
          {data.customData != null &&
            data.type != "rightPanel" &&
            (data.type != "popup" || module == "Opportunities") && (
              <DetailCustomComponent
                view={view}
                module={module}
                id={data.id}
                way={way + "/customData"}
                prefix={way}
                data={data.customData}
                readonly={data.readonly}
                type={data.type}
              />
            )}
          {data.module == 'acm_coripoReports' && data.type != 'popup' && data.type != 'calendar' && (
            <PermissionAccess
              way={way}
              acl={data.acl}
              view={view}
              data={data.customData}
            />
          )}
          {data.get && data.get('customData') != null && data.get('customData').get && data.get('customData').get('customLines') && data.type != "rightPanel" &&
            data.type != "popup" && (
              <CustomDetailLines
                view={view}
                module={module}
                way={way}
                data={data.get('customData').get('customLines')}
                type={data.type}
                acl={data.acl}
              />
            )}
          {data.id != null && <Subpanels data={data.subpanels} way={way} acl={data.acl} />}
        </div>
      </div>
    );
  }
}

export default Detail;
