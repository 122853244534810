import React, { useEffect, useState, useRef } from 'react';
import sAction from 'sAction';
import CsRequestRecord from './CsRequestRecord';
import Loader from '../../loader';

export default function CsRequests({ getCount, requestsNumbers }) {
    const [open, setOpen] = useState(false);
    const [records, setRecords] = useState({ byMe: null, byOthers: null });
    const [load, setLoad] = useState(true);
    const [error, setError] = useState('');
    const [requestsCount, setRequestsCount] = useState({ byMe: null, byOthers: null });
    const [animate, setAnimate] = useState(false);

    const containerRef = useRef(null);

    const getCustomersRequests = () => {
        sAction.rest.get('getCustomersRequests', (res) => {
            if (res.status) {
                setRecords(res.data);
            } else {
                setError(res.data);
            }
            setLoad(false);
        });
    };

    const renderRecords = (records) => {
        return records.map((record, i) => {
            return <CsRequestRecord index={i} data={record} key={record.id} />;
        });
    };

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        getCount();
        const intervalId = setInterval(() => {
            getCount();
        }, 15000);

        // Cleanup the event listener when the component unmounts
        return () => {
            clearInterval(intervalId);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (open) {
            getCustomersRequests();
        }
    }, [open]);

    useEffect(() => {
        if (requestsCount.byMe && requestsCount.byOthers && (requestsCount.byMe < requestsNumbers.byMe || requestsCount.byOthers < requestsNumbers.byOthers)) {
            setAnimate(true);
        }
        setTimeout(() => {
            setAnimate(false);
        }, 2000);
        setRequestsCount(requestsNumbers);
    }, [requestsNumbers]);

    const requestTotal = parseInt(requestsNumbers.byMe) + parseInt(requestsNumbers.byOthers);
    const color = requestTotal <= 10 ? 'green' : requestTotal > 10 && requestTotal <= 50 ? 'orange' : 'red';

    const colors = {
        green: '#18da59',
        red: '#E92222',
        orange: '#f5a020',
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            ref={containerRef}
            className={`csContainer csContainer-${open} ${animate ? 'csAnimation csAnimation-animate' : ''}`}
            style={{ backgroundColor: open ? '#FFF' : colors[color] }}
        >
            <div className={`csContainerIconBox csRequestIconBox csContainer-${open}`} onClick={() => setOpen(!open)}>
                <div className='csEmptyDivForFlex'></div>
                <div className={`csContainer-icon icon-acm_customers_requests`} />
                <div className='csRequestNumBox'>
                    <div className='csRequestNum csRequestNum-my'>{requestsNumbers.byMe}</div>
                    <div className='csRequestNum csRequestNum-assigned'>{requestsNumbers.byOthers}</div>
                </div>
            </div>
            {open ? (
                <div className='csRequestsContent'>
                    {load ? (
                        <Loader />
                    ) : error ? (
                        sAction.translate(error, 'acm_customers_requests')
                    ) : (
                        <>
                            <div className='csRequestContentBox'>
                                <div className='csRequestContentBox-myLine csRequestContentBox-line'>
                                    {sAction.translate('LBL_MY_CREATED_REQUESTS', 'acm_customers_requests')}
                                </div>
                                <div className='csRequestContentRecords'>
                                    {records.byMe ? renderRecords(records.byMe) : sAction.translate('LBL_NO_CUSTOMERS_REQUESTS', 'acm_customers_requests')}
                                </div>
                            </div>
                            <div className='csRequestContentBox'>
                                <div className='csRequestContentBox-othersLine csRequestContentBox-line'>
                                    {sAction.translate('LBL_MY_ASSIGNED_REQUESTS', 'acm_customers_requests')}
                                </div>
                                <div className='csRequestContentRecords'>
                                    {records.byOthers ? renderRecords(records.byOthers) : sAction.translate('LBL_NO_CUSTOMERS_REQUESTS', 'acm_customers_requests')}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ) : null}
        </div>
    );
}