
import $ from "jquery";
import detailDefault from "../../detailDefault";
export default class detailCalls extends detailDefault{
    load(sAction,data) {

      if(!sAction.dataGet(data.prefix+"/id")){
        const parent_type = sAction.dataGet(data.prefix+"/fields/parent_name/def/parent_type_value");
        const parent_id = sAction.dataGet(data.prefix+"/fields/parent_name/def/id_value");
        if(parent_id && (parent_type == "Accounts" || parent_type == "Contacts")){
          this.setContactinfo(sAction, {module : parent_type, id : parent_id}, data.prefix);
        }
      }

      var prefixInv = data.prefix+"/customData/invitees";
      
      if(data.view == "calendar" || data.view == "home"){
        prefixInv = data.prefix.replace("/detailView", "");
        prefixInv += "/invitees";
      }  

      this.searchInvitees(sAction, data.prefix, prefixInv);

      if(data.view != "calendar" && data.view != "home"){
        const id = sAction.dataGet(data.prefix+"/id");
        if(!id){
          const date = new Date();
          const year = date.getFullYear();
          var month = date.getMonth()+1;
          if(month < 10){
            month = "0"+month
          }
          var day = date.getDate();
          if(day < 10){
            day = "0"+day;
          }
          var hours = date.getHours();
          if(hours < 10){
            hours = "0"+hours;
          }
          var minutes = date.getMinutes();
          if(minutes < 10){
            minutes = "0"+minutes
          }
          const today = year+"-"+month+"-"+day+" "+hours+":"+minutes+":00";
          sAction.dsClear();
          sAction.dsAdd("set",data.prefix+"/fields/date_start/value",today)
          sAction.dsAdd("set",data.prefix+"/changes/fields/date_start",today)
          sAction.dsAdd("set",data.prefix+"/fields/duration_hours/value",0)
          sAction.dsAdd("set",data.prefix+"/fields/duration_hours/def/minutesValue",15)
          sAction.dsAdd("set",data.prefix+"/changes/fields/duration_hours",0)
          sAction.dsAdd("set",data.prefix+"/changes/fields/duration_minutes",15)
          sAction.dsAdd("set",data.prefix+"/fields/direction/value","Inbound")
          sAction.dsAdd("set",data.prefix+"/changes/fields/direction","Inbound")
          sAction.dsProcess();
        }
      }
    }
    update(sAction,data) {
      if(data.field == "parent_id"){
        const parent_type = sAction.dataGet(data.prefix+"/fields/parent_name/def/parent_type_value");
        if(parent_type == "Accounts" || parent_type == "Contacts"){
          if(data.value.id){
            this.setContactinfo(sAction, {module : parent_type, id : data.value.id}, data.prefix);
          }
        }

        var prefixInv = data.prefix+"/customData/invitees";
        if(data.view == "calendar" || data.view == "home"){
          var prefixInv = data.prefix.replace("/detailView", "").replace("/callView", "");
          prefixInv += "/invitees";
        }
        else if(data.view != "detail"){
          return;
        }
        this.searchInvitees(sAction, data.prefix, prefixInv);
      }
    }
    searchInvitees(sAction, prefix, prefixInv){
      const parent = sAction.dataGet(prefix+"/fields/parent_name");
      if(parent && parent.def && parent.def.get("parent_type_value") == "Accounts" && parent.def.get("id_value") != ""){
        sAction.searchInvitees({parent: parent.toJS()}, prefixInv); 
      }
    }

    setContactinfo(sAction, params, prefix){
      params.action = "getActivityContactInfo";
      sAction.rest.post("customAction", params, function(resp) {
        if(resp.errCode == "OK"){
            sAction.dsClear();
            sAction.dsAdd("set",prefix+"/fields/phone_c/value", resp.data.phone)
            sAction.dsAdd("set",prefix+"/changes/fields/phone_c", resp.data.phone)
            sAction.dsAdd("set",prefix+"/fields/mobile_c/value", resp.data.mobile)
            sAction.dsAdd("set",prefix+"/changes/fields/mobile_c",resp.data.mobile)
            sAction.dsProcess();
            
        }
      });
    }
}
