// Czech
/**
 * @param {number} n
 * @param {number} d
 * @param {string} a
 * @returns {string}
 */
function f(n, d, a) {
    return a[d >= 0 ? 0 : a.length === 2 || n < 5 ? 1 : 2];
}

const strings = {
    prefixAgo: 'před',
    prefixFromNow: 'před',
    // prefixFromNow: 'za', // future dates are disabled for now
    suffixAgo: null,
    suffixFromNow: null,
    seconds: function(n, d) {
        return f(n, d, ['< min', '< min']);
    },
    minute: function(n, d) {
        return f(n, d, ['minutou', 'minutu']);
    },
    minutes: function(n, d) {
        return f(n, d, ['%d min.', '%d minuty', '%d minut']);
        // return f(n, d, ['%d minutami', '%d minuty', '%d minut']);
    },
    hour: function(n, d) {
        return f(n, d, ['hodinou', 'hodinu']);
    },
    hours: function(n, d) {
        return f(n, d, ['%d hod.', '%d hodiny', '%d hodin']);
        // return f(n, d, ['%d hodinami', '%d hodiny', '%d hodin']);
    },
    day: function(n, d) {
        return f(n, d, ['%d dnem', '%d den']);
    },
    days: function(n, d) {
        return f(n, d, ['%d dny', '%d dny', '%d dní']);
    },
    month: function(n, d) {
        return f(n, d, ['%d měsícem', '%d měsíc']);
    },
    months: function(n, d) {
        return f(n, d, ['%d měsíci', '%d měsíce', '%d měsíců']);
    },
    year: function(n, d) {
        return f(n, d, ['%d rokem', '%d rok']);
    },
    years: function(n, d) {
        return f(n, d, ['%d lety', '%d roky', '%d let']);
    },
};

export default strings;
