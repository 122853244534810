export default function acmCompliantAssaignToComplaintGroup(data){
    const params = {
        action: "compliantAssaignToComplaintGroup",
        record: data.id,
    };

    
    this.load();
    this.rest.post("customAction", params, (resp) => {
        this.unLoad();
        if (resp.errCode == "OK") {
            window.location.href = window.location.origin+"/#list/acm_compliant";
        }
        else{
            this.error(this.translate(resp.errMsg, "acm_compliant"));
        }
    });
}

