export default function acmCompliantRequestApprove(data){


    const comp_inv_damage = this.dataGet(data.prefix + "/fields/comp_inv_damage/value");
    const invoice_number = this.dataGet(data.prefix + "/fields/invoice_number/value");
    const inv_num_damage_date = this.dataGet(data.prefix + "/fields/inv_num_damage_date/value");
    const sap = this.dataGet(data.prefix + "/fields/sap/value");

    let emptyFields = [];

    if(!comp_inv_damage || comp_inv_damage == 0){
        this.dataSet(data.prefix+"/fields/comp_inv_damage/customClass", "error");
        emptyFields.push(this.translate("LBL_COMP_INV_DAMAGE", "acm_compliant"));
    }

    if(!invoice_number){
        this.dataSet(data.prefix+"/fields/invoice_number/customClass", "error");
        emptyFields.push(this.translate("LBL_INVOICE_NUMBER", "acm_compliant"));
    }

    if(!inv_num_damage_date){
        this.dataSet(data.prefix+"/fields/inv_num_damage_date/customClass", "error");
        emptyFields.push(this.translate("LBL_INV_NUM_DAMAGE_DATE", "acm_compliant"));
    }

    if(!sap){
        this.dataSet(data.prefix+"/fields/sap/customClass", "error");
        emptyFields.push(this.translate("LBL_SAP", "acm_compliant"));
    }


    if(emptyFields.length > 0){
        this.error(this.translate("LBL_EROOR_SAP_MISSING_FIELDS") + emptyFields.join(", "));
        return;
    }

    const params = {
        action: "compliant_request_approve",
        acm_compliant: data.id,
    };
    this.load();
    this.rest.post("customAction", params, (resp) => {
        this.unLoad();
        if (resp.errCode == "OK") {
            location.reload();
        }
        else{
            this.error(resp.errMsg);
        }
    });

}