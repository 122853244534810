export default function createCustomerRequestsFromComplaint(data) {
    const prefix = data.prefix;
    this.detailPredefinedFields = [];

    // Data from Detail
    const packageNumber = this.dataGet(`${prefix}/fields/balik_cislo/value`).replaceAll('-', '');
    const personName = this.dataGet(`${prefix}/fields/comp_pers_name/value`);
    const personLastName = this.dataGet(`${prefix}/fields/comp_pers_lastname/value`);
    const phone = this.dataGet(`${prefix}/fields/comp_pers_phone/value`);
    const email = this.dataGet(`${prefix}/fields/comp_pers_email/value`);

    const params = {
        shipmentNr: packageNumber,
        ticketName: '',
    };
    this.load();
    this.rest.post('getShipmentInfo', params, cnRes => {
        if (cnRes.status) {
            this.rest.post('getDataForNewRequest', {packageNumber, packageData: cnRes.data?.info}, res => {
                this.unLoad();
                if (res.status) {
                    this.detailPredefinedFields.push({
                        name: 'acm_zasilk8845_baliky_ida',
                        fieldName: `acm_zasilka_baliky_acm_customers_requests_1_name`,
                        value: {name: res.data.packageName, id: res.data.packageId},
                        type: 'relate_simple',
                    });
                    this.detailPredefinedFields.push({
                        type: 'field',
                        name: 'request_type',
                        value: 'complaint',
                    });
                    this.detailPredefinedFields.push({
                        type: 'field',
                        name: 'customer_first_name',
                        value: personName,
                    });
                    this.detailPredefinedFields.push({
                        type: 'field',
                        name: 'customer_last_name',
                        value: personLastName,
                    });
                    this.detailPredefinedFields.push({
                        type: 'field',
                        name: 'phone',
                        value: phone,
                    });
                    this.detailPredefinedFields.push({
                        type: 'field',
                        name: 'email',
                        value: email,
                    });
                    this.detailPredefinedFields.push({
                        type: 'field',
                        name: 'status',
                        value: 'not-assigned',
                    });
                    this.detailPredefinedFields.push({
                        name: 'assigned_user_id',
                        fieldName: 'assigned_user_name',
                        value: {name: '', id: ''},
                        type: 'relate_simple',
                    });
                    this.popupDetail({
                        module: 'acm_customers_requests',
                        record: '',
                        saveCallback: ret => {
                            this.unLoad();
                        },
                    });
                }
            })
        } else {
            if (res.data === 'LBL_SOLVER_IS_UNREACHABLE') {
                this.error(this.translate('LBL_SOLVER_IS_UNREACHABLE', 'acm_customers_requests'));
            } else {
                this.error(this.translate('LBL_PACKAGE_NOT_FOUND', 'acm_customers_requests'));
            }
            this.unLoad();
        }
    });
}
