/**
 * Class for handling socket calls from BE
 */
export default class CoripoWebsocket {
    /** @property {storeAction} sAction almost whole coripo functionality in one class :) */
    /** @property {string} sessionID id of session in database */
    /** @property {string} currentUserID  id of user in database */
    /** @property {string} baseUrl for opening sockets, get url from script.js */
    /** @property {string} websocketUserUrl  url for opening user socket */
    /** @property {WebSocket} userSocket socket for communication user and server */

    /**
     *
     * @param {storeAction} sAction
     */
    constructor(sAction) {
        this.sAction = sAction;
        this.baseUrl = sAction.param.websockets; // get url from script.js
        this.websocketUserUrl = this.websocketUserUrlBase = `${this.baseUrl}/userSocket`;
        this.reconnectCount = 0;
    }

    /**
     * Function that handles user sockets messages
     *
     * @param {event} event
     * @return
     */
    onMessageUser = (event) => {
        // console.log('Socket message:  '+ event.data);
        const messageString = event.data;
        let messageObject = null;
        try {
            messageObject = JSON.parse(messageString);
        } catch (e) {
            console.error('Socket message is not JSON -> ', event.data);
            return;
        }

        switch (messageObject?.['type']) {
            case 'chat':
                return this.sAction.chatCore.handleMessage(messageObject);
            case 'notification':
                // TODO rework this nightmare: this.sAction.checkNewNotifications();
                console.error('Implement notification');
                return;
            default:
                console.error('Unknown action');
                return;
        }
    };

    /**
     * Handle errors from sockets
     *
     * @param {event} error
     */
    onError = (error) => {
        console.error('Socket Error!!!', error); // TODO smazat / handling
        console.error('Socket Error!!!', error.data); // TODO smazat / handling

        setTimeout(() => {
            if (!this.isOpen(this.userSocket) && this.reconnectCount < 10) {
                this.reconnectCount++;
                console.warn('[ws] userSocket is not open! recCount: ' + this.reconnectCount);
                console.warn('[ws] reconnecting...');
                this.sAction.rest.get('recreateUserChanel', (data) => {
                    console.log('recreateUserChanel', data);

                    setTimeout(() => {
                        this.setupSockets(this.currentUserID, this.sessionID);
                    }, 1000);
                }, false);
            }
        }, 2000);
    };

    /**
     * isOpen
     * @param {WebSocket} ws
     * @return {boolean}
     */
    isOpen(ws) {
        return ws.readyState === ws.OPEN;
    }

    waitForConnection = (callback, interval) => {
        if (this.userSocket.readyState === 1) {
            callback();
        } else {
            const that = this;
            // optional: implement backoff for interval here
            setTimeout(function() {
                that.waitForConnection(callback, interval);
            }, interval);
        }
    };

    /**
     *
     * @param {string|object} msg
     */
    sendUser(msg) {
        msg = typeof msg === 'string' ? msg : JSON.stringify(msg);

        if (!this.isOpen(this.userSocket) || this.userSocket.readyState !== 1) {
            console.warn('[ws] userSocket is not open!');
            console.warn('[ws] reconnecting...');
            this.setupSockets(this.currentUserID, this.sessionID);
        }

        this.waitForConnection(() => {
            this.userSocket.send(msg);
        }, 1000);
    }

    /**
     *
     * @param {string} currentUserID
     * @param {string} sessionID
     */
    setupSockets(currentUserID, sessionID) {
        this.currentUserID = currentUserID;
        this.sessionID = sessionID;
        // TODO switch to session ID
        this.websocketUserUrl = `${this.websocketUserUrlBase}/${this.currentUserID}`; // don't append id, it could go wrong
        this.userSocket = new WebSocket(this.websocketUserUrl);
        this.userSocket.onerror = this.onError;
        this.userSocket.onopen = (event) => {
            console.log('Opened userSocket: ', event.data);
        };
        this.userSocket.onmessage = this.onMessageUser;
        this.reconnectCount = 0;
    }
}
