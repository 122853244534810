export default function acmCompliantInfo(data){
    
    
    //const balik = this.dataGet(data.prefix+"/fields/acm_zasilka_baliky_acm_compliant_name/value");
    const balik = this.dataGet(data.prefix+"/fields/balik_cislo/value"); 

    if(!balik){
        this.popup("acmCompliantSetBalikNum", {
            header : this.translate("LBL_COMPLIANT_WRITE_BALIK_NUM_TITLE", "acm_compliant"),
            params: {id: data.id}
        });
    }
    else{
        this.popup("load");
        const params = {
            action: "compliant_cargonet_info",
            balik: balik,
            checkBalik: false,
        };

        this.rest.post("customAction", params, (resp) => {
            if (resp.errCode == "OK") {
                this.popup("acmCompliantCargonetInfo",{
                    header : this.translate("LBL_COMPLIANT_CARGONET_INFO", "acm_compliant"),
                    params: {info: resp.result, id: data.id}
                });
            }
            else{
                this.error(resp.errMsg);
            }
        });
    }
}