import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import ChatAvatarOnline from './ChatAvatarOnline';
import TimeAgo from 'react-timeago';
import czechStrings from './utils/timeagoCzechStrings';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import sAction from 'sAction';

export default function ChatListItem({user, lastMessage, newMessages, selected, className, onClick}) {
    const formatter = buildFormatter(czechStrings);

    const messagePreview = useMemo(() => {
        switch (lastMessage.messageType) {
            case 'system':
                return sAction.translate(lastMessage.content);
            case 'image':
                return `media: ${lastMessage.content}`;
            case 'coripoLink':
                return `${lastMessage.content?.url}`;
            default:
                return lastMessage.content;
        }
    }, [lastMessage]);

    return (
        <div className={`chatListItem ${className} ${selected ? 'selected' : ''}`} onClick={onClick}>
            <div className='chatListItemAvatarWrapper'>
                <ChatAvatarOnline user={user} online={user.online} />
                <div className='chatListItemInfo'>
                    <p className='chatListItemName'>{user.name}</p>
                    <p className='chatListItemLastMessage'>{messagePreview}</p>
                </div>
            </div>
            <div className='chatListItemNewMessages'>
                <p className='chatListItemLastMessageTimestamp'>
                    <TimeAgo
                        date={lastMessage.timestamp}
                        formatter={formatter}
                        minPeriod={60}
                    />
                </p>
                <p className={`chatListItemNewMessagesCount ${newMessages <= 0 ? 'countZero' : ''}`}>{newMessages}</p>
            </div>
        </div>
    );
}

ChatListItem.propTypes = {
    className: PropTypes.string,
    lastMessage: PropTypes.shape({
        chatID: PropTypes.string,
        content: PropTypes.any,
        messageID: PropTypes.string,
        messageType: PropTypes.string,
        sendByID: PropTypes.string,
        timestamp: PropTypes.string,
    }),
    newMessages: PropTypes.number,
    selected: PropTypes.bool,
    user: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profile_photo: PropTypes.string,
        online: PropTypes.any,
    }),
    onClick: PropTypes.func,
};
