import React, {useContext, useMemo, useEffect, useCallback, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import AvatarGroup from '@mui/material/AvatarGroup';
import Dropdown from '@mui/joy/Dropdown';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import LinearProgress from '@mui/joy/LinearProgress';

import {ChatContext} from 'ROOT/src/contexts/ChatContext';
import ChatAvatarOnline from './ChatAvatarOnline';
import ChatAvatar from './ChatAvatar';
import sAction from 'sAction';
import ChatMessages from './ChatMessages';
import ChatWindowMessageField from './ChatWindowMessageField';

export default function ChatWindow({user, chatId, chatOpen}) {
    const {chats, users, setChats, userIsActive, getUserChats, setActiveScreen} = useContext(ChatContext);
    const fetchMessagesCnt = 50;
    const [allMessagesFetched, setAllMessagesFetched] = useState(false);
    const [lastMessageId, setLastMessageId] = useState(null);
    const [loading, setLoading] = useState(false);

    const virtuoso = useRef(null);

    let lastMessageIndex = 0;

    if (!chats || !users) {
        return null;
    }

    const chat = useMemo(() => {
        return chats.find((chat) => chat.id === chatId);
    }, [chats, chatId]);

    const chatUsers = useMemo(() => {
        if (!chat) {
            return null;
        }
        const participants = chat.participants.filter((userID) => userID !== user.id);
        return participants.map((userID) => users[userID]);
    }, [users, chat, user, chatId]);

    useEffect(() => {
        fetchChatMessages();

        // register listener for user activity when user returns to chat set last messages as seen
        window.addEventListener('focus', () => {
            handleUserIsActive();
        });
    }, [chatId, chat]);

    useEffect(() => {
        getLastMessageId();
        if (chat?.messages && chat.messages.length > 0) {
            lastMessageIndex = chat.messages[chat.messages.length - 1]?.index;
        }
    }, [chat, chat?.messages?.length]);

    useEffect(() => {
        // to set last message as seen when user is active and opens chat window with already loaded chat
        if (chatOpen) {
            handleUserIsActive();
        }
    }, [chatOpen]);

    useEffect(() => {
        if (lastMessageId) {
            // scroll to bottom of chat window on open
            if (!virtuoso?.current) {
                return;
            }

            setTimeout(() => {
                const lastMessageIndexTemp = chat.messages[chat.messages.length - 1]?.index;
                if (lastMessageIndexTemp > lastMessageIndex) {
                    lastMessageIndex = chat.messages[chat.messages.length - 1]?.index;
                    virtuoso.current.scrollToIndex({
                        index: lastMessageIndex + 10, // arbitrary number after last message
                        behavior: 'smooth',
                        align: 'end',
                    });
                    const lastSeenMessageId = chat.messages[chat.messages.length - 1]?.messageID;
                    if (userIsActive) {
                        sAction.chatCore.chatSeenFromUser(chatId, lastSeenMessageId);
                    }
                }
            }, 400);
        }
    }, [chatId, lastMessageId, chat?.messages?.length]);

    /**
     * handleUserIsActive - set last message as seen when user is active
     */
    const handleUserIsActive = () => {
        if (!chat) {
            return;
        }

        if (userIsActive && sAction.dataGet('conf/chat/open') === true) {
            const lastSeenMessageId = chat.messages[chat.messages.length - 1]?.messageID;
            sAction.chatCore.chatSeenFromUser(chatId, lastSeenMessageId);
        }
    };

    /**
     * getLastMessageId
     */
    const getLastMessageId = () => {
        if (!chat) {
            return;
        }
        const lastMessage = chat.messages[chat.messages.length - 1];
        if (!lastMessage) {
            return;
        }
        setLastMessageId(lastMessage.messageID);
    };

    /**
     * fetchChatMessages
     * @returns {Promise<void>}
     */
    const fetchChatMessages = async () => {
        // TODO: optimize this function for fetching chat messages
        if (!chat) {
            return;
        }
        // if chat messages at desired count are already fetched, return
        if ((chat.messages && chat.messages.length >= fetchMessagesCnt) || allMessagesFetched) {
            return;
        }
        setLoading(true);
        // fetch chat messages from getChatMessages endpoint
        // if theres only one prefetched message, fetch all messages again to update it
        const lastMessageId = (chat.messages && chat.messages.length > 1) ? chat.messages[chat.messages.length - 1].messageID : null;
        const chatMessages = await sAction.chatCore.getChatMessages(chatId, lastMessageId);

        // update chat messages, add new messages to beginning of array
        const newChats = [...chats];
        const chatIndex = newChats.findIndex((chat) => chat.id === chatId);
        const newChat = {...newChats[chatIndex]};
        newChat.messages = [...chatMessages, ...newChat.messages];
        newChats[chatIndex] = newChat;

        // check if all messageIds are unique remove duplicates
        const uniqueMessages = [];
        newChat.messages.forEach((message) => {
            if (!uniqueMessages.find((m) => m.messageID === message.messageID)) {
                uniqueMessages.push(message);
            }
        });
        newChat.messages = uniqueMessages;
        if (uniqueMessages.length < fetchMessagesCnt) {
            setAllMessagesFetched(true);
        }
        setChats(newChats);
        setLoading(false);
    };

    /**
     * handleRemoveGroupChat with confirmation
     */
    const handleRemoveGroupChat = useCallback(() => {
        sAction.confirm(sAction.translate('LBL_CHAT_REMOVE_GROUP_CHAT_CONFIRM'), () => {
            sAction.chatCore.removeChat(chatId, () => {
                sAction.toast({
                    type: 'success',
                    name: sAction.translate('LBL_CHAT_GROUP_REMOVED'),
                    description: ' ',
                });
                sAction.popupHide();
                getUserChats();
            });
        }, () => sAction.popupHide(), 'high');
    }, [chatId]);

    /**
     * handleRemoveChat
     */
    const handleRemoveChat = useCallback(() => {
        sAction.confirm(sAction.translate('LBL_CHAT_REMOVE_CHAT_CONFIRM'), () => {
            sAction.chatCore.removeChat(chatId, () => {
                sAction.toast({
                    type: 'success',
                    name: sAction.translate('LBL_CHAT_REMOVED'),
                    description: ' ',
                });
                sAction.popupHide();
                getUserChats();
            });
        }, () => sAction.popupHide(), 'high');
    }, [chatId]);

    /**
     * handleLeaveGroupChat
     */
    const handleLeaveGroupChat = useCallback(() => {
        sAction.confirm(sAction.translate('LBL_CHAT_LEAVE_GROUP_CHAT_CONFIRM'), () => {
            sAction.chatCore.leaveGroupChat(chatId, () => {
                sAction.toast({
                    type: 'success',
                    name: sAction.translate('LBL_CHAT_GROUP_LEFT'),
                    description: ' ',
                });
                sAction.popupHide();
                getUserChats();
            });
        }, () => sAction.popupHide(), 'high');
    }, [chatId]);

    if (!chat) {
        return <img src='img/emptyChat.jpg' alt='emptyChat' className='emptyChat' />;
    }

    return (
        <div className='chatWindow'>
            <div className='chatWindowHeader'>
                {chat?.type === 'group' ? (
                    <div className='chatWindowHeaderUserAvatar'>
                        <AvatarGroup max={4} className='chatWindowHeaderAvatarGroup'>
                            {chatUsers.map((user) => (
                                <ChatAvatar key={user.id} user={user} />
                            ))}
                        </AvatarGroup>

                        <p className='chatWindowHeaderUserName'>{chat.name}</p>
                    </div>
                ):(
                    <div className='chatWindowHeaderUserAvatar'>
                        <ChatAvatarOnline user={chatUsers[0]} online={chatUsers[0].online} />
                        <p className='chatWindowHeaderUserName'>{chatUsers[0].name}</p>
                    </div>
                )}

                <Dropdown>
                    <MenuButton
                        slots={{root: IconButton}}
                        slotProps={{root: {color: 'neutral'}}}
                        className='chatWindowHeaderMenuButton'
                    >
                        <span className='iconfas-menuDotsVert' />
                    </MenuButton>
                    {chat?.type === 'group' ? (
                        <Menu variant="plain" placement="bottom-end" size="lg">
                            {!chat?.protected && (
                                <MenuItem onClick={() => setActiveScreen({view: 'editGroupChat'})}>
                                    <span className='iconfas-settings' />
                                    {sAction.translate('LBL_CHAT_SETTINGS')}
                                </MenuItem>
                            )}
                            <MenuItem onClick={handleLeaveGroupChat}>
                                <span className='iconfas-leave' />
                                {sAction.translate('LBL_CHAT_LEAVE_GROUP_CHAT')}
                            </MenuItem>
                            {!chat?.protected && (
                                <MenuItem color="danger" onClick={handleRemoveGroupChat}>
                                    <span className='iconfas-trash' />
                                    {sAction.translate('LBL_CHAT_REMOVE_CHAT')}
                                </MenuItem>
                            )}
                        </Menu>
                    ) : (
                        <Menu variant="plain" placement="bottom-end" size="lg">
                            {/* <MenuItem onClick={() => {
                                console.warn('TODO: implement this...');
                            }}>
                                <span className='iconfas-settings' />
                                {sAction.translate('LBL_CHAT_SETTINGS')}
                            </MenuItem> */}
                            <MenuItem color="danger" onClick={handleRemoveChat}>
                                <span className='iconfas-trash' />
                                {sAction.translate('LBL_CHAT_REMOVE_CHAT')}
                            </MenuItem>
                        </Menu>
                    )}
                </Dropdown>

            </div>
            <div className='chatWindowBodyWrapper'>
                {loading && <div className='chatWindowLoading'>
                    <LinearProgress size="sm" />
                </div>}
                <div className='chatWindowBody' id="chatWindowBody">
                    {chat?.messages && chat.messages.length !== 0 && (
                        <ChatMessages
                            messages={chat.messages}
                            user={user}
                            chatId={chatId}
                            virtuoso={virtuoso}
                            setLoading={setLoading}
                        />
                    )}
                </div>
            </div>
            <ChatWindowMessageField chatId={chatId} />
        </div>
    );
}

ChatWindow.propTypes = {
    chatId: PropTypes.string,
    user: PropTypes.shape({
        id: PropTypes.string,
    }),
    chatOpen: PropTypes.bool,
};
