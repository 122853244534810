export default function emailChangeTemplate(id, props, addVariableHighlights = true, params) {
  const parseData = {
    id,
    sendTo: props.data.to_addrs,
    parent_type: props.data.parent_type,
    parent_id: props.data.parent_id
  };

  this.load();

  this.rest.post("email/getEmailTemplate", parseData, data => {
    this.dsClear();
    if (data != null && typeof data === "object") {
      let description = this.htmlDecode(data.body_html + (props.data.signature ?? ''));
      if(params.replace){
        for (const [replaceKey, value] of Object.entries(params.replace)) {
          description = description.replace(replaceKey,value)
        }
      }
      description = addVariableHighlights ? this.emailAddVariableHighlights(description) : description;
      this.dsAdd("set", props.way + "/name", data.subject || "");
      this.dsAdd("set", props.way + "/emailTemplate", id);
      this.dsAdd("set",props.way + "/description_html", description);
      this.dsAdd("set", props.way + "/updateEmailText", true);
    }
    this.dsAdd("set", "conf/load", false);
    this.dsProcess();
  }, false);

  this.emailGetTemplateAttachments(id, props.way + "/attachments");
}
