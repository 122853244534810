import moment from 'moment';

/**
 * Handle messages from sockets
 */
export default class ChatCore {
    /** @property {storeAction} sAction */
    /** @property {string} userID */

    constructor(sAction) {
        this.sAction = sAction;
        this.userID = '';
        // eslint-disable-next-line
        console.log('ChatCore constructor');
    }

    /**
     * Set user ID for sending data
     * @param {string} userID
     */
    setChatCoreUserID(userID) {
        this.userID = userID;
    }

    /**
     * Entry point from socket, this method should decide which chat is used and properly render it...
     *
     * @param {object} data
     */
    handleMessage(data) {
        switch (data.action) {
            case 'messageFromServer':
                console.log('Prisla ti zprava ze serveru --> ', data.data.content, data);

                // method handleMessage is injected into sAction from ChatContext
                this?.handleMessageCallback(data.data);

                return;
            case 'chatSeenFromServer':
                // TODO datum je prijato v UTC!!!! zobraz v local timu!!!
                console.log(`Chat byl zobrazen --> uzivatel: ${data['data']['seenByID']}, chat:`, data['data']['chatID'], data);
                if (!this?.markChatAsReadCallback) {
                    console.warn('markChatAsReadCallback is not set');
                    return;
                }
                this?.markChatAsReadCallback(data['data']['chatID'], data['data']['lastSeenMessageId'], data['data']['seenByID']);
                return;
            case 'clientOnlineFromServer':
                console.log('Uzivatel je online --> ', data);
                if (!this?.handleUserOnlineCallback) {
                    console.warn('handleUserOnlineCallback is not set');
                    return;
                }
                this?.handleUserOnlineCallback(data.data, true);
                return;
            case 'clientOfflineFromServer':
                console.log('Uzivatel je offline --> ', data);
                if (!this?.handleUserOnlineCallback) {
                    console.warn('handleUserOnlineCallback is not set');
                    return;
                }
                this?.handleUserOnlineCallback(data.data, false);
                return;
            case 'confirmMessageSent':
                console.log('[Chat] Message was sent - confirm', data);
                if (!this?.handleSentConfirmationCallback) {
                    console.warn('handleSentConfirmationCallback is not set');
                    return;
                }
                this?.handleSentConfirmationCallback(data.data);
                return;
        }
        console.error('UNHANDLED MESSAGE ', data);
    }

    /**
     * sendChatMessage
     * @param {string} chatID
     * @param {string} messageType
     * @param {string} content
     * @return {object} // messageData
     */
    sendChatMessage(chatID, messageType, content) {
        console.log('sendChatMessage', chatID, messageType, content);
        const messageData = {
            messageID: this.sAction.generateUUID(),
            chatID: chatID.toString(),
            sendByID: this.userID,
            messageType: messageType,
            content: content,
            timestamp: moment().format('YYYY-MM-DD HH:mm:ss'), // time is rewritten on server
        };

        const msg = {
            type: 'chat',
            action: 'messageFromUser',
            data: messageData,
        };
        // add message via callback to context
        this?.handleMessageCallback(msg.data);
        this.send(msg);

        return messageData;
    }

    /**
     * User has seen chat
     * @param {string} chatId
     * @param {string} lastSeenMessageId
     */
    chatSeenFromUser(chatId, lastSeenMessageId) {
        this.sAction.dataSet('conf/chat/newMessages/' + chatId, 0);
        const msg = {
            type: 'chat',
            action: 'chatSeenFromUser',
            data: {
                chatID: chatId,
                seenByID: this.userID,
                lastSeenMessageId: lastSeenMessageId,
            },
        };
        this?.markChatAsReadCallback(chatId, lastSeenMessageId);
        this.send(msg);
    }

    /**
     * Send message by socket to all listeners
     *
     * @param {string|object} data sending to user socket
     */
    send(data) {
        const msg = typeof data === 'string' ? data : JSON.stringify(data);
        this.sAction.websocket.sendUser(msg);
    }

    /**
     * @param {function} callback
     */
    getAllUsers(callback) {
        this.sAction.rest.fetchData('getAllUsers', 'GET', null, false).then(callback);
    }

    /**
     * @param {function} callback
     */
    getAllUserChats(callback) {
        this.sAction.rest.fetchData('getAllUserChats', 'GET', null, false).then(callback);
    }

    /**
     * createNewChat - create new chat
     * @param {array} participants
     * @param {string} chatType
     * @param {function} callback
     */
    createNewChat(participants = [], chatType = 'direct', callback = () => {}) {
        participants.push(this.userID);
        const requestData = {
            participants: participants,
            chatType: chatType,
        };
        this.sAction.rest.fetchData('createNewChat', 'POST', requestData, false).then((data) => {
            callback(data);
        });
    }

    /**
     * createNewGroupChat - create new chat
     * @param {array} participants
     * @param {string} chatName
     * @param {bool} byAdmin
     * @param {function} callback
     */
    createNewGroupChat(participants = [], chatName, byAdmin, callback = () => {}) {
        if (byAdmin !== true) {
            participants.push(this.userID);
        }
        const requestData = {
            participants: participants,
            chatType: 'group',
            chatName: chatName,
            byAdmin: byAdmin,
        };
        this.sAction.rest.fetchData('createNewChat', 'POST', requestData, false).then((data) => {
            callback(data);
        });
    }

    /**
     * removeChat
     * @param {string} chatID
     * @param {function} callback
     */
    removeChat(chatID, callback) {
        const requestData = {
            chatID: chatID,
        };

        this.sAction.rest.fetchData('removeChat', 'DELETE', requestData, false).then((data) => {
            callback(data);
        });
    }

    /**
     * leaveGroupChat
     * @param {string} chatID
     * @param {function} callback
     */
    leaveGroupChat(chatID, callback) {
        const requestData = {
            chatID: chatID,
        };

        this.sAction.rest.fetchData('leaveGroupChat', 'POST', requestData, false).then((data) => {
            callback(data);
        });
    }

    /**
     * getAllGroupChats - get all group chats (admin)
     * @return {array}
     */
    async getAllGroupChats() {
        return await this.sAction.rest.fetchData('getAllGroupChats', 'GET', null, false).then((data) => {
            return data?.data?.chats;
        });
    }

    /**
     * editGroupChat
     * @param {string} chatId
     * @param {string} chatName
     * @param {array} participants
     * @param {function} callback
     */
    editGroupChat(chatId, chatName, participants, callback) {
        const requestData = {
            chatId: chatId,
            participants: participants,
            chatName: chatName,
        };

        this.sAction.rest.fetchData('editGroupChat', 'POST', requestData, false).then((data) => {
            callback(data);
        });
    }

    /**
     * getChatMessages - get chat messages
     * @param {string} chatID
     * @param {string} lastMessageId
     * @param {int} limit
     * @return {{}}
     */
    async getChatMessages(chatID, lastMessageId = null, limit = 50) {
        const requestData = {chatID: chatID, lastMessageId: lastMessageId, limit: limit};
        if (!lastMessageId) {
            delete requestData.lastMessageId;
        }

        return await this.sAction.rest.fetchData('getChatMessages', 'GET', requestData, false).then((data) => {
            console.log('getChatMessages', data);
            return data?.data?.messages;
        }).catch((data) => {
            console.error('Could not load messages: ', data);
            this.sAction.error('Chat neexistuje'); // TODO
            return {};
        });
    }

    /**
     * @param {string} msg
     * @todo DELETE IT to prod
     * TESTING FUNCTION
     *
     */
    sendMockMessage(msg = 'ZPRAVA JAK CYP') {
        this.sendChatMessage(1, 'text', msg);
    }

    /**
     * @param {string} chatID
     * @todo DELETE IT to prod
     * TESTING FUNCTION
     */
    chatSeenMock(chatID = '1') {
        this.chatSeenFromUser(chatID, '');
    }

    /**
     * generateUserColorFromId - generate user color from id (for avatar, no profile photo)
     * @param {string} id
     * @return {string}
     */
    generateUserColorFromId(id) {
        const colors = [
            '#f44336',
            '#e91e63',
            '#9c27b0',
            '#673ab7',
            '#3f51b5',
            '#2196f3',
            '#03a9f4',
            '#00bcd4',
            '#009688',
            '#4caf50',
            '#8bc34a',
            '#fed330',
            '#ffc107',
            '#ff9800',
            '#ff5722',
            '#795548',
            '#00baff',
            '#607d8b',
        ];
        // if id is longer than 8 chars, cut it
        if (id.length > 8) {
            id = id.substring(0, 8);
        }
        // convert string id to int value
        const idVal = id.split('').reduce((prev, curr) => {
            return prev + curr.charCodeAt(0);
        }, 0);
        const colorIndex = idVal % colors.length;
        return colors[colorIndex];
    }
}
