


export default function popupHide(onClose){
const id = this.getPopupId();

this.dsClear();
this.dsAdd("set","conf/popup"+id+"/show",false);
this.dsAdd("set","conf/popup"+id+"/content",null);
this.dsAdd("set","conf/popup"+id+"/data",{});
this.dsProcess();

if(typeof onClose === "function"){
    onClose();
}

}
