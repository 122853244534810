export default function acmCompliantAction(data){

    var tasks = {};

    const comp_type = this.dataGet(data.prefix+"/fields/comp_type/value")

    if(!comp_type){
        return;
    }

    this.dataGet(data.prefix+"/fields").toJS().forEachObject(field => {
        if(field.def.type == "bool"){
            if(field.name.startsWith(comp_type)){
                tasks[field.name] = field.def.vname;
            }
            
        }
        
    });


    this.popup("acmCompliantAction",{
        header : this.translate("LBL_DEMAND_ACTION", "acm_compliant"),
        params: {tasks: tasks, id: data.id}
    });

}