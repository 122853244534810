import React from "react";
import MaterialDefault from "./MaterialDefault";
import MaterialSelect from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import  sAction  from "sAction";
export default class Select extends MaterialDefault {
  constructor(props) {
    super(props);
    var value = "";
    if (this.props.defaultValue != undefined) {
      value = this.props.defaultValue;
    }

    var open = false;
    if (this.props.open != undefined) {
      open = this.props.open;
    }
    this.state = {
      value: value,
      focus: false,
      openOnFocus: true,
      open: open
    };
  }
  onChange(e) {
    this.setState({
      value: e.target.value,
      open: false
    });
    if (this.props.onChange != undefined) {
      this.props.onChange(e);
    }
  }
  onClose(e) {
    this.setState({ open: false });
    setTimeout(() => { }, 100);
    if (this.props.onClose != undefined) {
      this.props.onClose(e);
    }
  }
  onOpen(e) {
    this.setState({ open: true });
    if (this.props.onOpen != undefined) {
      this.props.onOpen(e);
    }
  }
  onBlur(e) {
    if (this.props.onBlur != undefined) {
      this.props.onBlur(e);
    }
  }
  onKeyDown(e) {
    if (e.keyCode === 13 && this.state.open === false) {
      this.setState({ open: true })
    }

    if (this.props.onKeyDown != undefined) {
      this.props.onKeyDown(e);
    }
  }
  onFocus(e) {
    if (this.props.onFocus !== undefined) {
      this.props.onFocus(e);
    }
  }
  render() {
    var labelRender = null;
    if (this.props.label != undefined) {
      labelRender = (
        <InputLabel htmlFor={this.props.id}>{this.props.label}</InputLabel>
      );
    }
    const options = this.props.options;

    var optionsRender = [];
    options?.forEach((value, key) => {
     if (typeof(value.label) === 'string') {
       value.label = sAction.decodeHTMLEntities(value.label);
     }
      if (options.length > 200) {
        if (value.type != "header") {
          optionsRender.push(<option key={key} value={value.value} >{value.label} {sAction.getStorage("debug") && " ["+value.value+"]"}</option>)
        }
      } else {
        if (value.type == "header") {
          optionsRender.push(
            <MenuItem key={key} disabled={true} className="acmSelectHeader">
              {value.label} {sAction.getStorage("debug") && " ["+value.value+"]"}
            </MenuItem>
          );
        } else {
          optionsRender.push(
            <MenuItem key={key} value={value.value} className={value.type == "button" ? "selectButton" : ""}>
              {value.label}{sAction.getStorage("debug") && " ["+value.value+"]"}
            </MenuItem>
          );
        }
      }
    });
    var className = "acmSelectContainer";
    if (this.state.focus == true) {
      className = "acmSelectContainer focus";
    }
    if (this.props.error == true) {
      className += " error";
    }
    // const attr = this.createAttr("acmSelect");

    var value = this.state.value;
    if (this.props.value != undefined) {
      value = this.props.value;
    }
    console.log("Ted");
    return (
      <FormControl
        className={
          this.props.containerClassName != undefined
            ? className + " " + this.props.containerClassName
            : className
        }
        disabled={this.props.disabled}
        onKeyDown={e => this.onKeyDown(e)}
        autoFocus={this.props.autoFocus}
        style={this.props.containerStyle ? this.props.containerStyle : null}
      >
        {labelRender}
        <MaterialSelect
          native={optionsRender.length > 200 ? true : false}
          className="acmSelect"
          value={value}
          id={this.props.id}
          inputProps={{ id: this.props.id }}
          onChange={e => this.onChange(e)}
          onOpen={e => this.onOpen(e)}
          onClose={e => this.onClose(e)}
          onBlur={e => this.onBlur(e)}
          onKeyDown={e => this.onKeyDown(e)}
          onFocus={e => this.onFocus(e)}
          inputRef={this.props.myRef}
          open={this.state.open}
          autoFocus={this.props.autoFocus}
          name={this.props.name}
          style={this.props.style ? this.props.style : null}
          autoWidth={this.props.autoWidth}
          MenuProps={this.props.menuProps}
        >
          {optionsRender}
        </MaterialSelect>
      </FormControl>
    );
  }
}
