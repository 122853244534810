import { ListItemSecondaryAction } from "@material-ui/core";
import { roundToNearestMinutesWithOptions } from "date-fns/fp";

export default function acmSchvalovaniChangeState(data){
    //console.log("acmSchvalovaniChangeState", data);

    
    const params = {
        action: "acm_log_schvalovani_change_state",
        record: data.id,
        newState: data.newState
    };

    if(data.newState == "approved"){
        this.load();
        this.rest.post("customAction", params, (resp) => {
            this.unLoad();
            if (resp.errCode == "OK") {
                location.reload();
            }
            else{
                this.error(resp.errMsg);
            }
        });
    }
    else if(data.newState == "unapproved"){
        this.popup("logSchvalovaniReject", {header: this.translate("LBL_REJECT_REASON", "acm_log_schvalovani"), id : data.id});
    }
    /*
    this.load();
    this.rest.post("customAction", params, (resp) => {
        this.unLoad();
        if (resp.errCode == "OK") {
            location.reload();
        }
        else{
            this.error(resp.errMsg);
        }
    });
    */

}