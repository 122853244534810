export default function uploadFiles(files, way) {
  var formData = {
    files: files
  };
  const list = this.dataGet(way);
  if (list.listViewTree !== undefined) {
    if (list.listViewTree !== null) {
      const activeCategory = list.listViewTree.get("activeCategory");
      const fieldName = list.listViewTree.get("field");
      formData["fieldData"] = {};
      formData["fieldData"][fieldName] = activeCategory;
    }
    // ______________________________________ if is subpanel
    if (list.type == "subpanel") {
      var relData = this.dataGet(way + "/../def/rel");
      var parentId = this.dataGet(way + "/../../../id");

      var relData = {
        module: relData.get("module"),
        relname: relData.get("relName"),
        id: parentId
      };
      formData["relData"] = relData
    }

    // ______________________________________ if is subpanel
    this.popup("fileUpload", { percent: 0, loading: true, onClose: () => {
      this.reloadList(0,1,way);
    } });
    const id = this.getPopupId();
    this.filesXhr({
      action: "uploadFile",
      data: formData,
      success: data => {
          if(data.state == true){
            this.dataSet("conf/popup"+id+"/data/loading", false);
          }
          else if(data.message){
            this.dataSet("conf/popup"+id+"/data/loading", false);
            this.error(data.message);
          }
      },
      progress: data => {
        this.dataSet("conf/popup"+id+"/data/percent",data.percent);
      }
    });
  }
}
