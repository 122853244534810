import PropTypes from 'prop-types';
import React, {useCallback, useMemo} from 'react';
import {Virtuoso} from 'react-virtuoso';
import MessageBobule from './MessageBobule';
import sAction from 'sAction';

export default function ChatMessagesList({messages, startReached, virtuoso, user, startIndex, lastSeenMessages}) {
    const firstItemIndex = useMemo(() => {
        return startIndex - messages.length;
    }, [startIndex, messages.length]);

    /**
     * lastMessageIndex - index of last message
     * @return {number}
     */
    const lastMessageIndex = useMemo(() => {
        if (!messages || messages.length === 0) {
            return 1;
        }
        return messages[messages.length - 1]?.index + 1;
    }, [messages, messages?.length]);

    /**
     * internalMessages - messages to render
     * @type {Array}
     * @description keep a copy of messages so I can set the next first item index before new messages set
     */
    const internalMessages = useMemo(() => {
        // NOTE: maybe not needed
        return messages;
    }, [messages, messages?.length, startIndex]);

    /**
     * itemContent - render message
     * @param index
     * @param rowData
     * @returns {JSX.Element}
     */
    const itemContent = useCallback((index, rowData) => {
        let isLastMessageInBlock = true;
        let isLastMessage = false;
        const nextMessageIndex = index - firstItemIndex +1;

        if (nextMessageIndex <= internalMessages.length) {
            const nextMessage = internalMessages[nextMessageIndex];
            if (nextMessage && nextMessage.sendByID === rowData.sendByID) {
                isLastMessageInBlock = false;
            }
        }

        if (nextMessageIndex === internalMessages.length) {
            isLastMessage = true;
        }

        // get array of user ids that last saw this message
        const lastSeenMessageByUsers = [];
        if (lastSeenMessages) {
            const myUserId = sAction.dataGet('conf/user/id');

            Object.keys(lastSeenMessages).forEach((userId) => {
                if (lastSeenMessages[userId] === rowData.messageID && userId !== myUserId && userId !== rowData.sendByID) {
                    lastSeenMessageByUsers.push(userId);
                }
            });
        }

        const previousMessage = index === 0 ? null : internalMessages[nextMessageIndex - 2];

        return (<div>
            <MessageBobule
                message={rowData}
                user={user}
                isLastMessageInBlock={isLastMessageInBlock}
                previousMessage={previousMessage}
                isLastMessage={isLastMessage}
                lastSeenMessageByUsers={lastSeenMessageByUsers}
            />
            {/* NOTE: does not work for now  */}
            {/* {index === lastMessageIndex - 1 ? <div className='chatMessageBobuleSpacer--first' /> : null} */}
        </div>);
    }, [messages, messages.length, lastMessageIndex, internalMessages, firstItemIndex]);

    // setting 'auto' for behavior does help in this sample, but not in my actual code
    const followOutput = useCallback((isAtBottom) => {
        return isAtBottom ? true : false;
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                flexFlow: 'column',
                height: '100%',
                width: '100%',
            }}
        >
            <Virtuoso
                ref={virtuoso}
                initialTopMostItemIndex={internalMessages.length - 1}
                firstItemIndex={Math.max(0, firstItemIndex)}
                itemContent={itemContent}
                data={internalMessages}
                startReached={startReached}
                followOutput={followOutput}
                style={{width: '100%'}}
                className='chatMessagesListVirtuoso'
                // overscan={100}
                atBottomThreshold={200}
                atTopThreshold={200}
            />
        </div>
    );
}

ChatMessagesList.propTypes = {
    chatId: PropTypes.string,
    messages: PropTypes.array,
    startIndex: PropTypes.any,
    startReached: PropTypes.any,
    user: PropTypes.any,
    virtuoso: PropTypes.any,
    lastSeenMessages: PropTypes.object,
};
