import React from 'react';
import sAction from 'sAction';

export default function CsRequestRecord({data, index}) {
    const lineStyle = index % 2 !== 1 ? 'odd' : '';
    const dateCreated = new Date(data.date_entered).toLocaleDateString('en-GB').replaceAll('/', '.');
    const priorityEnum = sAction.getEnumForSelect('customers_requests_priority');
    let priority = '';
    priorityEnum.forEach((row) => {
        if (row.value === data.priority) {
            priority = row.label;
        }
    })

    return (
        <div className={`csRequestRecord csRequestRecord-${lineStyle}`}>
            <a href={`#detail/acm_customers_requests/${data.id}`}>
                {data.name}
            </a>
            <div style={{color: data.priority === 'priority' ? '#E92222' : ''}}>{priority}</div>
            <div>{dateCreated}</div>
        </div>
    )
}