import Loader from "../../loader";
import React, {useEffect, useState} from "react";
import sAction from 'sAction';
import TabContainer from "../../formElements/TabContainer";

export default function CustomerRequestReplyCommunicationHistory(props){
    const [communication, setCommunication] = useState({
        loaded: false,
        internal: [],
        official: [],
    });

    const [activeTab, setActiveTab] = useState(0);

    // get communication data
    useEffect(() => {
        sAction.rest.get(`getPreviousCommunication?requestID=${props.acm_customers_requestsID}`, (responseData) => {
            if(!responseData?.status){
                sAction.error('Error - no data')
                return;
            }
            let officialCommunication = responseData?.data?.communicationData?.official ?? [];
            officialCommunication = officialCommunication.sort((a, b) => {
                const aDate = new Date(a.date_entered)
                const bDate = new Date(b.date_entered);
                if(aDate > bDate){
                    return -1;
                }
                return 1;
            });
            let internalCommunication = responseData?.data?.communicationData?.internal ?? [];
            internalCommunication = internalCommunication.sort((a, b) => {
                const aDate = new Date(a.date_entered)
                const bDate = new Date(b.date_entered);
                if(aDate > bDate){
                    return -1;
                }
                return 1;
            });

            setCommunication({loaded: true, official: officialCommunication, internal: internalCommunication})
        }, false);
    }, []);

    if(communication.loaded === false) {
        return <Loader/>;
    }


    let internalRender = <div className={"hidden"}></div>;
    let officialRender = <div className={"hidden"}></div>;
    if(activeTab === 0){
        officialRender = [];
        communication.official.forEachObject((item, i) => {
            const href =`#detail/acm_official_communication/${item.id}`;
            let label = '';
            let personLink = null;
            if(item.source === 'sps'){
                label = sAction.translate('LBL_ANSWER', 'acm_official_communication');
                personLink = (
                    <a
                        className="person"
                        href={`#detail/Users/${item.person.id}`}
                        target="_blank"
                    >{item.person?.name}</a>
                );
            } else {
                label = sAction.translate('LBL_QUESTION', 'acm_official_communication')
            }

            officialRender.push(
                <div key={i} className={'communicationHistoryItem'}>
                    {item.message ? (
                        <div className={`communicationPart ${item.source !== 'sps' ? 'communicationPartAnswer' : ''}`}>
                            <a
                                className="header"
                                href={href}
                                target="_blank"
                            >
                                {label}
                            </a>
                            <span className={'date'}>{sAction.dateFormat(item.date_entered)}</span>
                            {personLink}
                            <div className={"body"}>
                                {item.message}
                            </div>
                        </div>
                    ) : null}
                </div>
            );
        });
        if(officialRender.length === 0){
            officialRender = sAction.translate('LBL_NO_COMMUNICATION_DATA', 'acm_customers_requests');
        }
        officialRender = (<div  className={"communicationHistoryWrapper"}>{officialRender}</div>)
    }
    if(activeTab === 1){
        internalRender = [];
        communication.internal.forEachObject((item, i) => {
            const href =`#detail/acm_internal_communication/${item.id}`;
            const userQuestionHref =`#detail/Users/${item.questioner.id}`;
            const userAnswererHref =`#detail/Users/${item.answerer.id}`;
            internalRender.push(
                <div key={i} className={'communicationHistoryItem'}>
                    {item.question ? (
                        <div className={'communicationPart'}>
                            <a
                                className="header"
                                href={href}
                                target="_blank"
                            >{sAction.translate('LBL_QUESTION_TO', 'acm_internal_communication')} </a>
                            <a
                                target="_blank"
                                href={userAnswererHref}
                            >
                                    {item.answerer?.name}
                            </a>
                            <span className={'date'}>{sAction.dateFormat(item.date_entered)}</span>
                            <a
                                className="link person"
                                href={userAnswererHref}
                                target="_blank"
                            >{item.questioner?.name}</a>
                            <div className={"body"}>
                                {item.question}
                            </div>
                        </div>
                    ) : null}
                    {item.answer ? (
                        <div className={'communicationPart communicationPartAnswer'}>
                            <a
                                className="header"
                                href={href}
                                target="_blank"
                            >{sAction.translate('LBL_ANSWER_TO', 'acm_internal_communication')} </a>
                            <a
                                className="link"
                                target="_blank"
                                href={userQuestionHref}
                            >
                                    {item.questioner?.name}
                            </a>
                            <span className={'date'}>{sAction.dateFormat(item.date_sent)}</span>
                            <a
                                className="link person"
                                href={userQuestionHref}
                                target="_blank"
                            >{item.answerer?.name}</a>
                            <div className={"body"}>
                                {item.answer}
                            </div>
                        </div>
                    ) : null}
                </div>
            );
        });
        if(internalRender.length === 0){
            internalRender = sAction.translate('LBL_NO_COMMUNICATION_DATA', 'acm_customers_requests');
        }
        internalRender = (<div  className={"communicationHistoryWrapper"}>{internalRender}</div>)
    }

    return (
        <div className={"acmTabContainer"} style={{height: "100%", width: "100%"}}>
            <div className="viewActionPanelButtons">
                <TabContainer
                    onChange={(e, value) => setActiveTab(value)}
                    value={activeTab}
                    tabs={[sAction.translate('LBL_MODULE_NAME','acm_official_communication'), sAction.translate('LBL_MODULE_NAME','acm_internal_communication')]}
                />

            </div>
            {internalRender}
            {officialRender}
        </div>
    );
}