import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import Badge from '@mui/material/Badge';
import {styled} from '@mui/material/styles';
import sAction from 'sAction';

const StyledBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
        right: 8,
        top: 0,
        padding: '0',
        minWidth: '15px',
        height: '15px',
        backgroundColor: '#F44336',
    },
}));

export default function ChatIcon({className, chatConf}) {
    const newMessages = useMemo(() => {
        if (!chatConf?.newMessages) {
            return null;
        }
        let count = 0;
        chatConf?.newMessages.forEach((chat) => {
            count += chat;
        });

        return count;
    }, [chatConf]);

    return (
        <div className='headerChatIconWrapper' onClick={() => sAction.openChat()} id='chatIcon'>
            <StyledBadge badgeContent={newMessages} color="primary" className='headerChatIcon' id='chatIcon'>
                <div
                    className={`iconfas-chat ${className} ${chatConf?.open ? 'active' : ''}`}
                    id='chatIcon'
                />
            </StyledBadge>
        </div>
    );
}

ChatIcon.propTypes = { 
    className: PropTypes.string,
    chatConf: PropTypes.shape({
        newMessages: PropTypes.object,
        open: PropTypes.bool,
    }),
};
