import React, {useState} from 'react';
import  sAction  from 'sAction';

import InputText from '../formElements/InputText';

export default function CargoNetSearch() {
    const [requestNumber, setRequestNumber] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        sAction.load()
        sAction.rest.post('getCSRequestByName', {requestNumber}, (res) => {
            sAction.unLoad();
            if (!res.status) {
                return sAction.error(sAction.translate('LBL_REQUEST_NOT_FOUND', 'acm_customers_requests'));
            }
            if (res.data.isInDepartment) {
                window.location.href = `#detail/acm_customers_requests/${res.data.requestId}`;
            } else {
                sAction.popupDetail({
                    module: 'acm_customers_requests',
                    record: res.data.requestId,
                });
            }
        })
    };

    return (
        <form className='cargonetSearch' onSubmit={handleSubmit}>
            <span className='cargonetSearch-label'>{sAction.translate('LBL_MODULE_NAME', 'acm_customers_requests')}:</span>
            <InputText
                containerClassName='cargonetSearch-input'
                className="withBorder"
                value={requestNumber}
                onChange={(e) => setRequestNumber(e.target.value)}
                placeholder={sAction.translate('LBL_SEARCH_REQUEST', 'acm_customers_requests')}
            />
            <button type='submit' className='cargonetSearch-icon icon-search'></button>
        </form>
    )
}
