import PropTypes from 'prop-types';
import React, {memo, useMemo, useContext} from 'react';
import {ChatContext} from 'ROOT/src/contexts/ChatContext';
import ChatAvatar from './ChatAvatar';
// import LinkPreview from './LinkPreview';
import sAction from 'sAction';

const MessageBobule = memo(function MessageBobule({message, user, className, isLastMessageInBlock, isLastMessage, lastSeenMessageByUsers, previousMessage}) {
    const {users, previewImage} = useContext(ChatContext);
    const messageClass = useMemo(() => {
        if (user.id === message.sendByID) {
            return 'ownMessage';
        }
        return '';
    }, [message, user]);

    const senderUser = useMemo(() => {
        return users[message.sendByID];
    }, [message, users]);

    /**
     * usersThatSawMessage - get users that saw this message
     * @return {array}
     */
    const usersThatSawMessage = useMemo(() => {
        if (!lastSeenMessageByUsers || lastSeenMessageByUsers.length <= 0) {
            return [];
        }

        const usersThatSawMessage = [];
        lastSeenMessageByUsers.forEach((userId) => {
            const user = users[userId];
            if (user) {
                usersThatSawMessage.push(user);
            }
        });
        return usersThatSawMessage;
    }, [lastSeenMessageByUsers, message, users]);

    // text message - detect links and convert them to clickable
    const messageContent = useMemo(() => {
        if (!message.content || typeof message.content !== 'string' || message.content.length <= 0) {
            return '';
        }
        const URL_REGEX = /(https?:\/\/[^\s]+)/g;

        // URL_REGEX is a matching regular expression
        const parts = message.content.split(URL_REGEX);
        for (let i = 1; i < parts.length; i += 2) {
            parts[i] = <a key={'link' + i} href={parts[i]} target='_blank' rel="noreferrer">{parts[i]}</a>;
        }

        return <span>{parts}</span>;
    }, [message.content]);

    if (message.messageType === 'system') {
        return (
            <div className={`messageBobuleWrapper systemMessage ${className}`}>
                <div className='messageMessageBobule'>
                    <p className='messageBobuleText'>{sAction.translate(message.content)}</p>
                </div>
            </div>
        );
    }

    if (message.messageType === 'image') {
        return (
            <MessageBobuleWrapper
                isLastMessageInBlock={isLastMessageInBlock}
                usersThatSawMessage={usersThatSawMessage}
                isLastMessage={isLastMessage}
                messageClass={messageClass}
                senderUser={senderUser}
                className={className + ' imageMessage'}
                message={message}
                previousMessage={previousMessage}
            >
                <img
                    src={sAction.getImageUrl(message.content)}
                    alt='image'
                    className='messageBobuleImage'
                    onClick={() => previewImage(message.content)}
                />
            </MessageBobuleWrapper>
        );
    }

    if (message.messageType === 'coripoLink') {

        console.log('message', message);

        return (
            <MessageBobuleWrapper
                isLastMessageInBlock={isLastMessageInBlock}
                usersThatSawMessage={usersThatSawMessage}
                isLastMessage={isLastMessage}
                messageClass={messageClass}
                senderUser={senderUser}
                className={className + ' linkMessage'}
                message={message}
                previousMessage={previousMessage}
            >
                <a href={message.content?.url} >
                    <div className='messageBobuleCoripoLink'>
                        <div className='moduleIcon'>
                            <span className={`icon-${message.content?.module}`} />
                        </div>
                        <div className='messageBobuleTextWrapper'>
                            <p className='messageBobuleText'>{message.content?.title}</p>
                        </div>
                    </div>
                </a>
            </MessageBobuleWrapper>
        );
    }

    // // if message content is only a link render link preview
    // if (message.content.match(/^https?:\/\/[^\s]+$/)) {
    //     return (
    //         <MessageBobuleWrapper
    //             isLastMessageInBlock={isLastMessageInBlock}
    //             usersThatSawMessage={usersThatSawMessage}
    //             isLastMessage={isLastMessage}
    //             messageClass={messageClass}
    //             senderUser={senderUser}
    //             className={className + ' linkMessage'}
    //         >
    //             <LinkPreview url={message.content} />
    //         </MessageBobuleWrapper>
    //     );
    // }

    return (
        <MessageBobuleWrapper
            isLastMessageInBlock={isLastMessageInBlock}
            usersThatSawMessage={usersThatSawMessage}
            isLastMessage={isLastMessage}
            messageClass={messageClass}
            senderUser={senderUser}
            className={className + ' textMessage'}
            message={message}
            previousMessage={previousMessage}
        >
            <p className='messageBobuleText'>{messageContent}</p>
        </MessageBobuleWrapper>
    );
});

/**
 * MessageBobuleWrapper
 * @param {object} props
 * @return {JSX.Element}
 */
const MessageBobuleWrapper = memo(function MessageBobuleWrapper(
    {children, isLastMessageInBlock, usersThatSawMessage, isLastMessage, messageClass, senderUser, className, message, previousMessage},
) {
    const {currentUser} = useContext(ChatContext);
    const sentFailed = currentUser.id === senderUser.id && message.sent === false ? 'sentFailed' : '';
    let nextMessageBlock = false;

    if (previousMessage?.sendByID && message.sendByID !== previousMessage?.sendByID) {
        nextMessageBlock = true;
    }

    return (<>
        {nextMessageBlock && (
            <div className='messageBobuleSpacerPlaceholder'></div>
        )}
        <div className={`messageBobuleWrapper ${messageClass} ${className} ${isLastMessage ? 'lastMessage' : ''} ${sentFailed}`}>

            {isLastMessageInBlock ? (
                <ChatAvatar
                    alt={senderUser.name}
                    user={senderUser}
                    className={`messageBobuleAvatar`}
                />
            ) : (
                <div className='messageBobuleAvatarPlaceholder' />
            )}
            <div className='messageBobule'>
                {children}
            </div>

            {currentUser.id === senderUser.id && isLastMessage && message.sent === true && (
                <div className='messageBobuleSentIcon'>
                    <span className='iconfas-check' />
                </div>
            )}

            {usersThatSawMessage && (
                usersThatSawMessage.map((user) => {
                    return (
                        <ChatAvatar
                            key={user.id}
                            alt={user.name}
                            user={user}
                            className={`messageBobuleAvatarSaw`}
                        />
                    );
                })
            )}

            <p className='messageBobuleTime'>{sAction.dateFormat(message.timestamp)}</p>
        </div>
    </>);
});

MessageBobuleWrapper.propTypes = {
    children: PropTypes.any,
    className: PropTypes.any,
    isLastMessage: PropTypes.any,
    isLastMessageInBlock: PropTypes.any,
    messageClass: PropTypes.any,
    senderUser: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profile_photo: PropTypes.string,
        online: PropTypes.any,
    }),
    usersThatSawMessage: PropTypes.array,
    message: PropTypes.shape({
        chatID: PropTypes.string,
        content: PropTypes.any,
        messageID: PropTypes.string,
        messageType: PropTypes.string,
        sendByID: PropTypes.string,
        timestamp: PropTypes.string,
        sent: PropTypes.bool,
    }),
    previousMessage: PropTypes.shape({
        chatID: PropTypes.string,
        content: PropTypes.any,
        messageID: PropTypes.string,
        messageType: PropTypes.string,
        sendByID: PropTypes.string,
        timestamp: PropTypes.string,
        sent: PropTypes.bool,
    }) || null,
};

MessageBobule.propTypes = {
    className: PropTypes.any,
    chatId: PropTypes.string,
    message: PropTypes.shape({
        chatID: PropTypes.string,
        content: PropTypes.any,
        messageID: PropTypes.string,
        messageType: PropTypes.string,
        sendByID: PropTypes.string,
        timestamp: PropTypes.string,
        sent: PropTypes.bool,
    }),
    user: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profile_photo: PropTypes.string,
        online: PropTypes.any,
    }),
    isLastMessageInBlock: PropTypes.bool,
    isLastMessage: PropTypes.bool,
    lastSeenMessageByUsers: PropTypes.array,
    previousMessage: PropTypes.shape({
        chatID: PropTypes.string,
        content: PropTypes.any,
        messageID: PropTypes.string,
        messageType: PropTypes.string,
        sendByID: PropTypes.string,
        timestamp: PropTypes.string,
        sent: PropTypes.bool,
    }) || null,
};

export default MessageBobule;
