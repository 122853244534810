import PropTypes from 'prop-types';
import React from 'react';
import ChatAvatar from './ChatAvatar';

export default function UserListItem({user, className, onClick, customIcon}) {
    return (
        <div className={`usersListItem ${className}`} onClick={onClick}>
            <div className='usersListItemAvatarWrapper'>
                <ChatAvatar user={user} className='usersListItemAvatar' />
                <div className='usersListItemInfo'>
                    <p className='usersListItemName'>{user.name}</p>
                </div>
            </div>
            {customIcon}
        </div>
    );
}

UserListItem.propTypes = {
    className: PropTypes.string,
    user: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profile_photo: PropTypes.string,
        online: PropTypes.any,
    }),
    onClick: PropTypes.func,
    customIcon: PropTypes.element,
};
