export default function acmCompliantEmail(params){
    const emails = [];
    const comp_pers_email = this.dataGet(params.prefix + "/fields/comp_pers_email/value")
    
    comp_pers_email && emails.push(comp_pers_email);
    params.email && emails.push(params.email);
    
    const email = emails.join(", ");

    const balik_cislo = this.dataGet(params.prefix+"/fields/balik_cislo/value");
    const ref2 = this.dataGet(params.prefix+"/fields/ref2/value");

    const data = {
        parent_type: params.module,
        parent_id: params.id,
        parent_name: params.name,
        name: params.name+" /  TRK "+balik_cislo+" / REF "+ref2,
        to_addrs: email,
        emailTemplate : "",
        callback: "acmCompliantEmailCallback",
        keepSelf: true,
    };
    
    this.openEmailPopup(data);
}