export default function acmCompliantApprove(params){
    const email = this.dataGet(params.prefix+"/fields/comp_pers_email/value");

    const comp_est_damage = this.dataGet(params.prefix+"/fields/comp_est_damage/value");
    const credit_transit = this.dataGet(params.prefix+"/fields/credit_transit/value");

    if(comp_est_damage <= 0 || !credit_transit){

        if(comp_est_damage <= 0){
            this.dataSet(params.prefix+"/fields/comp_est_damage/customClass", "error");
        }
        if(!credit_transit){
            this.dataSet(params.prefix+"/fields/credit_transit/customClass", "error");
        }

        this.error(this.translate("LBL_ERR_INVALID_APPROVE_REQUEST"));
        return;
    }

    const data = {
        parent_type: params.module,
        parent_id: params.id,
        parent_name: params.name,

        to_addrs: email,
        emailTemplate : "",
        callback: "acmCompliantApproveCallback",
    };
        
    this.openEmailPopup(data);
}