export default function cisloBalikuChanged(prefix, val){
    if(!val){
        return;
    }

    this.load();
        const params = {
            action: "compliant_cargonet_info",
            type: "crmLoadBalik",
            balik: val,
            checkBalik: true,
            acm_compliant: this.dataGet(prefix+"/id"),
        };

        this.rest.post("customAction", params, (resp) => {
            const ignoreFields = ["history", "shp_id", "pkg_id", "zakaznik", "hmotnost", "datum", "account", "zasilka", "comp_pers_email"];
            this.unLoad();
            if (resp.errCode == "OK") {

                let curEmail =  this.dataGet(prefix+"/fields/comp_pers_email/value");
                this.dsClear();
                resp.result.forEachObject((value, prop) => {
                    if(ignoreFields.includes(prop)){
                        return;
                    }

                    this.dsAdd("set", prefix+"/fields/"+prop+"/value", value);
                    this.dsAdd("set", prefix+"/changes/fields/"+prop, value);
                });

                if(resp.result.comp_pers_email){
                    if(!curEmail){
                        this.dsAdd("set", prefix+"/fields/comp_pers_email/value", resp.result.comp_pers_email);
                        this.dsAdd("set", prefix+"/changes/fields/comp_pers_email", resp.result.comp_pers_email);
                    }
                }
                
                this.dsAdd("set", prefix+"/fields/zasilka_cislo/value", resp.result.shp_id);
                this.dsAdd("set", prefix+"/changes/fields/zasilka_cislo", resp.result.shp_id);

                if(resp.account){
                    this.dsAdd("set", prefix+"/fields/acm_compliant_accounts_name/value", resp.account.name);
                    this.dsAdd("set", prefix+"/fields/acm_compliant_accounts_name/def/id_value", resp.account.id);
                    this.dsAdd("set", prefix+"/changes/fields/acm_compliant_accountsaccounts_ida", resp.account.id);
                }

                if(resp.zasilka){
                    this.dsAdd("set", prefix+"/changes/fields/acm_compliant_sl_zasilkasl_zasilka_ida", resp.zasilka.id);
                }

                if(resp.balik){
                    this.dsAdd("set", prefix+"/fields/acm_zasilka_baliky_acm_compliant_name/value", resp.balik.name);
                    this.dsAdd("set", prefix+"/fields/acm_zasilka_baliky_acm_compliant_name/def/id_value", resp.balik.id);
                    this.dsAdd("set", prefix+"/changes/fields/acm_zasilka_baliky_acm_compliantacm_zasilka_baliky_ida", resp.balik.id);
                }


                this.dsProcess();
            }
            else{
                this.error(resp.errMsg);
                this.dataSet(prefix+"/fields/balik_cislo/value", "");
                this.dataSet(prefix+"/changes/fields/balik_cislo", "");
            }
        });
}