import sAction from "sAction";

export default function customerRequestReply({id, module, prefix, newState, name}) {
    this.rest.post("email/customerRequestsTemplates", {requestID: id}, data => {
        if(data?.status === false){
            this.error(data?.message);
            return;
        }

        const signatures = [];
        data.signatures.forEachObject((label, value) => {
            signatures.push({ label, value });
        });
        const emailTemplates = [];
        data.emailTemplates.forEachObject((label, value) => {
            emailTemplates.push({ label, value });
        });
        const fromAccounts = [];
        data.fromAccounts.forEach(a => {
            fromAccounts.push({ label: a.text, value: a.value });
        });

        const customerFirstName = sAction.dataGet(`${prefix}/fields/customer_first_name/value`);
        const customerLastName = sAction.dataGet(`${prefix}/fields/customer_last_name/value`);
        
        this.popup("customerRequestReply", {
            to_addrs: this.dataGet(`${prefix}/fields/email/value`) ?? '',
            name: name,
            parent_type: module,
            parent_name: name,
            parent_id: id,
            description_html: "",
            fromAccounts: fromAccounts,
            uid: "",
            loaded: true,
            emailTemplate: "",
            emailTemplates: emailTemplates,
            signatures: signatures,
            newState: newState,
            attachments: {},
            customerFirstName: customerFirstName,
            customerLastName: customerLastName,
        });
    });
}
