export default function acmCompliantDobropis(params){
    const email = "fakturacia@sps-sro.sk";
    const data = {
        parent_type: params.module,
        parent_id: params.id,
        parent_name: params.name,

        to_addrs: email,
        emailTemplate : "4c396916-518a-d113-30ad-5ece1c79d80f",
    };
        
    this.openEmailPopup(data);
}