import React, {useState, useCallback, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import sAction from 'sAction';
import {ChatContext} from 'ROOT/src/contexts/ChatContext';
import UsersList from './UsersList';
import UserListItem from './UserListItem';
import Loader from '../loader';

export default function CreateEditGroupChat({user, edit = false, chatId = null, data = null}) {
    const {setActiveScreen, getUserChats, chats, users} = useContext(ChatContext);
    const [name, setName] = useState('');
    const [search, setSearch] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [nameError, setNameError] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (nameError) {
            setNameError(false);
        }
    }, [name]);

    useEffect(() => {
        let editChatId = chatId;
        if (data?.chatId) {
            editChatId = data.chatId;
        }

        if (edit && editChatId) {
            let chat = chats.find((chat) => chat.id === editChatId);
            if (!chat && data?.chat) {
                chat = data.chat;
            }
            if (!chat) {
                setActiveScreen(null);
                sAction.toast({
                    type: 'error',
                    name: sAction.translate('LBL_CHAT_ERROR'),
                    description: sAction.translate('LBL_CHAT_GROUP_NOT_FOUND'),
                });
                return;
            }

            setName(chat.name);
            const participants = chat.participants.map((participant) => {
                return users[participant];
            });

            setSelectedUsers(participants);
        }
    }, [edit, chatId, data?.chatId]);

    /**
     * handleSelectUserCallback
     * @param {object} user
     */
    const handleSelectUserCallback = (user) => {
        setSelectedUsers([...selectedUsers, user]);
    };

    /**
     * handleUnselectUser
     * @param {string} userId
     */
    const handleUnselectUser = (userId) => {
        setSelectedUsers(selectedUsers.filter((user) => user.id !== userId));
    };

    /**
     * handleCreateGroupChat
     */
    const handleCreateGroupChat = useCallback(() => {
        if (name.length <= 0) {
            setNameError(true);
            sAction.toast({
                type: 'error',
                name: sAction.translate('LBL_CHAT_ERROR'),
                description: sAction.translate('LBL_CHAT_GROUP_NAME_REQUIRED'),
            });
            return;
        }

        if (selectedUsers.length <= 0 ) {
            sAction.toast({
                type: 'error',
                name: sAction.translate('LBL_CHAT_ERROR'),
                description: sAction.translate('LBL_CHAT_GROUP_USERS_REQUIRED'),
            });
            return;
        }

        const participants = selectedUsers.map((user) => user.id);
        const propsData = data;
        sAction.chatCore.createNewGroupChat(participants, name, data?.admin === true, (data) => {
            if (data?.data?.id) {
                setLoading(true);
                setTimeout(() => {
                    getUserChats();
                    setLoading(false);
                    if (propsData?.admin === true) {
                        setActiveScreen({view: 'adminGroupChatList'});
                    } else {
                        setActiveScreen(null);
                    }
                }, 1500);
            } else {
                console.warn('[Error] creating new chat: ', data);
                sAction.toast({
                    type: 'error',
                    name: sAction.translate('LBL_CHAT_ERROR'),
                    description: sAction.translate('LBL_CHAT_GROUP_ERROR'),
                });
            }
        });
    }, [name, selectedUsers]);

    /**
     * handleEditGroupChat
     */
    const handleEditGroupChat = useCallback(() => {
        let editChatId = chatId;
        if (data?.chatId) {
            editChatId = data.chatId;
        }

        if (name.length <= 0) {
            setNameError(true);
            sAction.toast({
                type: 'error',
                name: sAction.translate('LBL_CHAT_ERROR'),
                description: sAction.translate('LBL_CHAT_GROUP_NAME_REQUIRED'),
            });
            return;
        }

        if (selectedUsers.length <= 0 ) {
            sAction.toast({
                type: 'error',
                name: sAction.translate('LBL_CHAT_ERROR'),
                description: sAction.translate('LBL_CHAT_GROUP_USERS_REQUIRED'),
            });
            return;
        }

        const participants = selectedUsers.map((user) => user.id);
        const propsData = data;
        sAction.chatCore.editGroupChat(editChatId, name, participants, (data) => {
            if (data?.data?.id) {
                setLoading(true);
                setTimeout(() => {
                    getUserChats();
                    setLoading(false);
                    if (propsData?.admin === true) {
                        setActiveScreen({view: 'adminGroupChatList'});
                    } else {
                        setActiveScreen(null);
                    }
                }, 1500);
            } else {
                console.warn('[Error] creating new chat: ', data);
                sAction.toast({
                    type: 'error',
                    name: sAction.translate('LBL_CHAT_ERROR'),
                    description: sAction.translate('LBL_CHAT_GROUP_ERROR'),
                });
            }
        });
    }, [name, selectedUsers]);

    /**
     * handleRemoveGroupChat
     */
    const handleRemoveGroupChat = useCallback(() => {
        let editChatId = chatId;
        if (data?.chatId) {
            editChatId = data.chatId;
        }

        sAction.confirm(sAction.translate('LBL_CHAT_REMOVE_CHAT_CONFIRM'), () => {
            sAction.chatCore.removeChat(editChatId, () => {
                setLoading(true);
                setTimeout(() => {
                    getUserChats();
                    setLoading(false);
                    setActiveScreen({view: 'adminGroupChatList'});
                    sAction.toast({
                        type: 'success',
                        name: sAction.translate('LBL_CHAT_REMOVED'),
                        description: ' ',
                    });
                    sAction.popupHide();
                }, 500);
            });
        }, () => sAction.popupHide(), 'high');
    }, [chatId]);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className='createGroupChat'>
            <h3 className={'createGroupChatHeadline' +
                (data?.chat?.protected === true || (data?.admin === true && edit === false) ? ' admin' : '')}
            >
                {edit ? sAction.translate('LBL_CHAT_EDIT_GROUP_CHAT') : sAction.translate('LBL_CHAT_NEW_GROUP_CHAT')}
            </h3>

            <div className='createGroupChatForm'>
                <div className='createGroupInputs'>
                    <Input
                        placeholder={sAction.translate('LBL_CHAT_GROUP_NAME')}
                        variant="soft"
                        className='chatGroupNameFiled'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={nameError}
                    />
                    <div className='selectedUsersList usersList'>
                        <p className='usersListLabel'>{sAction.translate('LBL_CHAT_GROUP_SELECTED_USERS')}: </p>
                        <div className='selectedUsersListStack usersListStack'>
                            {selectedUsers.map((user) => {
                                return (
                                    <UserListItem
                                        user={user}
                                        key={user.id}
                                        customIcon={<span className='iconfa-close' onClick={() => handleUnselectUser(user.id)} />}
                                    />
                                );
                            })}
                            {selectedUsers.length <= 0 &&
                                <p className='secLabel'>{sAction.translate('LBL_CHAT_GROUP_NO_USER_SELECTED')}</p>
                            }
                        </div>
                    </div>

                    <div className='createGroupChatUsersListWrapper'>
                        <Input
                            placeholder={sAction.translate('LBL_CHAT_SEARCH_USERS')}
                            variant="soft"
                            className='chatSearchFiled'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <UsersList
                            user={user}
                            search={search}
                            selectCallback={(user) => handleSelectUserCallback(user) }
                            showAllUsers={true}
                            filter={selectedUsers.map((user) => user.id)}
                            className='createGroupChatUsersList'
                            includeCurrentUser={data?.admin === true}
                        />
                    </div>

                    <div className='createGroupChatDivider' />
                </div>

                <div className='createGroupChatControls'>
                    <div className='createGroupChatControlsButtons'>
                        <Button
                            variant="soft"
                            onClick={() => {
                                data?.admin ? setActiveScreen({view: 'adminGroupChatList'}) : setActiveScreen(null);
                            }}
                            sx={[{
                                backgroundColor: '#c7e8eb',
                                color: '#047780',
                                transition: 'background-color 0.3s',
                            }, {'&:hover': {
                                backgroundColor: '#3dcad4',
                            }}]}
                        >
                            {sAction.translate('LBL_CHAT_GROUP_CANCEL_BTN')}
                        </Button>
                        {edit && data?.admin && (
                            <Button
                                variant="solid"
                                onClick={handleRemoveGroupChat}
                                sx={[{
                                    backgroundColor: '#F56565',
                                    transition: 'background-color 0.3s',
                                }, {'&:hover': {
                                    backgroundColor: '#bd3a3a',
                                }}]}
                            >
                                {sAction.translate('LBL_CHAT_REMOVE_CHAT')}
                            </Button>
                        )}
                        {edit ? (
                            <Button
                                variant="solid"
                                onClick={handleEditGroupChat}
                                sx={[{
                                    backgroundColor: '#09b5c1',
                                    transition: 'background-color 0.3s',
                                }, {'&:hover': {
                                    backgroundColor: '#047780',
                                }}]}
                            >
                                {sAction.translate('LBL_CHAT_GROUP_EDIT_BTN')}
                            </Button>
                        ) : (
                            <Button
                                variant="solid"
                                onClick={handleCreateGroupChat}
                                sx={[{
                                    backgroundColor: '#09b5c1',
                                    transition: 'background-color 0.3s',
                                }, {'&:hover': {
                                    backgroundColor: '#047780',
                                }}]}
                            >
                                {sAction.translate('LBL_CHAT_GROUP_CREATE_BTN')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

CreateEditGroupChat.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profile_photo: PropTypes.string,
        online: PropTypes.any,
    }),
    edit: PropTypes.bool,
    chatId: PropTypes.string,
    data: PropTypes.object,
};
