import detailDefault from "../../detailDefault";

export default class detailacm_customers_requests extends detailDefault {
    load(sAction, data) {
        sAction.dsClear();
        const fromPopup = sAction.dataGet(`${data.prefix}/fields/from_popup/value`);
        // From popup means from cargoNet popup, should be not assigned user just when it is created from cargoNet popup
        // Změna v issue coripo/sps#188
        if (fromPopup === '1' && data.prefix.includes('popup')) {
        //     sAction.dsAdd("set", data.prefix + "/fields/assigned_user_name/value", null);
        //     sAction.dsAdd("set", data.prefix + "/fields/assigned_user_name/def/id_value", null);
        //     sAction.dsAdd("set", data.prefix + "/changes/fields/assigned_user_id", null);
            sAction.dsAdd("set", data.prefix + "/fields/status/def/options", 'customers_requests_status_new_record');
        }

        const recordId = sAction.dataGet(`${data.prefix}/id`);
        if (!recordId) {
            sAction.dsAdd("set", data.prefix + "/fields/request_type/def/options", 'customers_requests_request_type_new_record');
        }

        sAction.dsAdd('set', `${data.prefix}/fields/from_popup/def/visible`, false);
        sAction.dsProcess();
        this.canChangeDepartment(sAction, data);
    }

    update(sAction,data){
        switch(data.field){
            case "status":
                this.canChangeDepartment(sAction, data);
                break;
        }
    }

    canChangeDepartment(sAction, data) {
        const status = sAction.dataGet(`${data.prefix}/fields/status/value`);
        if (status === 'in-progress') {
            sAction.dataSet(`${data.prefix}/fields/acm_department_acm_customers_requests_1_name/def/readonly`, false);
        } else {
            sAction.dataSet(`${data.prefix}/fields/acm_department_acm_customers_requests_1_name/def/readonly`, true);
        }
    }
}
