import React from "react";
import PureComponent from "../pure";

import DetailViewTabContentRow from "./DetailViewTabContentRow";

import  sAction  from "sAction";
class DetailTabContent extends PureComponent {
  render() {
    const data = this.props.data;
    const module = this.props.module;
    const rowCount = this.props.rowCount;
    const id = this.props.id;
    const way = this.props.way;
    const fields = this.props.fields;
    const prefix = this.props.prefix;
    const index = this.props.index;
    const readonly = this.props.readonly;

    var rows = [];
    data.rows.forEach((row, key) => {
      if (row.get("type") == "headerPanel") {
        const headerName = row.get("name");
        const label = sAction.translate(headerName.toUpperCase(), module);
        rows.push(
          <div className={"detailViewPanelHeader "+headerName} key={headerName}>
            <div className="detailViewPanelText" colSpan={2}>{label}</div>
          </div>
        );
      } else {
        rows.push(
          <DetailViewTabContentRow
            prefix={prefix}
            module={module}
            way={way + "/rows/" + key}
            key={key}
            index={key}
            data={row}
            rowCount={100 / rowCount}
            id={id}
            fields={fields}
            readonly={readonly}
            acl={this.props.acl}
          />
        );
      }
    });

    var blok = "";
    if (data.active == true) {
      blok = (
        <div className="detailViewTabContentBlok select" data-index={index}>
          {rows}
        </div>
      );
    } else {
      blok = (
        <div className="detailViewTabContentBlok" data-index={index}>
          {rows}
        </div>
      );
    }

    return blok;
  }
}

export default DetailTabContent;
