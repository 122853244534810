import React from "react";
import PureComponent from "../pure"

import sAction from 'sAction'

export default class ToastItem extends PureComponent {
  constructor(props) {
    super(props);
    this.el = React.createRef();
    this.autoClose = null;
  }
  componentDidMount(props) {
    this.autoClose = setTimeout(() => {
      this.close();
    }, 7000)
  }
  componentWillUnmount(){
    clearTimeout(this.autoClose);
  }
  onClick(e) {
    const data = this.props.data
    const target = e.target
    if (target.classList.contains("icon-closeIconLight")) {
      this.close()
    } else {
      const action = data.get("sAction");
      if (sAction[action] != undefined) {
        console.log("ted");
        sAction[action]();
        this.close();
      } else {
        const redirectUrl = data?.get('redirect');
        if (redirectUrl) {
          sAction.redirect({url: redirectUrl});
        }
      }
    }
  }
  close() {
    const target = this.el.current;
    const width = target.offsetWidth + 24;
    const height = target.offsetHeight;
    target.style.height = height
    target.style.left = "-" + width + "px";
    setTimeout(() => {
      target.style.height = 0;
      target.style.padding = "0px 16px";
      target.style.margin = "0px";
      setTimeout(() => {
        sAction.clearToast();
      }, 200)
    }, 200)
  }

  render() {
    const data = this.props.data;

    return <div className={`toastItem ${data?.get('type')} toastItem-${data?.get('type')}`} onClick={(e) => this.onClick(e)} ref={this.el}>
      <div className="toastIconContainer">
        <div className="toastIcon icon-info"></div>
      </div>
      <div className="toastContentContainer">
        <div className="header">
          {data.get("name")}
        </div>
        <div className="content">
          {data.get("description")}
        </div>
      </div>
      <div className="toastButtonContainer">
        <div className="icon-closeIconLight" />
      </div>
    </div>;
  }
}
