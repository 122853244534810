import sAction from '../..';

export default function cargoNetCreateNote(data) {
    const packageNumber = sAction.dataGet(`${data.prefix}/fields/acm_zasilka_baliky_acm_customers_requests_1_name/value`);
    console.log(data);
    sAction.popup('CargoNetCreateNote', {
        data: data,
        packageNumber: packageNumber,
        ticketName: data.name,
    });
}