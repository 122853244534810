import React from "react";
import InputField from "../inputFieldClass";
import InputText from "../../formElements/InputText";
import sAction from "sAction";
export default class DefaultRelate extends InputField {
  constructor(props) {
    super();
    this.state = {
      state: "fine",
      value: null,
      id: props?.data?.def?.get('id_value'),
      resultData: null,
      resultOpen: false,
      resultArrowIndex: -1,
    };
    this.searchInterval = null;
    this.input = React.createRef();
  }
  keyUp(event) {
    const keyCode = event.keyCode;
    if (keyCode === 13 && event.ctrlKey === false) {
      if (this.state.resultOpen == true) {
        const item = this.state.resultData[this.state.resultArrowIndex];
        if (item != undefined) {
          this.selectItem(item, true, false);
          this.setState({ resultOpen: false });
          this.input.current.value = item.name;
        }
      } else {
        this.save();
      }
    } else if (keyCode === 27) {
      this.cancel();
    } else if (keyCode === 40 || keyCode === 38) {
      this.changeArrowIndex(keyCode);
    } else if (keyCode !== 9 && keyCode !== 37 && keyCode !== 39) {
      this.waitForSearch();
    }
  }
  waitForSearch() {
    if (this.searchInterval != null) {
      clearInterval(this.searchInterval);
    }
    var self = this;
    this.searchInterval = setInterval(() => {
      self.search();
      clearInterval(self.searchInterval);
    }, 300);
  }
  changeArrowIndex(keyCode) {
    var resultArrowIndex = this.state.resultArrowIndex;
    if (keyCode === 40) {
      resultArrowIndex += 1;
    } else if (keyCode === 38) {
      resultArrowIndex -= 1;
    }

    if (resultArrowIndex < -1) {
      resultArrowIndex = -1;
    }
    if (this.state.resultData != null) {
      if (resultArrowIndex >= this.state.resultData.length) {
        resultArrowIndex = this.state.resultData.length;
      }
    }
    this.setState({ resultArrowIndex });
  }
  search() {
    if (!this.input || !this.input.current || !this.input.current.value) {
      return;
    }
    const value = this.input.current.value;
    const module = this.props.data.def.get("module");
    if (value !== "" || value !== null) {
      const searchData = {
        fields: ["name"],
        getFields: ["id", "name"],
        value,
        module,
      };
      const defaultFilter = this.props.data.def.get("defaultFilter");
      if (defaultFilter) {
        searchData.defaultFilter = defaultFilter.toJS();
      }
      var self = this;
      sAction.quickSearch(searchData, (data) => {
        self.setState({
          resultArrowIndex: -1,
          resultData: data,
          resultOpen: true,
        });
      });
    }
  }
  selectItem(item, doUpdate = true, cancelEdit = true) {
    const data = {
      way: this.props.way,
      name: this.props.data.def.get("id_name"),
      fieldName: this.props.data.name,
      type: "relate",
      value: item,
      cancelEdit,
    };
    this.setState({
      id: item.id,
      name: item.name,
    });
    if (this.props.way === 'report' && this.props.onBlur && item.id) {
      this.props.onBlur({id: item.id, name: item.name});

      return;
    }
    this.saveField(data, doUpdate);
  }
  focus() {
    if (this.state.resultData != null) {
      this.setState({
        resultOpen: true,
      });
    }
  }
  openPopupList() {
    var self = this;
    const data = {
      module: this.props.data.def.get("module"),
      selectedActive: false,
    };
    console.log(data);
    const defaultFilter = this.props.data.def.get("defaultFilter");
    if (defaultFilter) {
      data.defaultFilter = defaultFilter.toJS();
    }
    sAction.openRelatePopup(data, (returnData) => {
      console.log(returnData);
      self.selectItem(returnData);
      if (!returnData.popupHidden) {
        sAction.popupHide();
      }
    });
  }
  newRecord() {
    const module = this.props.data.def.get("module");
    console.log(module);
    sAction.popupDetail({
      module,
      record: "",
      saveCallback: ret => {
        const item = {
          id: ret.record,
          name: ret.name,
        };
        const data = {
          way: this.props.way,
          name: this.props.data.def.get("id_name"),
          fieldName: this.props.data.name,
          type: "relate",
          value: item,
          cancelEdit:true,
        };
        this.setState({
          id: item.id,
          name: item.name,
        });
        this.saveField(data, true);
        sAction.unLoad();
      },
      exitCallback: () => {},
    });
  }
  getSearchResult() {
    const module = this.props.data.def.get("module");
    var searchResult = null;
    if (this.state.resultOpen === true) {
      var resultList = [];

      if(sAction.hasAccess(module,"edit") && (this.props.prefix === 'view' || this.props.prefix === 'rightPanel/data/detail')){
        resultList.push(
          <div
            tabIndex={resultList.length}
            onClick={() => this.newRecord()}
            key={resultList.length}
            className={"quickSearchResultLine newRecord"}
          >
            {sAction.translate("LBL_CREATE_NEW_RECORD")}
          </div>
        );
      }
      this.state.resultData.forEach((item, index) => {
        var lineClass = "quickSearchResultLine";
        if (item.id === this.props.data.def.get("id_value")) {
          lineClass += " select";
        }

        if (index === this.state.resultArrowIndex) {
          lineClass += " arrowSelect";
        }

        resultList.push(
          <div
            tabIndex={index+1}
            onClick={() => this.selectItem(item)}
            key={index+1}
            className={lineClass}
          >
            {sAction.decodeHTMLEntities(item.name)}
          </div>
        );
      });
      searchResult = <div className="quickSearchResult">{resultList}</div>;
    }

    return searchResult;
  }
  onBlur(e) {
    const relTarget = e.relatedTarget;
    if (relTarget == null) {
      this.cancel();
    } else {
      var parent = relTarget.closest(
        "div[data-fieldname='" + this.props.data.name + "']"
      );
      if (parent == null) {
        this.cancel();
      }
    }
  }
  deleteValue = () => {
    this.selectItem({ id: "", name: "" });
    if (this.props.way === 'report' && this.props.onBlur ) {
      this.props.onBlur({id: '', name: ''});
    }
  };
  render() {
    const data = this.props.data;
    const newRecord = this.props.newRecord;
    var containerClass = "inputEditContainer";

    var value = data.value;
    if (newRecord && (value === "" || value === undefined || value === null)) {
      value = "";
    }
    else{
      value = sAction.decodeHTMLEntities(value);
    }
    const searchResult = this.getSearchResult();
    const containerClassField = "inputContainer relateField";

    return (
      <div className={containerClass} data-fieldname={data.name}>
        <div className={containerClassField}>
          <InputText
            onKeyDown={(e) => this.onKeyDown(e)}
            autoFocus={true}
            onKeyUp={(event) => this.keyUp(event)}
            onFocus={() => this.focus()}
            myRef={this.input}
            id={data.name}
            defaultValue={value}
            autoComplete="acmAutoFillOff"
          />
          {searchResult}
        </div>
        {!newRecord && (
          <div className="buttonContainer">
            <div
              tabIndex="1"
              onClick={() => this.openPopupList()}
              className="inputEditButton"
            >
              <div className={"icon-detailSearch"} />
            </div>
            <div
              tabIndex="2"
              onClick={() => this.deleteValue()}
              className=" inputEditButton"
            >
              <div className={"icon-detailCancel"} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
