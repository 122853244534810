import detailDefault from "../../detailDefault";

export default class detailUsers extends detailDefault {
    load(sAction, data) {
        const currentUser = sAction.dataGet('conf/user');
        if (currentUser.get("is_admin") !== '1') {
            sAction.dataSet(data.prefix+"/fields/offline_c/def/readonly", true);
        }
    }
    update(sAction, data) { }
}
