import React from "react";
import PureComponent from "../pure";

import DetailViewSpanValue from "./DetailViewSpanValue";

import sAction from "sAction";
import DetailApiButtons from "../detailViewFields/Api/DetailApiButtons"

class DetailViewField extends PureComponent {

  show(e, data) {
    e.stopPropagation();
    const prefix = this.props.prefix;
    const type = sAction.dataGet(prefix + "/type");
    if (type !== "rightPanel") {
      sAction.rightPanelDetail(data.module, data.id);
    }
  }

  showInfo(selector, help, header) {
    sAction.popper({ selector: selector, content: help, header: header,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      }})
  }

  render() {
    const data = this.props.data;
    const module = this.props.module;
    const prefix = this.props.prefix;
    const panelType = sAction.dataGet(prefix + "/type");
    const readonly = this.props.readonly;
    const id = this.props.id;
    var newRecord = false;

    var errorMsg = null;
    if (data.def.get("isInvalid")) {
      errorMsg = <span className="errorMsg">{sAction.translate("LBL_REQUIRED_FIELD_EMPTY", "Calendar")}</span>;
    }

    //nezobrazuje skryte fieldy
    if (data.def.get("visible") === false) return "";

    var value;
    var type = data.def.get("type");
    if (type == undefined) {
      type = "varchar";
    }
    let fieldType = type.charAt(0).toUpperCase() + type.slice(1);

    var showDetailApi = data.def.get("showDetailApi");
    if (showDetailApi == undefined && type == "phone") {
      showDetailApi = "phoneApi"
    }
    var showApiButtons = showDetailApi && showDetailApi.indexOf("Api") !== -1;
    if (this.props.acl && this.props.acl.get("edit") == false) {
      showApiButtons = false;
    }

    if (data.actEdit === false && newRecord == false) {
      value = (
        <DetailViewSpanValue
          prefix={this.props.prefix}
          way={this.props.way}
          rowWay={this.props.rowWay}
          index={this.props.index}
          edit={() => sAction.fieldToEdit({
            way: this.props.way,
            name: this.props.data.name,
            prefix: this.props.prefix,
            type: this.props.data.def.get("type")
          })}
          show={(e, data) => this.show(e, data)}
          data={data}
          fieldType={fieldType}
          newRecord={newRecord}
          boolField={sAction.fields["Bool"]}
          readonly={readonly}
          acl={this.props.acl}
          module={module}
        />
      );
      if (fieldType === "Files" || fieldType === "RichText" || fieldType === "HTMLText") return value;
    } else {
      if (showApiButtons) {
        if (showDetailApi !== "ApiStreet" && showDetailApi !== 'phoneApi') {
          fieldType = showDetailApi;
        }
      }
      var F = sAction.fields[fieldType];
      if (!F) {
        console.log("Field '" + fieldType + "' se nenašel");
        F = sAction.fields["Varchar"];
      }
      value = <F prefix={this.props.prefix} way={this.props.way} data={data} newRecord={newRecord} rowWay={this.props.rowWay} index={this.props.index} module={this.props.module} />;
    }
    var required = data.def.get("required");

    var labelValue = sAction.translate(data.def.get("vname"), module);

    const percent = this.props.percent;
    const extraStyle = { width: percent + "%" };
    let customClass = data.def.get("customClass");
    if (customClass) {
      customClass = " " + customClass;
    }
    else {
      customClass = "";
    }
    const help = data.def.get("help") ?? false


    //developer view
    if (sAction.getStorage("debug")) {
      //nazev fieldu
      const id_name = data.def.get("id_name");
      if (id_name) { //pokud je relace, zobrazi se nazev vazby
        labelValue += " <small style='color: orange'>"+id_name+"</small> ";
      } else {
        labelValue += " <small style='color: gold'>"+data.name+"</small> ";
      }

      //nazev pripadneho ciselniku
      const options = data.def.get("options") || data.def.get("ext1");
      if (options) {
        labelValue += " <small style='color: lightblue'>"+options+"</small> ";
      }
    }

    const warning = data.def.get('warning') ?? false;

    return (
      (data.def.get("displayIfNew") === false && id == null) ? null
        :
        <div className={"detailViwRow" + customClass} style={extraStyle}>
          <div className="DetailviewLabel">
            <label dangerouslySetInnerHTML={{ __html: labelValue }} />
            {required && <span className="required">* </span>}
            {help && <div id={data.name + "_help"} className="icon-info detailviewInfoIcon" onClick={(e) => this.showInfo("#" + data.name + "_help", help, labelValue)}></div>}
            {warning && <span className="warning">!</span>}
          </div>
          <div
            className={
              data.edit ? "DetailViewValue pointerClass" : "DetailViewValue"
            }
          >
            {showApiButtons ? (<div className="detailApi__field">
              {value}
              {errorMsg}
              <DetailApiButtons type={showDetailApi} prefix={this.props.prefix} name={data.name} value={data.value} />
            </div>) : (
                <React.Fragment>
                  {value}
                  {errorMsg}
                </React.Fragment>
              )}
            <input type="hidden" id={data.name + "_hidden"} value={data.value == null ? "" : data.value} />
          </div>
        </div>
    );
  }
}
export default DetailViewField;
