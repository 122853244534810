/**
 * uploadImgFile - Upload image file
 * @param {file} file
 * @param {function} callback
 * @param {bool} validate
 * @param {bool} compress
 * @param {function} progressCallback
 */
export default function uploadImgFile(file, callback, validate = false, compress = true, progressCallback = () => {}) {
    const formData = {
        files: [file],
        compress: compress,
    };

    if (validate) {
        // allow only images to be uploaded
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];
        if (!allowedTypes.includes(file.type)) {
            this.error(this.translate('LBL_UPLOAD_IMG_ERROR_TYPE'), 'Upload');
            return;
        }
    }

    this.filesXhr({
        action: 'uploadImageFile',
        data: formData,
        success: (data) => {
            callback(data);
        },
        progress: (data) => {
            progressCallback(data);
        },
        error: (data) => {
            this.error(this.translate(data.err), 'Upload');
        },
    });
}
