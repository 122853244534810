
import detailDefault from "../../detailDefault";
export default class detailacm_lost_found extends detailDefault{
    load(sAction,data){

    }
    update(sAction,data){
        console.log("update", data);
        switch(data.field){
            case "lostid":
                this.checkLostId(sAction, data);
                break;
            case "email":
                this.checkEmail(sAction, data);
                break;
        }
    }

    checkLostId(sAction, data){
        const val = data.value;
        
        if(val){
            
            const id = sAction.dataGet(data.prefix+"/id");
            sAction.load();
            sAction.rest.post("customAction", {action: "check_lost_id", val: val, id: id}, function(resp) {
                sAction.unLoad();
                if(resp.errCode !== "OK"){
                    sAction.dsClear();
                    sAction.dsAdd("set", data.prefix + "/fields/lostid/value", "");
                    sAction.dsAdd("set", data.prefix + "/changes/fields/lostid", "");
                    sAction.dsProcess();
                    sAction.error(resp.errMsg);
                }
                
                
            });
        }
    }

    checkEmail(sAction, data){
        console.log("data2", data);

        if(data.value){
            sAction.rest.post("customAction", {action: "check_acm_lost_found_email", email: data.value}, function(resp) {
                console.log("resp", resp);
                if(resp.errCode == "OK"){
                    sAction.dsClear();
                    sAction.dsAdd("set", data.prefix + "/fields/assigned_user_name/value", resp.user_name);
                    sAction.dsAdd("set", data.prefix + "/fields/assigned_user_name/def/id_value", resp.user_id);
                    sAction.dsAdd("set", data.prefix + "/changes/fields/assigned_user_id", resp.user_id);
                    sAction.dsProcess();
                }
            });
        }

    }
}
