export default function customerRequestClose({id, module, prefix}) {
    this.confrim(
        this.translate('LBL_CLOSE_REQUEST_CONFIRM', module),
        () => {
            this.dsClear();
            this.dsAdd('set', `${prefix}/fields/status/value`, 'closed');
            this.dsAdd('set', `${prefix}/changes/fields/status`, 'closed');
            this.dsProcess();
            this.saveRecord({
                id: id,
                module: module,
                prefix: prefix,
                canSave: true,
            });
            this.popupHide();
        }
    )
}
