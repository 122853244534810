export default function accGenerateContractCallback(data){
    
    const acc = data.parent_id;
    const attach = data.attachments.toJS();

    if(acc && attach){
        const params = {
            action: "accGenContractDocument",
            acc: acc,
            attach: attach,
        };

        this.rest.post("customAction", params, (resp) => {
            if (resp.errCode == "OK") {
                //location.reload();
            }
            else{
                this.error(resp.errMsg);
            }
        });
    }

}