export default function askAgain(params) {
    const prefix = params.prefix;
    this.detailPredefinedFields = [];

    const requestName = this.dataGet(`${prefix}/fields/acm_customers_requests_acm_internal_communication_1_name/value`);
    const requestId = this.dataGet(`${prefix}/fields/acm_customers_requests_acm_internal_communication_1_name/def/id_value`);

    const userName = this.dataGet(`${prefix}/fields/assigned_user_name/value`);
    const userId = this.dataGet(`${prefix}/fields/assigned_user_name/def/id_value`);

    this.detailPredefinedFields.push({
        type: 'field',
        name: 'name',
        value: requestName,
    });

    this.detailPredefinedFields.push({
        type: 'field',
        name: 'status',
        value: 'sent',
    });

    this.detailPredefinedFields.push({
        name: 'acm_custom16b7equests_ida',
        fieldName: `acm_customers_requests_acm_internal_communication_1_name`,
        value: {name: requestName, id: requestId},
        type: 'relate_simple',
    });

    this.detailPredefinedFields.push({
        name: 'assigned_user_id',
        fieldName: 'assigned_user_name',
        value: {name: userName, id: userId},
        type: 'relate_simple',
    });

    this.href('#detail/acm_internal_communication/');
}
