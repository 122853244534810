import PropTypes from 'prop-types';
import React, {memo} from 'react';
import ChatAvatar from './ChatAvatar';
import {styled} from '@mui/material/styles';
import Badge from '@mui/material/Badge';

const ChatAvatarOnline = memo(function ChatAvatarOnline({user, className, online}) {
    const StyledBadge = styled(Badge)(({theme}) => ({
        '& .MuiBadge-badge': {
            'backgroundColor': '#44b700',
            'color': '#44b700',
            'boxShadow': `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 4s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '60%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }));

    const StyledBadgeOffline = styled(Badge)(({theme}) => ({
        '& .MuiBadge-badge': {
            'backgroundColor': '#AAA',
            'color': '#AAA',
            'boxShadow': `0 0 0 2px ${theme.palette.background.paper}`,
        },
    }));

    if (online) {
        return (
            <StyledBadge
                overlap="circular"
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                variant="dot"
                className={`chatAvatarOnline ${className}`}
            >
                <ChatAvatar
                    alt={user.name}
                    user={user}
                    className={`chatAvatarOnline ${className}`}
                />
            </StyledBadge>
        );
    }

    return (
        <StyledBadgeOffline
            overlap="circular"
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            variant="dot"
            className={`chatAvatarOnline ${className}`}
        >
            <ChatAvatar
                alt={user.name}
                user={user}
                className={`chatAvatarOnline ${className}`}
            />
        </StyledBadgeOffline>
    );
});

export default ChatAvatarOnline;

ChatAvatarOnline.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profile_photo: PropTypes.string,
        online: PropTypes.any,
    }),
    className: PropTypes.string,
    online: PropTypes.bool,
};
