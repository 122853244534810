import PropTypes from 'prop-types';
import React, {useMemo, useContext, useCallback} from 'react';
import {ChatContext} from 'ROOT/src/contexts/ChatContext';
import ChatMessagesList from './ChatMessagesList';
import sAction from 'sAction';

export default function ChatMessages({chatId, messages, user, virtuoso, setLoading}) {
    const lastMessageIndex = useMemo(() => {
        if (!messages || messages.length === 0) {
            return 1;
        }
        return messages[messages.length - 1]?.index + 1;
    }, [chatId, messages.length]);

    const {chats, setChats} = useContext(ChatContext);

    const lastSeenMessages = useMemo(() => {
        const chat = chats.find((chat) => chat.id === chatId);
        if (!chat) {
            return null;
        }
        return chat?.lastSeenMessages;
    }, [chats, chatId]);

    /**
     * startReached - load more messages
     */
    const startReached = useCallback( async () => {
        setLoading(true);
        const lastMessageId = (messages && messages.length > 1) ? messages[0].messageID : null;
        const chatMessages = await sAction.chatCore.getChatMessages(chatId, lastMessageId);

        // update chat messages, add new messages to beginning of array
        const newChats = [...chats];
        const chatIndex = newChats.findIndex((chat) => chat.id === chatId);
        const newChat = {...newChats[chatIndex]};
        newChat.messages = [...chatMessages, ...newChat.messages];
        newChats[chatIndex] = newChat;

        // check if all messageIds are unique remove duplicates
        const uniqueMessages = [];
        newChat.messages.forEach((message) => {
            if (!uniqueMessages.find((m) => m.messageID === message.messageID)) {
                uniqueMessages.push(message);
            }
        });
        newChat.messages = uniqueMessages;
        setChats(newChats);
        setLoading(false);
    }, [messages, chats, chatId, setChats]);

    return (
        <ChatMessagesList
            chatId={chatId}
            messages={messages}
            startReached={startReached}
            virtuoso={virtuoso}
            user={user}
            startIndex={lastMessageIndex}
            lastSeenMessages={lastSeenMessages}
        />
    );
}

ChatMessages.propTypes = {
    chatId: PropTypes.string,
    messages: PropTypes.array,
    user: PropTypes.any,
    virtuoso: PropTypes.any,
    setLoading: PropTypes.func,
};
