import sAction from "../..";

export default function getCargoNetPackageInfo(packageNumber, ticketName) {
    if (!packageNumber) {
        return sAction.error(sAction.translate('LBL_PACKAGE_NUMBER_MISSING', 'acm_customers_requests'));
    }
    const purePackageNumber = packageNumber.replaceAll('-', '');
    const data = {
        shipmentNr: packageNumber,
        ticketName: ticketName
    };
    sAction.load();
    sAction.rest.post('getShipmentInfo', data, res => {
        sAction.unLoad();
        if (res.status) {
            sAction.popup('CargoNetPackageInfo', {
                searchedPackageNumber: purePackageNumber,
                data: res.data,
                ticketName: ticketName,
            });
        } else {
            if (res.data === 'LBL_SOLVER_IS_UNREACHABLE') {
                sAction.error(sAction.translate('LBL_SOLVER_IS_UNREACHABLE', 'acm_customers_requests'));
            } else {
                sAction.error(sAction.translate('LBL_PACKAGE_NOT_FOUND', 'acm_customers_requests'));
            }
        }
        sAction.unLoad();
    })
}
