import React from "react";

import InputField from "../inputFieldClass";

function htmlUnescape(str) {
  return str == null
    ? ""
    : str
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&");
}

export default class HTMLText extends InputField {
  constructor(props) {
    super(props);

    this.state = {
      value: ""
    }
  }

  getValue() {
    return this.state.value;
  }

  handleEditorChange = (e) => {
    // console.log('Content was updated:', e.target.getContent());
    this.setState({value: e.target.getContent()})
    this.save()
  }

  componentDidMount() {
    var tinymce_min = document.querySelector('script[src*="tinymce.min.js"]');

    if (tinymce_min) {
      this.init_tinymce();
    } else {
      const script = document.createElement("script");
      script.src = "build/tinymce/tinymce.min.js";
      document.body.appendChild(script);

      script.onload = () => {
        this.init_tinymce();
      }
    }
  }

  init_tinymce = () => {
    tinymce.init({
      selector: '#htmlEditor',
      height: '500px',
      language: 'cs',
      convert_urls : false,
      setup: (ed) => {
        ed.on('change', (e) => {
          this.handleEditorChange(e);
        });
      },
      menubar: 'file edit view insert format tools table help',
      plugins: [
        'print preview fullpage paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons'
      ],
      toolbar:
        'undo redo | bold | forecolor fontsizeselect | fullscreen preview print',
      readonly: this.props.readonly ? 1 : 0
    });
  }

  componentWillUnmount() {
    document.getElementById("htmlEditorWrappper").innerHTML = "";
    //smazani tinymce tooltipu z <body>
    var elements = document.getElementsByClassName("tox-silver-sink");
    while(elements.length > 0){
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  render() {
    const {data, newRecord, readonly} = this.props;

    var value = data.value;
    if (newRecord && (value == "" || value == undefined)) {
      value = "";
    }
    if (value) {
      value = htmlUnescape(value);
    }

    return (
      <div id="htmlEditorWrappper" className="w100">
        <div className="email__add-params"><label>Text:</label></div>
        <textarea id="htmlEditor" defaultValue={value}></textarea>
      </div>
    );
  }
}