export default function loadCustomReport(id, way, data = null) {
  this.customReportXHR(id,data,reponseData => {
    if (!document.querySelector(".customReport")) {
      console.error("div pro report jiz neni v DOMu");
      return;
    }
    this.dsClear();
    if(data.isDashboard != true){
      this.dsAdd("set",way, {});
    }
    this.dsAdd("set",way+"/html", reponseData);
    this.dsProcess();
  })
}
