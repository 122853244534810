import React, {useRef, useState, useEffect} from 'react';
import { TextField } from '@mui/material';
import sAction from 'sAction';
import {Switch} from '@mui/material';
import Loader from '../../loader';

export default function CsUsersStatuses() {
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [departmentsOnly, setDepartmentsOnly] = useState(true);
    const [load, setLoad] = useState(false);
    const containerRef = useRef(null);

    const handleClickOutside = (event) => {
        if (containerRef.current &&
            !containerRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setLoad(true);
        if (open) {
            sAction.rest.post('getCsUsersStatuses', {departmentsOnly} ,(res) => {
                if (res.status) {
                    setUsers(res.data);
                } else {
                    sAction.error(res.message);
                }
                setLoad(false);
            }, false);
        }
    }, [departmentsOnly, open]);

    const statuses = sAction.app_strings['work_type'];

    const colors = {
        online: '#18da59',
        offline: '#E92222',
        lunch: '#f5a020',
    };

    const renderData = () => {
        return users.map((user) => {
            if (user.full_name.toLowerCase().includes(search.toLowerCase()) || user.user_name.toLowerCase().includes(search.toLowerCase())) {
                return (
                    <tr key={user.id}>
                        <td style={{width: '25%'}}>
                            <a href={`#detail/Users/${user.id}`}><span className='icon-users'/>{user.full_name}</a>
                        </td>
                        <td style={{width: '15%'}}>{user.user_name}</td>
                        <td className='departmentCol' style={{width: '45%'}}>
                            {user.departments}
                        </td>
                        <td style={{width: '15%'}}>
                            <div className='userStatus' style={{backgroundColor: colors[user.cs_worker_status]}}>
                                {statuses[user.cs_worker_status]}
                            </div>
                        </td>
                    </tr>
                )
            }
        })
    }

    return (
        <div
            ref={containerRef}
            className={`csContainer csContainer-usersStatuses csContainer-${open}`}
            style={{ backgroundColor: open ? '#FFF' : '' }}
        >
            <div className={`csContainerIconBox csContainer-${open}`} onClick={() => setOpen(!open)}>
                <div className='usersStatuses-icon csContainer-icon icon-userCheck' />
            </div>
            {open ? (
                <div className='csContainerContent'>
                    <div className='usersStatuses'>
                        <div className='usersStatusesBox'>
                        <TextField
                            hiddenLabel
                            variant="outlined"
                            size="small"
                            className='search'
                            placeholder={sAction.translate('LBL_SEARCH')}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <span className='switchLabel'>{sAction.translate('LBL_DEPARTMENTS_ONLY')}</span>
                        <Switch
                            checked={departmentsOnly}
                            onChange={() => setDepartmentsOnly(!departmentsOnly)}
                        />
                        </div>
                    {load ? <div className='usersStatusesNotFound'><Loader /></div> : users.length > 0 ?
                    <>
                        <table className='usersStatusesHeaderTable'>
                        <thead>
                                <tr>
                                    <th style={{width: '22%'}}>{sAction.translate('LBL_USER')}</th>
                                    <th style={{width: '13%'}}>{sAction.translate('LBL_USERNAME')}</th>
                                    <th className='departmentCol' style={{width: '40%'}}>{sAction.translate('LBL_DEPARTMENTS')}</th>
                                    <th style={{width: '15%'}}>{sAction.translate('LBL_CS_WORKER_STATUS')}</th>
                                </tr>
                            </thead>
                        </table>
                        <div className='usersTableWrapper'>
                        <table className='usersStatusesTable'>
                            <tbody>
                                {renderData()}
                            </tbody>
                        </table>
                    </div>
                    </> : <div className='usersStatusesNotFound'>{sAction.translate('LBL_NO_USERS_FOUND')}</div>}
                 </div>
            </div>
            ) : null}
        </div>
    )
}
