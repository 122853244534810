export default function closeRightPanel(){
    const way = this.dataGet('rightPanel/data/reloadList');
    const reloadOnClose = this.dataGet("rightPanel/reloadOnClose");

    if (typeof(reloadOnClose) === "boolean") {
        this.route();
    } else if (way !== undefined) {
        this.reloadList(0, 1, way);
    } else if (typeof(reloadOnClose) === "string") {
        this.reloadList(0, 1, reloadOnClose);
    }

    this.dataSet("rightPanel", {}, "RightPanel");
}
