import PropTypes from 'prop-types';
import React, {useContext, useCallback, useMemo, useState} from 'react';
import {ChatContext} from 'ROOT/src/contexts/ChatContext';
import ChatListItem from './ChatListItem';
import ChatGroupListItem from './ChatGroupListItem';
import Loader from '../loader';
import sAction from 'sAction';

export default function ChatsList(props) {
    const {users, chats, selectedChat, setSelectedChat, setActiveScreen} = useContext(ChatContext);
    const [showMoreChats, setShowMoreChats] = useState(false);

    if (!chats || !users) {
        return <Loader />;
    }

    const selectChat = useCallback((chatID) => {
        if (props?.selectCallback) {
            props.selectCallback(chatID);
            return;
        }

        setSelectedChat(chatID);
        setActiveScreen(null);
    }, [setSelectedChat]);

    const chatsList = useMemo(() => {
        let chatsData = chats;
        if (props?.chats) {
            chatsData = props.chats;
        }

        if (props.groupsOnly) {
            return chatsData.filter((chat) => chat.type === 'group');
        }

        if (showMoreChats || props?.showAllChats) {
            return chatsData;
        }

        return chatsData.slice(0, 8);
    }, [chats, showMoreChats, props?.showAllChats, props?.chats]);

    const handleShowMoreChats = useCallback(() => {
        setShowMoreChats(!showMoreChats);
    }, [showMoreChats]);

    return (
        <div className='chatsList'>
            <div className='chatsListWrapper'>
                {!props?.groupsOnly && <p className='chatsListLabel'>{sAction.translate('LBL_CHAT_CHATS')}</p>}
                <div className='chatsListStack'>
                    { chatsList.map((chat) => {
                        if (chat.type === 'direct') {
                            const user = users[chat.participants.find((userID) => userID !== props.user.id)];
                            if (!user) {
                                console.warn('[chat] >> user not found');
                                return null;
                            }

                            const lastMessage = chat.messages[chat.messages.length - 1];
                            if (!lastMessage) {
                                console.warn('[chat] >> last message not found');
                                return null;
                            }

                            return (
                                <ChatListItem
                                    user={user}
                                    lastMessage={lastMessage}
                                    newMessages={chat.unreadMessages}
                                    selected={selectedChat === chat.id}
                                    key={chat.id}
                                    onClick={() => selectChat(chat.id)}
                                />
                            );
                        } else if (chat.type === 'group') {
                            let participantUsers = chat.participants.map((userID) => {
                                return users[userID];
                            });
                            participantUsers = participantUsers.filter((user) => user !== undefined && user.id !== props.user.id);

                            if (participantUsers.includes(undefined)) {
                                console.warn('[chat] >> user not found');
                                return null;
                            }

                            return (
                                <ChatGroupListItem
                                    users={participantUsers}
                                    chat={chat}
                                    lastMessage={chat.messages[chat.messages.length - 1]}
                                    newMessages={chat.unreadMessages}
                                    selected={props?.markSelected && selectedChat === chat.id}
                                    key={chat.id}
                                    onClick={() => selectChat(chat.id)}
                                    highlightProtected={!!props?.groupsOnly}
                                />
                            );
                        }
                        console.warn('[chat] >> chat type not found');
                        return null;
                    })}
                    {chats.length > 8 && !props?.showAllChats && (
                        <p className={'chatListShowMore ' + (showMoreChats ? 'active' : '')} onClick={handleShowMoreChats}>
                            {!showMoreChats ? sAction.translate('LBL_CHAT_SHOW_MORE_USERS') : sAction.translate('LBL_CHAT_SHOW_LESS_USERS')}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

ChatsList.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string,
    }),
    groupsOnly: PropTypes.bool,
    showAllChats: PropTypes.bool,
    selectCallback: PropTypes.func,
    markSelected: PropTypes.bool,
    chats: PropTypes.array,
};
