import React from "react";
import sAction from "sAction";

export default function FileName(props) {
  var lineRender = null;
    lineRender = <a title={props.value} href={"#detail/" + props.module + "/" + props.id}>{props.value}</a>;
 
  var className = "newListViewLine";
  if(props.extraClass !== undefined){
      className += " "+props.extraClass
  }

  let onClick = () => props.onGetFile({id:props.id,module:props.module});
  
  // vyjimka: listview reklamaci
  if (props.module === "acm_log_schvalovani") {
    if (props.value === "____") {
      // kdyz nebyl pridan soubor, nic se nezobrazi
      return <div className={className}></div>;
    } else {
      // po kliku na nazev se soubor rovnou stahne
      lineRender = <a title={props.value} onClick={onClick}>{props.value}</a>;
    }
  }
  
  let showImage = "";
  if (/\.(bmp|jpg|jpeg|png|gif|tiff)$/i.test(props.value)) {
    showImage = <div className="icon-eye listViewEyeIcon" onClick={() => sAction.openRightPanel("image", {name: props.value, url: sAction.param.files+"image/"+ props.id})}>&nbsp;</div>;
  }

  return (
    <div className={className}><div onClick={onClick} className="icon-download listDownLoadIcon"></div>{showImage}{lineRender}</div>
  );
}
