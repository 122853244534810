import  sAction  from "sAction";

export default function clearCalDetailStore(prefix = "calendar"){

    sAction.dataSet(prefix+"/activeTab", "detailMeeting");
    
    var content = {
        id: "12", 
        module: "Users",
        actionButtons : []
      };

      var action = {
        type: "CALENDARCLEARDATA",
        content: {
          prefix: prefix
        },
        function: sAction
      };
      
      
      sAction.dsClear();
      sAction.dsAddCustom(action);
      sAction.dsProcess();
    
}