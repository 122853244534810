/*
http://localhost:8080/#custom/?action=acceptDecline&module=Calls&record=136201f1-945e-4bc2-8179-46248d0cb1f0&contact_id=969eb1e5-cb36-1b63-e89e-46248ccc391f&accept_status=accept
 */

export default function routeCustom(){
  let url = window.location.hash;

  if (!/\?/.test(url)) return;

  url = url.replace(/.*\?/, "");

  url = JSON.parse(
    '{"' + url.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    function(key, value) {
      return key === "" ? value : decodeURIComponent(value);
    }
  );

  if (url.action === "acceptDecline") {
    // prijmuti nebo odmitnuti hovoru nebo schuzky pres odkaz v e-mailu a presmerovani na dany zaznam...

    this.rest.post(url.action, url, (data) => {
      this.dsProcess(); // init menu
      this.load();
      window.location.hash = "#detail/" + url.module + "/" + url.record;
    });
  }
  else if(url.action === "refrechSAPNumber"){
    this.load();

    let params = {
      action : "refrech_SAP_number",
      token : url.token,
      compliant: url.compliant,
    }

    this.rest.post('customAction', params, (retData) => {
      this.unLoad();
      if(retData.errCode == "OK"){
        this.alert(retData.msg);
      }
      else{
        this.error(retData.errMsg);
      }
    });
  }
}