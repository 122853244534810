export default function customerRequestCreateNote(data) {
    const id = this.dataGet('view/id');
    const name = this.dataGet('view/fields/name/value');
    const module = 'acm_customers_requests';
    this.detailPredefinedFields = [];
    this.detailPredefinedFields.push({
        name: "parent_name",
        id_name: "parent_id",
        parentName: name,
        parentId: id,
        parentModule: module,
        type: 'parent',
    });
    this.popupDetail({
        module: 'Notes',
        record: '',
        saveCallback: data => {
            this.reloadSubpanelByWay('view', 'Notes');
        },
    })
}
