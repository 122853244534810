import PropTypes from 'prop-types';
import React, {useContext, useMemo, useCallback, useRef, useState} from 'react';
import EmojiPicker from 'emoji-picker-react';
import TextField from '@mui/material/TextField';

import {ChatContext} from 'ROOT/src/contexts/ChatContext';
import sAction from 'sAction';

export default function ChatWindowMessageField({chatId}) {
    const {chats, setChats} = useContext(ChatContext);

    const [newMessage, setNewMessage] = useState(null);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const messageInputRef = useRef(null);
    const fileUploadInputRef = useRef(null);

    /**
     * handleMessageSend - send message to chat
     * @param {string|object} newMessage
     * @returns {void}
     */
    const handleMessageSend = useCallback((newMessage) => {
        if (!newMessage) {
            return;
        }

        let messageData = null;
        if (typeof newMessage === 'string') {
            messageData = sAction.chatCore.sendChatMessage(chatId, 'text', newMessage);
        } else if (typeof newMessage === 'object') {
            messageData = sAction.chatCore.sendChatMessage(chatId, newMessage.type, newMessage.content);
        } else {
            console.warn('[Chat] unknown message type', newMessage);
        }

        // set timeout to check if message was sent
        setTimeout(() => {
            checkMessageSent(messageData);
        }, 4000);

        setNewMessage('');
        setShowEmojiPicker(false);
    }, [chats, chatId]);

    /**
     * checkMessageSent - check if message was sent
     * @param {object} messageData
     * @returns {void}
     */
    const checkMessageSent = useCallback((messageData) => {
        if (!messageData) {
            console.warn('checkMessageSent: messageData is not set');
            return;
        }
        const chat = chats.find((c) => c.id === chatId);
        if (!chat) {
            console.warn('Chat not found', chatId, chats);
            return;
        }

        const message = chat.messages.find((m) => m.messageID === messageData.messageID);
        if (!message) {
            console.warn('Message not found', messageData.messageID, chat.messages);
            return;
        }

        if (message.sent !== true) {
            console.warn('Message was not sent', message);
            sAction.toast({
                type: 'error',
                name: 'Zpráva nebyla odeslána, zkuste to prosím znovu',
                description: ' ',
            });

            message.sent = false;
            setChats([...chats]);
            return;
        }
    }, [chats, chatId]);

    const toggleEmojiPicker = useCallback(() => {
        if (!showEmojiPicker && messageInputRef.current) {
            messageInputRef.current.focus();
        }
        setShowEmojiPicker(!showEmojiPicker);
    }, [showEmojiPicker]);

    /**
     * handleOnEmojiClick
     * @param {any} emojiObject
     */
    // const handleOnEmojiClick = (emojiObject, event) => {
    function handleOnEmojiClick(emojiObject) {
        // add emoji to message
        setNewMessage((newMessage ? newMessage : '') + emojiObject.emoji);
        // NOTE: for now emoji picker is hidden after emoji is added to message because
        // it does not refresh message input while its open
        setShowEmojiPicker(false);
    }

    /**
     * handleSetNewMessage
     * @param {event} e
     */
    const handleSetNewMessage = (e) => {
        setNewMessage(e.target.value);
        setShowEmojiPicker(false);
    };

    /**
         * handleImageUpload - handle image upload and send to chat
         */
    const handleImageUpload = () => {
        // open file input dialog
        fileUploadInputRef.current.click();
    };

    /**
     * processFileUpload - process file upload
     * @param {file} file
     */
    const processFileUpload = (file) => {
        if (!file) {
            return;
        }

        sAction.uploadImgFile(file, fileUploadCallback, true, true);
    };

    /**
     * fileUploadCallback - callback for file upload action
     * @param {object} data
     */
    const fileUploadCallback = (data) => {
        if (data.state === true) {
            if (data?.ids?.length > 0) {
                setNewMessage({
                    type: 'image',
                    content: data.ids[0].name,
                });
                fileUploadInputRef.current.value = '';
            }
        }
    };

    /**
     * sendCoripoLink - send coripo link to chat
     */
    const sendCoripoLink = () => {
        setNewMessage({
            type: 'coripoLink',
            content: {
                url: window.location.href,
                title: document.title,
                module: sAction.dataGet('view/modul') || sAction.dataGet('view/module'),
                id: sAction.dataGet('view/id'),
            },
        });
    };

    const messageInput = useMemo(() => {
        if (typeof newMessage === 'string' || newMessage === null) {
            return (<>
                <TextField
                    className='chatWindowMessageInput'
                    multiline
                    maxRows={5}
                    value={newMessage}
                    defaultValue={newMessage}
                    onChange={handleSetNewMessage}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            // prevent default enter behavior
                            e.preventDefault();
                            handleMessageSend(newMessage);
                        }
                    }}
                    placeholder='Napište zprávu...'
                    ref={messageInputRef}
                />
                <button
                    onClick={() => handleMessageSend(newMessage)}
                    className='chatWindowMessageBtn chatWindowMessageSend'
                >
                    <span className='iconfa-sendMessage'></span>
                </button>
            </>);
        // custom message types send dialog
        } else if (typeof newMessage === 'object') {
            switch (newMessage?.type) {
                case 'image':
                    return (<>
                        <div className='chatWindowMessageImageInput'>
                            <img src={sAction.getImageUrl(newMessage.content)} alt='image' />

                        </div>
                        <div className='chatWindowMessageControlsWrapper'>
                            <div className='chatWindowMessageControls'>
                                <button
                                    className='chatWindowMessageBtn chatWindowMessageImageCancel'
                                    onClick={() => {
                                        setNewMessage('');
                                        fileUploadInputRef.current.value = '';
                                    }}
                                >
                                    <span className='iconfa-close'></span>
                                </button>
                            </div>
                            <div className='chatWindowMessageControls'>

                                <button
                                    onClick={() => handleMessageSend(newMessage)}
                                    className='chatWindowMessageBtn chatWindowMessageSend'
                                >
                                    <span className='iconfa-sendMessage'></span>
                                </button>
                            </div>
                        </div>
                    </>);
                case 'coripoLink':
                    return (<>
                        <div className='chatWindowMessageLinkInput'>
                            <TextField
                                className='chatWindowMessageInput'
                                multiline
                                maxRows={1}
                                disabled
                                value={newMessage.content.url.substring(0, 35) + '...'}
                                defaultValue={newMessage.content.url.substring(0, 35) + '...'}
                                placeholder='Coripo link'
                            />
                        </div>
                        <div className='chatWindowMessageControlsWrapper linkInput'>
                            <div className='chatWindowMessageControls'>
                                <button
                                    className='chatWindowMessageBtn chatWindowMessageImageCancel'
                                    onClick={() => {
                                        setNewMessage('');
                                    }}
                                >
                                    <span className='iconfa-close'></span>
                                </button>
                            </div>
                            <div className='chatWindowMessageControls'>

                                <button
                                    onClick={() => handleMessageSend(newMessage)}
                                    className='chatWindowMessageBtn chatWindowMessageSend'
                                >
                                    <span className='iconfa-sendMessage'></span>
                                </button>
                            </div>
                        </div>
                    </>);
                default:
                    console.warn('unknown message type', newMessage);
                    return null;
            }
        }
    }, [newMessage]);

    return (
        <div className='chatWindowControls'>
            <div className='chatWindowControlsWrapper'>
                <div className='chatWindowControlsButton chatWindowSendImageBtn' onClick={handleImageUpload}>
                    <span className='iconfa-sendImage'></span>
                </div>
                <div className='chatWindowControlsButton chatWindowSendCoripoLinkBtn' onClick={sendCoripoLink}>
                    <span className='iconfa-link'></span>
                </div>
                <div className='chatWindowControlsButton chatWindowEmojiBtn' onClick={toggleEmojiPicker}>
                    <span className='iconfa-emoji'></span>
                </div>
            </div>

            {showEmojiPicker &&
                <div className='emojiPickerWrapper'>
                    <EmojiPicker
                        onEmojiClick={handleOnEmojiClick}
                        previewConfig={{
                            defaultEmoji: '1f60a',
                            defaultCaption: '...',
                            showPreview: false,
                        }}
                        skinTonesDisabled={true}
                        autoFocusSearch={false}
                    />
                </div>
            }

            <input
                type='file'
                id='fileInput'
                ref={fileUploadInputRef}
                style={{display: 'none'}}
                onChange={(e) => {
                    processFileUpload(e.target.files[0]);
                }}
            />

            <div className='chatWindowMessageInputWrapper'>
                {messageInput}
            </div>
        </div>
    );
}

ChatWindowMessageField.propTypes = {
    chatId: PropTypes.any,
};
