export default function acmCompliantEfaCallback(data){
    const params = {
        action: "compliantEfa",
        record: data.parent_id,
    };

    if(data.parent_type == "acm_compliant" && data.parent_id){
        this.load();
        this.rest.post("customAction", params, (resp) => {
            this.unLoad();
            if (resp.errCode == "OK") {
                location.reload();
            }
            else{
                this.error(resp.errMsg);
            }
        });
    }
}