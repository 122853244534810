export default function updateDetailCustom(type, params) {
    this.dsClear();
    if(type == "field"){
        this.dsAdd("set",params.way, params.value);
    }
    else if(type == "delItem"){
        this.dsAdd("delete", params.way, params.index);
    }
    else if(type == "prodName"){
        if(!params.template){
            this.dsAdd("set", params.way+"/name", params.item.name);
            this.dsAdd("set", params.way+"/id", null);
        }
        else{
            let data = params.item;
            data.product_template_id = data.id;
            data.id = "";
            this.dsAdd("set", params.way, data, "product");
        }
    }
    else if(type == "addProdLine"){
        var item = {id: "", name: ""};
        if(typeof(params.item) == "object"){
            item = params.item;
        }
        this.dsAdd("add", params.way, item, "product");
    }
    else if(type == "addProdGroup"){
          this.dsAdd("add", params.way, {
            id: "",
            name: "",
            type: "normal",
            description: "",
          }, "productGroup");
    }
    else if(type == "changeOppProd"){
        var total = 0;
        var celkem = 0;
        var pom = {ks : 0, cena_za_kus : 0};
        const oppWay =  params.oppWay.substr(0, params.oppWay.indexOf('/customData/productData')); 

        params.lines.forEach(line => {
            pom.ks = parseFloat(line.ks);
            pom.cena_za_kus = parseFloat(line.cena_za_kus);
            if(line.product_template_id == params.product_template_id){
                if(params.field == "cena_za_kus" 
                && params.value != 0
                && pom.ks == 0
                ){
                    this.dsAdd("set", params.way+"/ks", 1);
                    pom.ks = 1;
                }

                pom[params.field] = params.value;
                celkem = pom.cena_za_kus * 12;

                
            }
            total += pom.cena_za_kus * 12;
        });
        

        this.dsAdd("set", params.way+"/celkem", celkem);
        this.dsAdd("set", params.way+"/"+params.field, params.value);
        this.dsAdd("set", oppWay+"/fields/"+params.totalFieldName+"/value", total);
        this.dsAdd("set", oppWay+"/changes/fields/"+params.totalFieldName, total);
    }
    else if(type == "addInvitee"){
        this.dsAdd("add", params.wayRel, params.value);
        this.dsAdd("delete", params.waySearch, params.index);
    }

    this.dsAdd("set","view/changes/forceChange", true);
    this.dsProcess();

}