export default function acmCompliantReject(params){
    const email = this.dataGet(params.prefix+"/fields/comp_pers_email/value");
    const comp_est_damage = this.dataGet(params.prefix+"/fields/comp_est_damage/value");

    if(comp_est_damage <= 0){
        this.error(this.translate("LBL_ERR_INVALID_REJECT_REQUEST"));

        this.dataSet(params.prefix+"/fields/comp_est_damage/customClass", "error");

        return;
    }


    const data = {
        parent_type: params.module,
        parent_id: params.id,
        parent_name: params.name,

        to_addrs: email,
        emailTemplate : "",
        callback: "acmCompliantRejectCallback",
    };
        
    this.openEmailPopup(data);
}